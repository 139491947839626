import cx from "classnames";
import { DataListValue } from "./DataList.model";
import styles from "./DataList.module.scss";
import {
  CategoryName,
  Channel,
  KbCoverage,
  QAScore,
  Status,
  TTR,
} from "../../../routes/explore/cells";

function DataListItem({ title, type, value }: DataListValue) {
  return (
    <>
      <div className={styles.Label}>{title}</div>

      <div className={styles.Value}>
        {value == null ? (
          <>-</>
        ) : (
          <>
            {type === "number" && Number(value).toFixed(2)}

            {type === "string" && value}

            {type === "qaScore" && <QAScore value={value} />}

            {type === "status" && <Status value={value} />}

            {type === "kbCoverage" && <KbCoverage value={value} />}

            {type === "channel" && <Channel value={value} />}

            {type === "ttr" && <TTR value={value} />}

            {type === "category" && <CategoryName value={value} />}
          </>
        )}
      </div>
    </>
  );
}

interface DataListProps {
  values: DataListValue[];
  className?: string;
}

function DataList({ values, className }: DataListProps) {
  return (
    <div className={cx(styles.DataList, className)}>
      {values.map((item, index) => (
        <DataListItem key={index} {...item} />
      ))}
    </div>
  );
}

export default DataList;
