import { useCallback, useContext, useMemo, useState } from "react";
import cx from "classnames";
import { Badge, Button, IconButton, TextArea, Theme } from "@radix-ui/themes";
import {
  CircleBackslashIcon,
  CheckCircledIcon,
  PlusIcon,
  DrawingPinIcon,
  Cross2Icon,
} from "@radix-ui/react-icons";
import {
  printTicketId,
  TicketExploreRow,
} from "../../../api/useExplore/Explore.model";
import { QAScore } from "../../explore/cells";
import {
  questionsOrder,
  questionToReasons,
  ReasonForNegativeScore,
  reasonsForNegativeScore,
  reasonsForNegativeScoreFormatted,
  TicketQuestionScore,
} from "./TicketQAScore.model";
import styles from "./TicketQAScore.module.scss";
import { useAuthorizedMutation } from "../../../api";
import { params } from "../../../params";
import { useAuth0, User } from "@auth0/auth0-react";
import { SelectOption } from "../../../components/shared/select";
import { ThemeContext } from "../../../components/Theme";
import { DropdownMenu } from "../../../components/shared/dropdown-menu";
import { Tooltip } from "../../../components/shared/tooltip";

function isYotpo(user: User | undefined): boolean {
  if (user == null || user.owner == null) {
    return false;
  }

  return ["yotpo"].includes(user.owner);
}

function ScoreButton({
  value,
  active,
  updateTicketScore,
  variant = "number",
  label,
  hint,
  hintAlign = "center",
}: {
  value: number;
  active: boolean;
  updateTicketScore?: (newScore: number) => Promise<void>;
  variant?: "number" | "binary";
  label?: string;
  hint?: string;
  hintAlign?: "left" | "center" | "right";
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleOnClick = useCallback(async () => {
    if (active || updateTicketScore == null) {
      return;
    }

    setIsLoading(true);
    await updateTicketScore(value);
    setIsLoading(false);
  }, [active, value, updateTicketScore]);

  return (
    <Button
      size={"1"}
      variant={"outline"}
      color={"gray"}
      className={cx(styles.ScoreButton, styles[`value-${variant}-${value}`], {
        [styles.active]: active,
        [styles.hasHint]: hint != null,
        [styles.readonly]: updateTicketScore == null,
      })}
      onClick={handleOnClick}
      loading={isLoading}
    >
      {variant === "binary" && (
        <>
          {value === 0 && <CircleBackslashIcon />}
          {value === 1 && <CheckCircledIcon />}
        </>
      )}

      {label ?? value}

      {hint != null && (
        <div className={cx(styles.ScoreButtonHint, styles[hintAlign])}>
          {hint}
        </div>
      )}
    </Button>
  );
}

type TicketScoreResultLabelProps = Pick<
  TicketQuestionScore,
  "autoScore" | "adjustedScore"
>;

function TicketScoreResultLabel({
  autoScore,
  adjustedScore,
}: TicketScoreResultLabelProps) {
  const { user } = useAuth0();

  const isAdjusted = adjustedScore != null && adjustedScore !== autoScore;

  if (!isAdjusted) {
    return <div className={styles.Label}>Result</div>;
  }

  return (
    <Tooltip>
      <Tooltip.Trigger>
        <div className={styles.Label}>
          <div className={styles.Adjusted} />
          Result
        </div>
      </Tooltip.Trigger>

      <Tooltip.Content>
        <div className={styles.AdjustedTooltip}>
          <div className={styles.Label}>Adjusted result </div>
          <div className={styles.Content}>
            {isYotpo(user) ? (
              <>
                <ScoreButton
                  variant={"binary"}
                  value={autoScore}
                  active
                  label={"Not achieved"}
                  hint={"AI scored"}
                />

                <ScoreButton
                  variant={"binary"}
                  value={adjustedScore}
                  active
                  label={"Achieved"}
                  hint={"Adjusted score"}
                />
              </>
            ) : (
              <>
                <ScoreButton value={autoScore} active hint={"AI scored"} />

                <ScoreButton
                  value={adjustedScore}
                  active
                  hint={"Adjusted score"}
                />
              </>
            )}
          </div>
        </div>
      </Tooltip.Content>
    </Tooltip>
  );
}

function TicketScoreResultNumeric({
  value,
  updateTicketScore,
}: {
  value: number;
  updateTicketScore: (newScore: number) => Promise<void>;
}) {
  return (
    <div className={styles.TicketScoreResult}>
      <ScoreButton
        value={1}
        active={value === 1}
        updateTicketScore={updateTicketScore}
        hint={"Not achieved"}
        hintAlign={"left"}
      />
      <ScoreButton
        value={2}
        active={value === 2}
        updateTicketScore={updateTicketScore}
      />
      <ScoreButton
        value={3}
        active={value === 3}
        updateTicketScore={updateTicketScore}
        hint={"Partly achieved"}
      />
      <ScoreButton
        value={4}
        active={value === 4}
        updateTicketScore={updateTicketScore}
      />
      <ScoreButton
        value={5}
        active={value === 5}
        updateTicketScore={updateTicketScore}
        hint={"Achieved"}
        hintAlign={"right"}
      />
    </div>
  );
}

function TicketScoreResultBinary({
  value,
  updateTicketScore,
}: {
  value: number;
  updateTicketScore: (newScore: number) => Promise<void>;
}) {
  return (
    <div className={styles.TicketScoreResult}>
      <ScoreButton
        variant={"binary"}
        value={0}
        active={value === 0}
        updateTicketScore={updateTicketScore}
        label={"Not achieved"}
      />
      <ScoreButton
        variant={"binary"}
        value={1}
        active={value === 1}
        updateTicketScore={updateTicketScore}
        label={"Achieved"}
      />
    </div>
  );
}

function TicketScoreComment({
  value,
  updateTicketScore,
}: {
  value?: string;
  updateTicketScore: (newValue: string) => Promise<void>;
}) {
  const [comment, setComment] = useState(value);

  const handleBlur = useCallback(() => {
    if (comment !== value) {
      updateTicketScore(comment || "");
    }
  }, [comment, value, updateTicketScore]);

  return (
    <TextArea
      variant={"soft"}
      color={"gray"}
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      onBlur={handleBlur}
      className={styles.TicketScoreComment}
      placeholder={"Add comment"}
    />
  );
}

function TicketScoreReasons({
  reasons = [...reasonsForNegativeScore],
  value,
  updateTicketScore,
}: {
  reasons?: ReasonForNegativeScore[];
  value?: ReasonForNegativeScore[];
  updateTicketScore: (newValue: ReasonForNegativeScore[]) => Promise<void>;
}) {
  const isEmpty = value == null || value.length === 0;

  const { isDarkMode } = useContext(ThemeContext);

  const [addingReason, setAddingReason] = useState(false);
  const [reasonInRemoving, setReasonInRemoving] = useState<string>();

  const options = useMemo<SelectOption[]>(() => {
    if (isEmpty) {
      return reasons.map((value) => ({
        value,
        label: reasonsForNegativeScoreFormatted[value],
      }));
    }

    const valueAsMap = new Set(value);
    return reasons
      .filter((reason) => !valueAsMap.has(reason))
      .map((value) => ({
        value,
        label: reasonsForNegativeScoreFormatted[value],
      }));
  }, [isEmpty, value]);

  const addReason = useCallback(
    async (reason: string) => {
      setAddingReason(true);
      await updateTicketScore([
        ...(value ?? []),
        reason as ReasonForNegativeScore,
      ]);
      setAddingReason(false);
    },
    [value, updateTicketScore]
  );

  const removeReason = useCallback(
    async (reason: string) => {
      setReasonInRemoving(reason);
      await updateTicketScore(
        (value ?? []).filter((r) => r !== reason) as ReasonForNegativeScore[]
      );
      setReasonInRemoving(undefined);
    },
    [value, updateTicketScore]
  );

  return (
    <div className={styles.TicketScoreReason}>
      {value?.map((reason) => (
        <Badge
          color={"gray"}
          key={reason}
          size={"2"}
          className={styles.ReasonItem}
        >
          {reasonsForNegativeScoreFormatted[reason]}

          <IconButton
            color="gray"
            variant="ghost"
            highContrast
            size="1"
            loading={reasonInRemoving === reason}
            onClick={() => removeReason(reason)}
          >
            <Cross2Icon />
          </IconButton>
        </Badge>
      ))}

      <DropdownMenu
        items={options.map((option) => ({
          label: option.label,
          onClick: () => addReason(option.value),
        }))}
      >
        <DropdownMenu.Trigger>
          {isEmpty ? (
            <Button
              color="gray"
              variant="ghost"
              highContrast
              size="1"
              loading={addingReason}
              className={styles.ReasonMenuButton}
            >
              <DrawingPinIcon />
              Add reason
            </Button>
          ) : (
            <IconButton
              color="gray"
              variant="ghost"
              highContrast
              size="1"
              loading={addingReason}
              className={styles.ReasonMenuButton}
            >
              <PlusIcon />
            </IconButton>
          )}
        </DropdownMenu.Trigger>
      </DropdownMenu>
    </div>
  );
}

function TicketScoreResult({
  id,
  content,
  autoScore,
  adjustedScore,
  reasonsForNegativeScore,
  comment,
  onUpdateTicketScore,
}: TicketQuestionScore & { onUpdateTicketScore: () => Promise<unknown> }) {
  const { user } = useAuth0();

  const { mutateAsync } = useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/ticket-scores`,
    method: "PUT",
  });

  const updateTicketScore = useCallback(
    async (newScore: {
      adjustedScore?: number;
      comment?: string;
      reasonsForNegativeScore?: ReasonForNegativeScore[];
    }) => {
      const newTicketScore = {
        scoreId: id,
        adjustedScore: newScore.adjustedScore ?? adjustedScore,
        comment: newScore.comment ?? comment,
        reasonsForNegativeScore:
          newScore.reasonsForNegativeScore ?? reasonsForNegativeScore,
      };

      const nonNullableTicketScore = Object.fromEntries(
        Object.entries(newTicketScore).filter(([, value]) => value != null)
      );

      await mutateAsync(nonNullableTicketScore);
      await onUpdateTicketScore();
    },
    [
      mutateAsync,
      onUpdateTicketScore,
      adjustedScore,
      comment,
      id,
      reasonsForNegativeScore,
    ]
  );

  const value = adjustedScore ?? autoScore;

  if (isYotpo(user)) {
    return (
      <div className={styles.TicketScore}>
        <TicketScoreResultLabel
          autoScore={autoScore}
          adjustedScore={adjustedScore}
        />

        <TicketScoreResultBinary
          value={value}
          updateTicketScore={(adjustedScore) =>
            updateTicketScore({ adjustedScore })
          }
        />

        <div className={styles.Label}>Reasons</div>

        <TicketScoreReasons
          reasons={questionToReasons[content]}
          value={reasonsForNegativeScore}
          updateTicketScore={(reasonsForNegativeScore) =>
            updateTicketScore({ reasonsForNegativeScore })
          }
        />

        <div className={styles.Label}>Comment</div>

        <TicketScoreComment
          value={comment}
          updateTicketScore={(comment) => updateTicketScore({ comment })}
        />
      </div>
    );
  }

  return (
    <div className={styles.TicketScore}>
      <TicketScoreResultLabel
        autoScore={autoScore}
        adjustedScore={adjustedScore}
      />

      <TicketScoreResultNumeric
        value={value}
        updateTicketScore={(adjustedScore) =>
          updateTicketScore({ adjustedScore })
        }
      />
    </div>
  );
}

function formatQuestionTitle(title: string): string {
  return title
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/-/g, " ")
    .toLowerCase()
    .replace(/^\w/, (char) => char.toUpperCase());
}

function Question(
  props: TicketQuestionScore & { onUpdateTicketScore: () => Promise<unknown> }
) {
  const { id, title, content } = props;
  const { user } = useAuth0();

  const formattedTitle = useMemo(() => formatQuestionTitle(title), [title]);

  return (
    <div className={styles.Question} key={id}>
      {isYotpo(user) ? (
        <div className={styles.Header}>
          <div className={styles.Title}>{content}</div>
        </div>
      ) : (
        <div className={styles.Header}>
          <div className={styles.Title}>{formattedTitle}</div>
          <div className={styles.Content}>{content}</div>
        </div>
      )}

      <TicketScoreResult {...props} />
    </div>
  );
}

interface TicketQuestionsScoreProps {
  ticket: TicketExploreRow;
  ticketScore?: TicketQuestionScore[];
  onUpdateTicketScore: () => Promise<unknown>;
}

function TicketQuestionsScore({
  ticket,
  ticketScore = [],
  onUpdateTicketScore,
}: TicketQuestionsScoreProps) {
  const { user } = useAuth0();

  const totalAdjusted = useMemo(() => {
    return ticketScore.reduce((sum, { autoScore, adjustedScore }) => {
      return sum + (autoScore !== adjustedScore ? 1 : 0);
    }, 0);
  }, [ticketScore]);

  return (
    <div className={styles.Questions}>
      <div className={styles.Header}>
        <div className={styles.TicketNo}>{`Ticket ${printTicketId(
          ticket.overview.id
        )} Evaluation`}</div>

        <div className={styles.ExtraData}>
          {totalAdjusted > 0 && (
            <div className={styles.AdjustedCounter}>{`${totalAdjusted} ${
              totalAdjusted > 1 ? "adjustments" : "adjustment"
            }`}</div>
          )}
          {ticket.avgQaScore != null && <QAScore value={ticket.avgQaScore} />}
        </div>
      </div>

      {isYotpo(user) ? (
        <>
          {ticketScore
            .sort(
              (questionA, questionB) =>
                (questionsOrder[questionB.content] ?? -1) -
                (questionsOrder[questionA.content] ?? -1)
            )
            .map((question) => (
              <Question
                key={question.id}
                {...question}
                onUpdateTicketScore={onUpdateTicketScore}
              />
            ))}
        </>
      ) : (
        <>
          {ticketScore
            .sort((a, b) => a.id - b.id)
            .map((question) => (
              <Question
                key={question.id}
                {...question}
                onUpdateTicketScore={onUpdateTicketScore}
              />
            ))}
        </>
      )}
    </div>
  );
}

export default TicketQuestionsScore;
