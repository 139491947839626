import { PropsWithChildren, useContext } from "react";
import cx from "classnames";
import * as DialogPrimitive from "@radix-ui/react-dialog";
import { IconButton } from "@radix-ui/themes";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Theme } from "@radix-ui/themes";
import { ThemeContext } from "../../../components/Theme";
import styles from "./Dialog.module.scss";

function DialogTitle({ children }: PropsWithChildren) {
  return (
    <DialogPrimitive.Title className={styles.Title}>
      {children}
    </DialogPrimitive.Title>
  );
}

function DialogClose({ className }: { className?: string }) {
  return (
    <DialogPrimitive.Close aria-label="Close" asChild className={className}>
      <IconButton color="gray" variant="ghost" highContrast size="1">
        <Cross2Icon />
      </IconButton>
    </DialogPrimitive.Close>
  );
}

function DialogTrigger({ children }: PropsWithChildren) {
  return <DialogPrimitive.Trigger asChild>{children}</DialogPrimitive.Trigger>;
}

interface DialogContentWrapperProps {
  isPortal?: boolean;
}

function DialogContentWrapper({
  children,
  isPortal = true,
}: PropsWithChildren<DialogContentWrapperProps>) {
  if (isPortal) {
    return <DialogPrimitive.Portal>{children}</DialogPrimitive.Portal>;
  }

  return <>{children}</>;
}

interface DialogContentProps extends DialogContentWrapperProps {
  size?: "small" | "medium" | "large";
}

function DialogContent({
  children,
  isPortal,
  size = "medium",
}: PropsWithChildren<DialogContentProps>) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <DialogContentWrapper isPortal={isPortal}>
      <Theme>
        <DialogPrimitive.Overlay className={styles.DialogOverlay} />

        <DialogPrimitive.Content
          className={cx(styles.DialogContent, styles[size], {
            [styles.tooltip_light]: !isDarkMode,
            [styles.tooltip_dark]: isDarkMode,
          })}
        >
          {children}
        </DialogPrimitive.Content>
      </Theme>
    </DialogContentWrapper>
  );
}

interface DialogProps {
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

function Dialog({ children, ...props }: PropsWithChildren<DialogProps>) {
  return <DialogPrimitive.Root {...props}>{children}</DialogPrimitive.Root>;
}

Dialog.Title = DialogTitle;
Dialog.Close = DialogClose;
Dialog.Trigger = DialogTrigger;
Dialog.Content = DialogContent;

export default Dialog;
