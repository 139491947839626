import { Ticket, TicketOverview } from "./Ticket.model";
import {
  FilterValueParsed,
  NumericSlicer,
  StringSlicer,
} from "../../routes/explore/context/FilterContext.model";

export type TrendValue = {
  date: string;
  count: number;
}[];

export interface ExploreValue {
  avgVolume: number;
  trendVolume: TrendValue;
  avgTimeToResolve: number;
  resolutionRate: number;
  avgBackAndForth: number;
  avgQaScore: number;
  trendQa: TrendValue;
  numberOfAgentsEvaluated: number;
}

export type ExploreRow = {
  id: number;
  name: string;
} & Partial<ExploreValue>;

export interface TicketExploreValue {
  ttr: number;
  backAndForth: number;
  sentimentScores: number[];
}

export type TicketExploreRow = {
  id: string;
  topic?: string;
  agent?: string;
  category?: string;
  kbCoverage?: string;
  team?: string;
  overview: TicketOverview;
  status?: string;
  channel?: string;
  ticketCsat?: number;
  avgQaScore?: number;
} & Partial<TicketExploreValue>;

type ColumnResponseStatus = "fulfilled" | "rejected";

interface ColumnResponse<IdType, DataType> {
  status: ColumnResponseStatus;
  value: {
    name: keyof DataType;
    data: (IdType & Partial<DataType>)[];
  };
}

export type ExploreRequest = FilterValueParsed;

interface ExploreResponseMetadata
  extends Omit<ExploreRequest, "stringSliceBy" | "numericSliceBy"> {
  tenantId: string;
  numericSliceBy: NumericSlicer[];
  stringSliceBy: StringSlicer[];
  ticketCount: number;
}

export interface ExploreResponse {
  metadata: ExploreResponseMetadata;
  res: ColumnResponse<{ id: number; name: string }, ExploreValue>[];
}

export function translateColumnsToExploreRows({
  res,
}: ExploreResponse): ExploreRow[] {
  if (!res) return [];

  const rows: Record<
    number,
    {
      id: number;
      name: string;
    } & Partial<ExploreValue>
  > = {};

  const fulfilledColumns = res.filter(({ status }) => status === "fulfilled");

  fulfilledColumns.forEach((column) => {
    const key = column.value.name;

    column.value.data.forEach((data) => {
      const { id, name } = data;

      if (id == null || data[key] == null) {
        return;
      }

      if (rows[id] == null) {
        rows[id] = { id, name, [key]: data[key] };
      } else {
        rows[id] = { ...rows[id], [key]: data[key] };
      }
    });
  });

  return Object.values(rows);
}

export type TicketExploreRequest = FilterValueParsed & {
  includeComments: boolean;
};

interface TicketExploreResponseMetadata extends ExploreResponseMetadata {
  isThereNextPage: boolean;
}

export interface TicketExploreResponse {
  metadata: TicketExploreResponseMetadata;
  res: ColumnResponse<
    { id?: string; ticketId?: string; name: string },
    TicketExploreValue
  >[];
  ticketsData?: {
    isThereNextPage: boolean;
    totalCount: number;
    tickets: Ticket[];
  };
}

export function translateColumnsToTicketExploreRows({
  res,
  ticketsData,
}: TicketExploreResponse): TicketExploreRow[] {
  if (!res) return [];

  const rowsObj: Record<
    string,
    {
      id: string;
    } & Partial<TicketExploreValue>
  > = {};

  const fulfilledColumns = res.filter(({ status }) => status === "fulfilled");

  fulfilledColumns.forEach((column) => {
    const key = column.value.name;

    column.value.data.forEach((data) => {
      const id = data.id || data.ticketId;

      if (id == null || data[key] == null) {
        return;
      }

      if (rowsObj[id] == null) {
        rowsObj[id] = { id, [key]: data[key] };
      } else {
        rowsObj[id] = { ...rowsObj[id], [key]: data[key] };
      }
    });
  });

  const rows = Object.values(rowsObj);

  const ticketMap = new Map(
    ticketsData?.tickets.map((ticket) => [ticket.id, ticket])
  );

  return rows
    .filter(({ id }) => id)
    .map((row) => {
      const ticket = ticketMap.get(row.id);

      return {
        ...ticket,
        topic: ticket?.topic?.topic,
        agent: ticket?.agentAssigned?.name,
        category: ticket?.category?.name,
        kbCoverage: ticket?.kbEvaluation?.coverageLevel,
        team: ticket?.agentAssigned?.agentGroups?.[0]?.name,
        ticketCsat: ticket?.ticketCsat?.score,
        ...row,
        avgQaScore: ticket?.ticketCalculations?.avgQaScores,
        overview: {
          id: ticket?.displayId ?? row.id.split("::")[1],
          subject: ticket?.subject ?? "",
        },
      };
    });
}

export function printTicketId(ticketId: string) {
  return `#${ticketId}`;
}
