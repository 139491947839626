import React, { useContext, useEffect, useMemo, useState } from "react";
import { useFeatureFlag } from "configcat-react";
import { useAuth0 } from "@auth0/auth0-react";
import { createToast } from "vercel-toast";

import { DashboardCard } from "../assessment/components/dashboardCard";
import {
  useGetArticleCoverage,
  useGetArticlesCount,
} from "../../api/useGetArticlesCount";
import { useFetchingToasts } from "../../hooks/useFetchingToasts";
import { CircularPieChart } from "../../components/atoms/circlePieChart ";

import { ArticleRow } from "./components/articleRow";
import styles from "./styles.module.scss";
import { CoverageLevelType, TTicketCoverage } from "./index.types";
import { Button, Input, Loading, Modal, useModal } from "@geist-ui/core";
import { Button as RadixButton } from "@radix-ui/themes";
import { CornerDownRight, Search } from "@geist-ui/icons";
import { useSearchArticle } from "../../api/useSearchArticle";
import { useNavigate } from "react-router-dom";
import { FeatureAccessContext } from "../../context/featureAccess";
import { EmptyStateComponent } from "../../components/emptyState";

export const KnowledgeManagement = () => {
  const { user } = useAuth0();
  const { features } = useContext(FeatureAccessContext);

  const {
    data: articlesCount,
    isLoading: articlesCountLoading,
    isError: countError,
  } = useGetArticlesCount(true);

  const {
    data: articlesCoverageData,
    isLoading: articlesCoverageLoading,
    isError: articlesCoverageError,
  } = useGetArticleCoverage(true);
  const articlesCoverage = articlesCoverageData as TTicketCoverage;

  const { value: knowledgeNewOpportunityValue } = useFeatureFlag(
    "knowledgeNewOpportunity",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const percentages = useMemo(() => {
    if (
      articlesCount &&
      articlesCount?.totalArticlesCountWithDuplications > 0
    ) {
      let per = Object.entries(articlesCount?.articlesTopicDict)
        .filter(([_, top]: [string, any]) => top.articleCount > 0)
        .map(([id, top]: [string, any]) => ({
          id: id || "",
          label: top?.topicName,
          percentage:
            (top?.articleCount /
              articlesCount?.totalArticlesCountWithDuplications) *
            100,
          displayPercentage: Math.max(
            5,
            Math.round(
              (top?.articleCount /
                articlesCount?.totalArticlesCountWithDuplications) *
                100
            )
          ),
        }));

      per.sort((a, b) => b.percentage - a.percentage);
      const topSeven = per.slice(0, 7);
      const rest = per.slice(7);

      const restPercentage = rest.reduce(
        (sum, item) => sum + item.percentage,
        0
      );
      const restDisplayPercentage = rest.reduce(
        (sum, item) => sum + item.displayPercentage,
        0
      );

      if (rest.length > 0) {
        topSeven.push({
          id: "rest",
          label: "Rest of Topics",
          percentage: restPercentage,
          displayPercentage: restDisplayPercentage,
        });
      }

      const totalDisplayPercentage = topSeven.reduce(
        (sum, item) => sum + item.displayPercentage,
        0
      );
      if (totalDisplayPercentage !== 100) {
        const diff = 100 - totalDisplayPercentage;
        const largestItem = topSeven.reduce((max, item) =>
          item.displayPercentage > max.displayPercentage ? item : max
        );
        largestItem.displayPercentage += diff;
      }

      return topSeven;
    } else {
      return [];
    }
  }, [articlesCount]);

  const { error } = useFetchingToasts({
    error:
      articlesCount?.error || articlesCount?.articlesCount?.error || countError,
    emptyState: false,
  });

  useEffect(() => {
    if (articlesCount?.totalArticlesCountWithDuplications === 0)
      createToast("Couldn't find articles", {
        type: "warning",
        timeout: 5000,
      });
  }, [articlesCount]);

  const load = useMemo(
    () => articlesCountLoading || articlesCount === undefined || error,
    [articlesCountLoading, articlesCount, error]
  );

  const topicsCoverage = useMemo(() => {
    if (!articlesCoverage || articlesCoverageLoading || articlesCoverageError)
      return {};
    const result: { [key: string]: { [key in CoverageLevelType]: number } } =
      {};

    Object.keys(articlesCoverage).forEach((level) => {
      const coverageLevel = level as CoverageLevelType;
      articlesCoverage[coverageLevel].forEach((item) => {
        const topicName = item?.topic?.topic;

        if (!result[topicName]) {
          result[topicName] = {
            [CoverageLevelType.FULL]: 0,
            [CoverageLevelType.PARTIAL]: 0,
            [CoverageLevelType.NO_COVERAGE]: 0,
          };
        }

        result[topicName][coverageLevel] += 1;
      });
    });

    return result;
  }, [articlesCoverage, articlesCoverageLoading, articlesCoverageError]);

  if (!features.knowledge) {
    return (
      <EmptyStateComponent
        title="Knowledge management"
        description="Identify gaps instantly, updates documentation automatically, and surfaces insights intelligently."
      />
    );
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.topbar}>
        <div className={styles.search}>
          <SearchArticles />
        </div>
      </div>
      <div className={styles.mainContainer}>
        <div className={styles.leftSide}>
          {knowledgeNewOpportunityValue && (
            <DashboardCard
              bigHead={articlesCount?.articlesCount}
              smallHead="New opportunity"
              grow={1}
              loading={load}
              bigHeadFont
              addSun
            >
              {!load ? (
                <button className={styles.articleCTA}>
                  View Suggested Article
                </button>
              ) : (
                <div style={{ height: "130px" }} />
              )}
            </DashboardCard>
          )}
          <DashboardCard grow={1} loading={load} removeMargin maxHeight={700}>
            {!load ? (
              <div className={styles.circularContainerWrapper}>
                <div className={styles.circularContainer}>
                  <CircularPieChart
                    number={articlesCount?.articlesCount || 0}
                    label="Knowledge base articles"
                    percentages={percentages.map(
                      ({ label, displayPercentage }) => ({
                        label,
                        percentage: displayPercentage,
                      })
                    )}
                  />
                </div>
                <div className={styles.chartReader}>
                  {percentages
                    .filter((entry) => entry.percentage !== 0)
                    .map(
                      (
                        {
                          label,
                          percentage,
                        }: {
                          label: string;
                          percentage: number;
                        },
                        index
                      ) => (
                        <div
                          className={`${styles.topic} ${
                            styles[`color${index + 1}`]
                          }`}
                          key={label}
                        >
                          <span className={styles.dot} />
                          {label}{" "}
                          {Number.isInteger(percentage)
                            ? percentage
                            : percentage.toFixed(2)}
                          %
                        </div>
                      )
                    )}
                </div>
              </div>
            ) : (
              <div style={{ height: "300px" }} />
            )}
          </DashboardCard>
        </div>

        <div className={styles.rightSide}>
          {!load
            ? Object.entries(articlesCount?.articlesTopicDict || {})
                .sort(
                  ([, a]: [string, any], [, b]: [string, any]) =>
                    b.articleCount - a.articleCount
                )
                .map(([id, topic]: [string, any]) => (
                  <ArticleRow
                    key={id}
                    id={id}
                    name={topic.topicName}
                    articles={topic.articleCount}
                    suggestedArticle={topic.suggestedArticleCount}
                    topicCoverage={topicsCoverage[topic.topicName]}
                  />
                ))
            : new Array(6)
                .fill(0)
                .map((_, index) => (
                  <div key={index} className={styles.mockTopic}></div>
                ))}
        </div>
      </div>
    </div>
  );
};

const SearchArticles = () => {
  const { setVisible, bindings } = useModal();
  const [searchPhrase, setSearchPhrase] = useState("");
  const navigate = useNavigate();

  const { data, refetch, isLoading } = useSearchArticle(searchPhrase, false);

  useEffect(() => {
    const handler = setTimeout(() => {
      // Trigger API call or any side effect here

      if (searchPhrase) {
        refetch();
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchPhrase]);

  return (
    <div>
      <Button
        auto
        onClick={() => setVisible(true)}
        placeholder={undefined}
        onPointerEnterCapture={undefined}
        onPointerLeaveCapture={undefined}
      >
        Search Articles
      </Button>
      <Modal width="35rem" {...bindings}>
        <Modal.Content>
          <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
            <Search />
            <Input
              autoFocus
              width={"100%"}
              placeholder="Search articles by title"
              onPointerEnterCapture={undefined}
              onPointerLeaveCapture={undefined}
              crossOrigin={undefined}
              value={searchPhrase}
              onChange={(e) => setSearchPhrase(e.target.value)}
            />
          </div>

          {searchPhrase && (
            <div className={styles.results}>
              {data?.length == 0 &&
                searchPhrase &&
                !isLoading &&
                "Seems like there is no results"}
              {isLoading && <Loading />}
              {data?.map((x: any) => {
                return (
                  <div
                    className={styles.result}
                    onClick={() =>
                      navigate(`/knowledge-management/1/article/${x.articleId}`)
                    }
                  >
                    <label>{x.title}</label>
                    <CornerDownRight />
                  </div>
                );
              })}
            </div>
          )}
          {/* <p>This is t
          he width I want.</p> */}
        </Modal.Content>
      </Modal>
    </div>
  );
};
