import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";
import { TTicketView } from "../../assets/types";
import { AssesmentTicketById } from "../components/assesmentTicketById";
import { useGetTicket } from "../../api/useGetTicket";

export const AssessmentByTicketId = () => {
  const isAuthenticated = useAuth0();
  const { ticketId } = useParams();
  const [currTicket, setCurrTicket] = useState<TTicketView>();
  const { data } = useGetTicket(ticketId ?? "", !!isAuthenticated);
  let ticket = data as TTicketView;

  useEffect(() => {
    if (isAuthenticated && ticket) {
      setCurrTicket(ticket);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return <AssesmentTicketById ticket={currTicket}></AssesmentTicketById>;
};
