import { useMemo } from "react";
import cx from "classnames";
import { ChevronRightIcon, DotsHorizontalIcon } from "@radix-ui/react-icons";
import { Button } from "@radix-ui/themes";
import { useFilter } from "./context";
import { FilterValue, findDiveHierarchy } from "./context/FilterContext.model";
import styles from "./ExploreNavbar.module.scss";

export interface NavbarItem {
  title: string;
  onClick?: () => void;
}

interface NavbarProps {
  variant?: "default" | "lite";
  items: NavbarItem[];
}

export function Navbar({ variant = "default", items }: NavbarProps) {
  if (variant === "lite") {
    if (items.length > 3) {
      return (
        <div className={cx(styles.ExploreNavbar, styles.isLite)}>
          <label className={styles.Label}>{items[0].title}</label>
          <ChevronRightIcon />

          <DotsHorizontalIcon />

          <ChevronRightIcon />
          <label className={styles.Label}>
            {items[items.length - 2].title}
          </label>
          <ChevronRightIcon />
          <label className={styles.Label}>
            {items[items.length - 1].title}
          </label>
        </div>
      );
    }

    return (
      <div className={cx(styles.ExploreNavbar, styles.isLite)}>
        {items.map(({ title }, index) => (
          <>
            <label key={title} className={styles.Label}>
              {title}
            </label>

            {index < items.length - 1 && <ChevronRightIcon />}
          </>
        ))}
      </div>
    );
  }

  return (
    <div className={styles.ExploreNavbar}>
      {items.map(({ title, onClick }, index) => {
        return (
          <>
            {onClick != null ? (
              <Button
                key={title}
                color="gray"
                variant="ghost"
                highContrast
                size="1"
                onClick={onClick}
                className={styles.Button}
              >
                {title}
              </Button>
            ) : (
              <label key={title} className={styles.Label}>
                {title}
              </label>
            )}

            {index < items.length - 1 && <ChevronRightIcon />}
          </>
        );
      })}
    </div>
  );
}

export interface UseExploreNavbarOptions {
  titleOverrides?: Record<string, string>;
  staticPrefix?: string[];
  staticSuffix?: string;
}

export function useExploreNavbar({
  titleOverrides = {},
  staticPrefix,
  staticSuffix,
}: UseExploreNavbarOptions) {
  const {
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    view,
    mainSliceBy,
    resetFilter,
  } = useFilter();

  return useMemo<NavbarItem[]>(() => {
    function overrideTitle(title = ""): string {
      return titleOverrides[title] ?? title;
    }

    const currFilterValue: FilterValue = {
      groupBy,
      stringSliceBy,
      numericSliceBy,
      dateRange,
      view,
      mainSliceBy,
    };

    return [
      ...(staticPrefix?.map((title) => ({ title })) ?? []),
      ...findDiveHierarchy(currFilterValue).map((value) => {
        return {
          title: overrideTitle(value.mainSliceBy?.value ?? value.groupBy),
          onClick: () => resetFilter(value),
        };
      }),
      {
        title: overrideTitle(
          currFilterValue.mainSliceBy?.value ?? currFilterValue.groupBy
        ),
      },
      ...(staticSuffix != null ? [{ title: staticSuffix }] : []),
    ];
  }, [
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    view,
    mainSliceBy,
    staticPrefix,
    staticSuffix,
    titleOverrides,
  ]);
}
