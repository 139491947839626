import { PropsWithChildren, useContext, useCallback } from "react";
import cx from "classnames";
import { Theme } from "@radix-ui/themes";
import * as DropdownMenuPrimitive from "@radix-ui/react-dropdown-menu";
import styles from "./DropdownMenu.module.scss";
import { ThemeContext } from "../../Theme";

function Trigger({ children }: PropsWithChildren) {
  return (
    <DropdownMenuPrimitive.Trigger asChild>
      {children}
    </DropdownMenuPrimitive.Trigger>
  );
}

interface DropdownMenuItem {
  label: string;
  onClick: () => void;
  icon?: JSX.Element;
}

type MenuItemProps = DropdownMenuItem;

function MenuItem({ label, onClick, icon }: MenuItemProps) {
  return (
    <DropdownMenuPrimitive.Item
      onSelect={onClick}
      className={styles.DropdownMenuItem}
    >
      {icon}
      {label}
    </DropdownMenuPrimitive.Item>
  );
}

interface DropdownMenuProps {
  items: DropdownMenuItem[];
}

function DropdownMenu({
  children,
  items,
}: PropsWithChildren<DropdownMenuProps>) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <DropdownMenuPrimitive.Root>
      {children}

      <DropdownMenuPrimitive.Portal>
        <Theme>
          <DropdownMenuPrimitive.Content
            align={"start"}
            sideOffset={5}
            className={cx(styles.DropdownMenuContent, {
              [styles.tooltip_light]: !isDarkMode,
              [styles.tooltip_dark]: isDarkMode,
            })}
          >
            {items.map((item) => (
              <MenuItem {...item} />
            ))}
          </DropdownMenuPrimitive.Content>
        </Theme>
      </DropdownMenuPrimitive.Portal>
    </DropdownMenuPrimitive.Root>
  );
}

DropdownMenu.Trigger = Trigger;

export default DropdownMenu;
