import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { X } from "@geist-ui/icons";
import { Drawer, Tabs } from "@geist-ui/core";
import dayjs from "dayjs";
import { createToast } from "vercel-toast";

import { PopoverMenu } from "../../../../components/popup";
import { CircularChart } from "../../../../components/atoms/circleChart";
import { Shapes } from "../../../../components/icons/shapes";
import { IMessage, Messages } from "../messages";
import { SourceItem } from "../sourceItem";
import {
  useAccuracySources,
  useGetArticlesByIds,
} from "../../../../api/useAccuracySources";
import { TCommonQuestion } from "../../index.types";
import { useUpdateAutoConfig } from "../../../../api/useCommonQuestions";
import { ActivationEvents, mixTrackEvent } from "../../../../assets/mixpanel";
import { getUrlForTicketSystem } from "../../../../assets/functions/ticketSystems";

import styles from "./styles.module.scss";
import { optionsJustSourceDetails } from "./configurationTools.config";
import {
  ELengthAnswer,
  EPersonalCustom,
  EToneOfVoice,
  TAccuracyConfig,
  TAccuracyDataType,
  TArticle,
  TOptionsAutoConfig,
} from "./configurationTools.types";

export const ConfigurationTools = ({
  messages,
  questionId,
  question,
  askTryAgain,
  config: autoConfigData,
}: {
  messages: IMessage[];
  questionId: number;
  question: TCommonQuestion;
  askTryAgain: (message: string) => void;
  config: TAccuracyConfig;
}) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { isLoading, data } = useAccuracySources(
    questionId,
    !!questionId && isDrawerOpen
  );

  const accuracyData = data as TAccuracyDataType;
  const { data: articlesData } = useGetArticlesByIds(
    data?.articlesIds || [],
    !!data?.articlesIds && isDrawerOpen
  );

  const { data: updatedAutoConfig, mutateAsync: mutateUpdateAutoConfig } =
    useUpdateAutoConfig();

  const articles = (articlesData?.articles || []) as TArticle[];

  const notifyToastAutoConfig = (isSuccess = false) => {
    const message: string = isSuccess
      ? "the configuration has been updated successfully."
      : "An error occurred while updating the configuration.";
    createToast(message, {
      type: isSuccess ? "success" : "error",
      timeout: 5000,
    });
  };

  useEffect(() => {
    if (updatedAutoConfig?.statusCode && updatedAutoConfig.statusCode !== 200) {
      notifyToastAutoConfig(false);
    } else {
      if (updatedAutoConfig) {
        askTryAgain("");
        notifyToastAutoConfig(true);
      }
    }
  }, [updatedAutoConfig, askTryAgain]);

  const handlePopoverClick = async (key: string) => {
    const actions: { [key: string]: () => void | Promise<any> } = {
      source_details: () => {
        mixTrackEvent({
          event: ActivationEvents.OPEN_SOURCE_DETAILS_DRAWER,
        });
        setDrawerOpen(true);
      },
      "length::short": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          lengthAnswer: ELengthAnswer.SHORT,
        }),
      "length::medium": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          lengthAnswer: ELengthAnswer.MEDIUM,
        }),
      "length::long": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          lengthAnswer: ELengthAnswer.LONG,
        }),
      "tone_of_voice::empathetic": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          toneOfVoice: EToneOfVoice.EMPATHETIC,
        }),
      "tone_of_voice::technical": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          toneOfVoice: EToneOfVoice.TECHNICAL,
        }),
      "tone_of_voice::supportive": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          toneOfVoice: EToneOfVoice.SUPPORTIVE,
        }),
      "tone_of_voice::encourage": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          toneOfVoice: EToneOfVoice.ENCOURAGE,
        }),
      "personalization::basic": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          personalCustom: EPersonalCustom.BASIC,
        }),
      "personalization::mellow": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          personalCustom: EPersonalCustom.MELLOW,
        }),
      "personalization::hyper": () =>
        mutateUpdateAutoConfig({
          commonQuestionId: questionId,
          personalCustom: EPersonalCustom.HYPER,
        }),
    };

    mixTrackEvent({
      event: ActivationEvents.SET_CONFIGURATION_TOOLS,
      properties: {
        instruction_config: key,
      },
    });

    if (actions[key]) {
      actions[key]();
    }
  };

  const options = useMemo(() => {
    if (!autoConfigData) {
      return optionsJustSourceDetails;
    }

    const updateSubOptions = (
      option: TOptionsAutoConfig,
      key: string,
      dataKey: "personalCustom" | "toneOfVoice" | "lengthAnswer"
    ) => {
      if (option.key === key) {
        const newSubOptions = option.subOptions?.map((subOption) => ({
          ...subOption,
          isSelected:
            autoConfigData?.[dataKey] && subOption?.key
              ? autoConfigData[dataKey].toLowerCase() ===
                subOption.key.toLowerCase()
              : false,
        }));
        option.subOptions = newSubOptions;
      }
    };

    optionsJustSourceDetails.forEach((option) => {
      updateSubOptions(option, "length", "lengthAnswer");
      updateSubOptions(option, "tone_of_voice", "toneOfVoice");
      updateSubOptions(option, "personalization", "personalCustom");
    });

    return optionsJustSourceDetails;
  }, [autoConfigData]);

  return (
    <>
      <PopoverMenu
        options={options}
        handleClick={handlePopoverClick}
        placement="top-start"
        disableOverflowScroll
        onClick={(s) =>
          s &&
          mixTrackEvent({
            event: ActivationEvents.OPEN_CONFIGURATION_TOOLS,
          })
        }
      >
        <button>
          <Shapes />
        </button>
      </PopoverMenu>
      <Drawer
        className={cx(styles.drawer, "dark_mode")}
        visible={isDrawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        {!isLoading ? (
          <>
            <div className={styles.header}>
              <button onClick={() => setDrawerOpen(false)}>
                <X />
              </button>
              <h3>
                <Shapes />
                Accuracy & Sources
              </h3>
            </div>
            <div className={styles.body}>
              <div className={styles.left}>
                <div className={styles.bodyLeftTopStats}>
                  <div className={styles.circleStats}>
                    <CircularChart
                      percentage={
                        accuracyData?.commonQuestion.answers[0]?.score
                      }
                      label="Accuracy score"
                      number={`${accuracyData?.commonQuestion.answers[0]?.score}%`}
                    />
                  </div>
                </div>
                {question?.similarTicket?.description &&
                  question?.similarTicket?.subject && (
                    <div className={styles.callout}>
                      <h4>Similar Ticket Subject</h4>
                      <p>{question?.similarTicket?.subject}</p>
                      <h4>Similar Ticket Description</h4>
                      <p>{question?.similarTicket?.description}</p>
                    </div>
                  )}
                <Tabs
                  initialValue="knowledge_base"
                  className={styles.tabsContainer}
                >
                  <Tabs.Item value="knowledge_base" label="Knowledge Base">
                    {articles?.map((article: TArticle) => {
                      return (
                        <SourceItem
                          key={article.articleId}
                          source="Article"
                          title={article.title}
                          url={article.url}
                          updatedAt={dayjs(article.updatedAt).format(
                            "MMMM D, YYYY"
                          )}
                          isActive={true}
                          precentage={100}
                        />
                      );
                    })}
                  </Tabs.Item>
                  <Tabs.Item value="Tickets" label="tickets">
                    {accuracyData?.tickets.map((ticket, index) => {
                      const ticketId = ticket.id;
                      return (
                        <SourceItem
                          key={index}
                          title={ticket.displayId ?? ticketId}
                          source="Ticket"
                          url={getUrlForTicketSystem(ticketId)}
                          updatedAt={dayjs(ticket.updatedAt).format(
                            "MMMM D, YYYY"
                          )}
                          isActive={true}
                          precentage={100}
                        />
                      );
                    })}
                  </Tabs.Item>
                </Tabs>
              </div>
              <div className={styles.right}>
                <Messages messages={messages} isAnswerLoading={false} />
              </div>
            </div>
          </>
        ) : (
          <div className={styles.loadAccuracyDrawer}></div>
        )}
      </Drawer>
    </>
  );
};
