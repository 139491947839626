import { useAuthorizedQuery } from "..";
import { params } from "../../params";

export const useGetAccess = (enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: "getAccess",
    url: `${params.API_URL}/gateway/access`,
    method: "GET",
    enabled,
  });
};
