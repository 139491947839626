import { useAuthorizedQuery } from "..";
import { params } from "../../params";
import { Tenant } from "./tenants.model";

export const useGetTenants = (enabled: boolean = true) => {
  return useAuthorizedQuery<Tenant[]>({
    queryKey: "tenants",
    url: `${params.API_URL}/gateway/organization/tenants`,
    method: "GET",
    enabled,
  });
};
