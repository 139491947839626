import { PropsWithChildren, useContext } from "react";
import cx from "classnames";
import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import { Theme } from "@radix-ui/themes";
import { ThemeContext } from "../../Theme";
import styles from "./Tooltip.module.scss";

function Trigger({ children }: PropsWithChildren) {
  return (
    <TooltipPrimitive.Trigger asChild>{children}</TooltipPrimitive.Trigger>
  );
}

function Content({ children }: PropsWithChildren) {
  const { isDarkMode } = useContext(ThemeContext);

  return (
    <TooltipPrimitive.Portal>
      <Theme>
        <TooltipPrimitive.Content
          className={cx(styles.TooltipContent, {
            [styles.tooltip_light]: !isDarkMode,
            [styles.tooltip_dark]: isDarkMode,
          })}
          sideOffset={5}
          side={"bottom"}
          align={"start"}
        >
          {children}
        </TooltipPrimitive.Content>
      </Theme>
    </TooltipPrimitive.Portal>
  );
}

function Tooltip({ children }: PropsWithChildren) {
  return <TooltipPrimitive.Root>{children}</TooltipPrimitive.Root>;
}

Tooltip.Trigger = Trigger;
Tooltip.Content = Content;

export default Tooltip;
