import React, { useCallback, useMemo, useState } from "react";
import cx from "classnames";
import { useParams } from "react-router-dom";
import { FaLockOpen } from "react-icons/fa";
import { IoSparkles } from "react-icons/io5";
import { ChevronLeft, Copy } from "@geist-ui/icons";
import { Card } from "@geist-ui/core";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import { createToast } from "vercel-toast";

import { useGetSuggestedArticles } from "../../api/useGetSuggestedArticles";
import { SuggestedArticle } from "../knowledgeByTopic/index.types";
import { EBadgeMode, GeistBadge } from "../../components/atoms/geistBadge";
import { useGetTopics } from "../../api/useGetTopics";
import { KnowledgeEvents, mixTrackEvent } from "../../assets/mixpanel";

import styles from "./styles.module.scss";

export const ArticleDraft = () => {
  const { topicId, articleId } = useParams();
  const { data: suggesterArticles, isLoading: articleLoading } =
    useGetSuggestedArticles(topicId ?? "", !!topicId);
  const { data: topics, isLoading: loadTopics } = useGetTopics();

  const isloading = useMemo(
    () =>
      articleLoading ||
      loadTopics ||
      suggesterArticles === undefined ||
      topics === undefined ||
      !!suggesterArticles.error ||
      !!topics.error,
    [articleLoading, loadTopics, suggesterArticles, topics]
  );

  const article: SuggestedArticle = useMemo(() => {
    if (!suggesterArticles || !suggesterArticles.length) return null;
    return suggesterArticles.find(
      (article: SuggestedArticle) => article.id === Number(articleId)
    );
  }, [suggesterArticles, articleId]);

  const navigate = useNavigate();
  const [copyPressed, setCopyPressed] = useState(false);

  const renderContent = useCallback((content: string) => {
    const isHTML = /<\/?[a-z][\s\S]*>/i.test(content);
    if (isHTML) {
      return <div dangerouslySetInnerHTML={{ __html: content }} />;
    } else {
      return content.split("\n").map((line: string, index: number) => (
        <React.Fragment key={index}>
          {line}
          <br />
        </React.Fragment>
      ));
    }
  }, []);

  return (
    <div className={cx(styles.mainContainer, { [styles.loading]: isloading })}>
      <div className={styles.headerContainer}>
        <div className={styles.badgeContainer}>
          <GeistBadge
            mode={isloading ? EBadgeMode.LOAD : EBadgeMode.PRETTY}
            onClick={() => {
              mixTrackEvent({
                event: KnowledgeEvents.CREATE_DRAFT_ARTICLE,
              });
              createToast(
                "Coming soon! For now, you can copy the article's content",
                {
                  type: "default",
                  timeout: 3000,
                }
              );
            }}
          >
            <FaLockOpen />
            Create draft from article
          </GeistBadge>
        </div>
        <div className={styles.titleContainer}>
          {!isloading ? (
            <ChevronLeft onClick={() => navigate(-1)} />
          ) : (
            <h1>sbg</h1>
          )}

          <h1>
            {!isloading ? article.title : "PRETTY LONG HEADLINE DOR LOAD"}
          </h1>
          <GeistBadge mode={isloading ? EBadgeMode.LOAD : EBadgeMode.YELLOW}>
            <IoSparkles /> Auto Generated
          </GeistBadge>
        </div>
      </div>
      <div className={styles.cardsContainer}>
        <div className={cx(styles.sideContainer, styles.leftSideContainer)}>
          <Card className={styles.articleContent}>
            <div
              className={cx(styles.content, {
                [styles.copyPressed]: copyPressed,
              })}
            >
              <Copy
                onClick={() => {
                  mixTrackEvent({
                    event: KnowledgeEvents.COPY_DRAFT_ARTICLE,
                  });
                  setCopyPressed(true);
                  navigator.clipboard.writeText(article?.content);
                }}
              />
              <p>{article?.content && renderContent(article.content)}</p>
            </div>
          </Card>
        </div>
        <div className={styles.sideContainer}>
          <Card className={styles.articleDetails}>
            <div className={styles.content}>
              <div className={styles.detailsRow}>
                <h4>Created at</h4>
                <h5>{dayjs(article?.createdAt).format("DD MMM HH:mm a")}</h5>
              </div>
              {!isloading && !!topics && topics.length && (
                <div className={styles.detailsRow}>
                  <h4>Topics</h4>
                  <div className={styles.topicsContainer}>
                    {article?.topicIds.map((topicId: string) => {
                      const _topic = topics.find(
                        (t: { id: number; name: string; topic: String }) =>
                          t.id === Number(topicId)
                      );
                      const topicName = _topic?.name || _topic?.topic;
                      return <GeistBadge key={topicId}>{topicName}</GeistBadge>;
                    })}
                  </div>
                </div>
              )}
              {/* <div className={styles.detailsRow}>
                <h4>Estimated impact</h4>
                <h5 className={styles.impact}>
                  {(article?.impact * 100).toFixed(2)}%
                </h5>
              </div> */}
            </div>
          </Card>
          <Card className={styles.articleDetails}>
            <h4>TLDR</h4>
            <div className={styles.content}>
              <div className={styles.detailsRow}>
                <h4>{article?.changesTldr || article?.tldr}</h4>
              </div>
            </div>
          </Card>
          {/* <Card className={styles.articleDetails}>
            <div className={styles.content}>
              <h4>Common Questions</h4>
              {article?.commonQuestions.map(
                (question: string, index: number) => {
                  return (
                    <div className={styles.detailsRow} key={index}>
                      <h4>{question}</h4>
                    </div>
                  );
                }
              )}
            </div>
          </Card> */}
        </div>
      </div>
    </div>
  );
};
