import { useState } from "react";
import cx from "classnames";
import { Button } from "@radix-ui/themes";
import { ViewVerticalIcon, ReaderIcon } from "@radix-ui/react-icons";
import TicketOverview from "./TicketOverview";
import styles from "./TicketQAScore.module.scss";
import { TicketExploreRow } from "../../../api/useExplore/Explore.model";
import { TicketComments } from "./TicketQAScore.model";
import TicketTranscript from "./TicketTranscript";

interface SidePanelProps {
  ticket: TicketExploreRow;
  comments: TicketComments[];
}

function SidePanel({ ticket, comments }: SidePanelProps) {
  const [activeTab, setActiveTab] = useState<"overview" | "transcript">(
    "overview"
  );

  return (
    <div className={styles.SidePanel}>
      <div className={styles.Tabs}>
        <Button
          variant={"outline"}
          color={"gray"}
          size={"1"}
          onClick={() => setActiveTab("overview")}
          className={cx(styles.TabButton, {
            [styles.active]: activeTab === "overview",
          })}
        >
          <ViewVerticalIcon /> Overview
        </Button>

        <Button
          variant={"outline"}
          color={"gray"}
          size={"1"}
          onClick={() => setActiveTab("transcript")}
          className={cx(styles.TabButton, {
            [styles.active]: activeTab === "transcript",
          })}
        >
          <ReaderIcon /> Transcript
        </Button>
      </div>

      <div className={styles.TabContent}>
        {activeTab === "overview" && <TicketOverview {...ticket} />}

        {activeTab === "transcript" && <TicketTranscript comments={comments} />}
      </div>
    </div>
  );
}

export default SidePanel;
