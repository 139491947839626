import { useEffect, useLayoutEffect } from "react";
import { useRef } from "react";

export function ChatbotSandbox() {
  const queryParams = new URLSearchParams(window.location.search);
  const tParam = queryParams.get("t");
  console.log("Query param t:", tParam);

  const isScriptInitialized = useRef(false);

  useLayoutEffect(() => {
    if (isScriptInitialized.current) return;

    (function (
      q: any,
      u: any,
      a: any,
      c: any,
      k: any,
      i: any = {},
      o: any = undefined
    ) {
      q[a] =
        q[a] ||
        function () {
          (q[a].q = q[a].q || []).push(arguments);
        };
      i = u.createElement(c);
      i.async = true;
      i.id = "quack-license";
      i.src = "https://sdk-quack.s3.amazonaws.com/p/verify.min.js";
      i.setAttribute(a, k);
      o = u.getElementsByTagName(c)[0];
      if (o && o.parentNode) {
        o.parentNode.insertBefore(i, o);
      }
    })(window, document, "quack", "script", tParam);

    isScriptInitialized.current = true;
  }, []);

  return (
    <div
      style={{
        marginTop: "20vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      Sandbox environment to test the chat
      <br />
      Check bottom right corner
    </div>
  );
}
