export enum IntegrationSetupStatus {
  COMPLETED = "COMPLETED",
  IN_PROGRESS = "IN_PROGRESS",
  NOT_AVAILABLE = "NOT_AVAILABLE",
  UNDEFINED = "UNDEFINED",
}

export enum IntegrationType {
  TICKET_SYSTEM = "TICKET_SYSTEM",
  CRM = "CRM",
  CHAT = "CHAT",
  OBSERVABILITY = "OBSERVABILITY",
  PRODUCT_ANALYTICS = "PRODUCT_ANALYTICS",
  KNOWLEDGE_BASE = "KNOWLEDGE_BASE",
  OTHER = "OTHER",
  SDK = "SDK",
}

export interface Integration {
  id?: string;
  name: string;
  status?: IntegrationSetupStatus;
  type: IntegrationType;
  isEnabled: boolean;
  metadata?: string;
}
