import { useCallback } from "react";
import { Dialog } from "../../../components/shared/dialog";
import { params } from "../../../params";
import { useAuthorizedQuery } from "../../../api";
import { Navbar, NavbarItem } from "../../explore/ExploreNavbar";
import TicketQuestionsScore from "./TicketQuestionsScore";
import SidePanel from "./SidePanel";
import styles from "./TicketQAScore.module.scss";
import {
  TicketExploreRow,
  printTicketId,
} from "../../../api/useExplore/Explore.model";
import { TicketQAScoreResponse } from "./TicketQAScore.model";

const useTicketData = (ticketId?: string) => {
  return useAuthorizedQuery<TicketQAScoreResponse>({
    queryKey: `useTicketData-${ticketId}`,
    url: `${params.API_URL}/platform/sampling/tickets/${ticketId}`,
    method: "GET",
    enabled: ticketId != null,
  });
};

interface TicketQAScoreProps {
  ticket?: TicketExploreRow;
  navbarItems: NavbarItem[];
  onClose: () => void;
}

function TicketQAScore({ ticket, navbarItems, onClose }: TicketQAScoreProps) {
  const { data, isLoading, refetch } = useTicketData(ticket?.id);

  const handleOpenChanged = useCallback(
    (open: boolean) => {
      if (!open) {
        onClose();
      }
    },
    [onClose]
  );

  if (ticket == null || isLoading) {
    return <></>;
  }

  return (
    <Dialog open onOpenChange={handleOpenChanged}>
      <Dialog.Content size={"large"}>
        <Dialog.Title>
          <Navbar
            variant={"lite"}
            items={[
              ...navbarItems,
              ...(ticket != null
                ? [{ title: printTicketId(ticket.overview.id) }]
                : []),
            ]}
          />

          <Dialog.Close />
        </Dialog.Title>

        <div className={styles.TicketQAScore}>
          <TicketQuestionsScore
            ticket={ticket}
            ticketScore={data?.ticketScore}
            onUpdateTicketScore={refetch}
          />

          <SidePanel ticket={ticket} comments={data?.comments ?? []} />
        </div>
      </Dialog.Content>
    </Dialog>
  );
}

export default TicketQAScore;
