import { useState, useEffect, useCallback, useMemo } from "react";
import { TicketExploreRow } from "../../../api/useExplore/Explore.model";
import Select, { SelectOption } from "../../../components/shared/select";
import ExploreTable, { ColumnType, TableSum } from "../../explore/ExploreTable";
import { useValidationSetSample } from "../../../api/useValidations";
import { Navbar, NavbarItem } from "../../explore/ExploreNavbar";
import exploreStyles from "../../explore/styles.module.scss";
import styles from "./Validations.module.scss";
import { Badge, Spinner } from "@radix-ui/themes";
import TicketQAScore from "../ticket-qa-score/TicketQAScore";
import {
  ticketExploreCellGenerator,
  ticketExploreCellValueGetter,
} from "../../explore/CellGenerator";

const ticketColumns: ColumnType<keyof TicketExploreRow>[] = [
  {
    label: "Tickets",
    key: "overview",
    width: "45%",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
  {
    label: "Agent",
    key: "agent",
  },
  {
    label: "CSAT",
    key: "ticketCsat",
  },
];

interface ValidationSetProps {
  name: string;
  samplesOptions: SelectOption[];
  backToValidationSets: () => void;
}

function ValidationSet({
  name,
  samplesOptions,
  backToValidationSets,
}: ValidationSetProps) {
  const [selectedSampleId, setSelectedSampleId] = useState<string>(
    samplesOptions?.[0]?.value
  );
  const [ticket, setTicket] = useState<TicketExploreRow>();

  const { data, isLoading } = useValidationSetSample(selectedSampleId);

  const rows = useMemo<TicketExploreRow[] | undefined>(() => {
    return data?.tickets.map(({ ticket }) => {
      const totalQaScore = ticket.ticketScore.reduce(
        (sum, { autoScore, adjustedScore }) =>
          sum + (adjustedScore ?? autoScore ?? 0),
        0
      );
      const avgQaScore = totalQaScore / ticket.ticketScore.length;

      return {
        id: ticket.id,
        overview: {
          id: ticket?.displayId ?? ticket.id.split("::")[1],
          subject: ticket.subject,
        },
        agent: ticket.agentAssigned?.name,
        avgQaScore,
        ticketCsat: ticket.ticketCsat?.score,
      };
    });
  }, [data]);

  const toggleTicket = useCallback((ticket: TicketExploreRow) => {
    setTicket(ticket);
  }, []);

  const handleClose = useCallback(() => {
    setTicket(undefined);
  }, []);

  useEffect(() => {
    if (samplesOptions.length > 0) {
      setSelectedSampleId(samplesOptions[0].value);
    }
  }, [samplesOptions]);

  const navbarItems = useMemo<NavbarItem[]>(
    () => [
      { title: "quality" },
      { title: "validations", onClick: backToValidationSets },
      { title: name },
    ],
    [name, backToValidationSets]
  );

  return (
    <div className={exploreStyles.exploreContainer}>
      <div className={exploreStyles.controlRow}>
        <Navbar items={navbarItems} />
      </div>

      <div className={exploreStyles.controlRow}>
        <div className={exploreStyles.buttonGroup} />

        <Select
          options={samplesOptions}
          value={selectedSampleId}
          onChange={setSelectedSampleId}
          size={"small"}
          classes={{
            trigger: styles.SampleSelect,
          }}
        />
      </div>

      <TableSum
        label={"random"}
        isLoading={isLoading}
        count={data?.tickets.length}
      />

      <ExploreTable<TicketExploreRow>
        columns={ticketColumns}
        rows={rows}
        isLoading={isLoading}
        cellGenerator={ticketExploreCellGenerator}
        cellValueGetter={ticketExploreCellValueGetter}
        onRowClick={toggleTicket}
      />

      <TicketQAScore
        ticket={ticket}
        navbarItems={navbarItems}
        onClose={handleClose}
      />
    </div>
  );
}

export default ValidationSet;
