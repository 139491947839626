import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ExploreRow,
  TicketExploreRow,
  translateColumnsToExploreRows,
  translateColumnsToTicketExploreRows,
} from "../../api/useExplore/Explore.model";
import {
  useExplore,
  useExploreInfiniteTickets,
  useExploreValues,
} from "../../api/useExplore";
import { LLM } from "./actions/llm";
import { useAuth0 } from "@auth0/auth0-react";
import { FilterProvider, useFilter } from "./context";
import { Search } from "./actions/search";
import { Keyword } from "./actions/keyword";
import {
  buildRange,
  FiniteStateSlicer,
  pasrseFilterValue,
} from "./context/FilterContext.model";
import SlicedBy from "./SlicedBy";
import DateRangePicker from "./DateRangePicker";
import {
  Navbar,
  useExploreNavbar,
  UseExploreNavbarOptions,
} from "./ExploreNavbar";
import styles from "./styles.module.scss";
import { exploreLevel1Table, exploreTicketsLevelTable } from "./stractures";
import ExploreTable, { ColumnType, TableSum } from "./ExploreTable";
import {
  exploreCellGenerator,
  ticketExploreCellGenerator,
  ticketExploreCellValueGetter,
} from "./CellGenerator";
import { useGenerateReport } from "../../api/useGenerateReport";
import { Button } from "@radix-ui/themes";

interface ExploreComponentProps {
  navbarOptions?: UseExploreNavbarOptions;
  exploreLevel1Table?: ColumnType<keyof ExploreRow>[];
  onTicketClick?: (ticket: TicketExploreRow) => void;
  finiteState?: boolean;
}

export const ExploreComponent = ({
  navbarOptions = {},
  onTicketClick,
  finiteState = false,
  ...props
}: ExploreComponentProps) => {
  const { user, getIdTokenClaims } = useAuth0();
  const {
    mutate: loadExplore,
    isLoading: exploreLoading,
    data: exploreData,
  } = useExplore();
  const { data: exploreValues } = useExploreValues(!!user);

  const {
    groupBy,
    stringSliceBy,
    numericSliceBy,
    dateRange,
    view,
    mainSliceBy,
    diveInto,
    diveIntoTickets,
  } = useFilter();

  const exploreNavbarItems = useExploreNavbar(navbarOptions);

  const [token, setToken] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState("");

  const ticketsQuery = useExploreInfiniteTickets(
    {
      groupBy: groupBy,
      ...(numericSliceBy ? { numericSliceBy } : {}),
      ...(finiteState
        ? {
            stringSliceBy: {
              and: [FiniteStateSlicer, ...(stringSliceBy?.and ?? [])],
            },
          }
        : {
            ...(stringSliceBy ? { stringSliceBy } : {}),
          }),
      dateRange,
      mainSliceBy,
      includeComments: true,
    },
    !!user && view === "tickets"
  );

  const data = view === "tickets" ? ticketsQuery.data?.pages[0] : exploreData;
  const isLoading =
    view === "tickets" ? ticketsQuery.isLoading : exploreLoading;

  const exploreTableProps = useMemo(() => {
    const columns = (props.exploreLevel1Table ?? exploreLevel1Table).map(
      (column) => {
        if (column.key === "name") {
          return { ...column, label: groupBy ?? "topics" };
        }

        return column;
      }
    );

    if (exploreData == null) {
      return { columns };
    }

    const rows = translateColumnsToExploreRows(exploreData).filter(
      ({ avgVolume, name }) =>
        (avgVolume ?? 0) >= 1 && name.toLowerCase().includes(searchPhrase)
    );

    return { columns, rows };
  }, [exploreData, searchPhrase, props.exploreLevel1Table]);

  const ticketExploreTableProps = useMemo(() => {
    const rows = ticketsQuery.data?.pages
      .map((page) => {
        return translateColumnsToTicketExploreRows(page);
      })
      .flat();

    return { columns: exploreTicketsLevelTable, rows };
  }, [ticketsQuery]);

  const agentId = useMemo(() => {
    if (mainSliceBy?.type !== "agent") {
      return;
    }

    return ticketsQuery.data?.pages[0]?.ticketsData?.tickets[0].agentAssigned
      .id;
  }, [mainSliceBy, ticketsQuery.data]);

  const { generateReport, isLoading: generatingReport } =
    useGenerateReport(agentId);

  const handleExloreRowClick = useCallback(
    (row: ExploreRow) => {
      if (groupBy === "teams") {
        diveInto("teams", "agents", row.name);
      } else {
        diveIntoTickets(row.name);
      }
    },
    [groupBy, diveInto, diveIntoTickets]
  );

  useEffect(() => {
    getIdTokenClaims().then((claims) => {
      setToken(true);
    });
  }, [getIdTokenClaims]);

  useEffect(() => {
    if (user && token) {
      if (view == null) {
        loadExplore(
          pasrseFilterValue({
            groupBy: groupBy,
            ...(numericSliceBy ? { numericSliceBy } : {}),
            ...(finiteState
              ? {
                  stringSliceBy: {
                    and: [FiniteStateSlicer, ...(stringSliceBy?.and ?? [])],
                  },
                }
              : {
                  ...(stringSliceBy ? { stringSliceBy } : {}),
                }),
            dateRange,
            mainSliceBy,
          })
        );
      }
    }
  }, [
    groupBy,
    view,
    user,
    token,
    loadExplore,
    dateRange,
    numericSliceBy,
    stringSliceBy,
    mainSliceBy,
    finiteState,
  ]);

  return (
    <div className={styles.exploreContainer}>
      <div className={styles.controlRow}>
        <Navbar items={exploreNavbarItems} />

        <div className={styles.buttonGroup}>
          {view === "tickets" && agentId != null && (
            <Button
              color={"gray"}
              variant={"solid"}
              highContrast
              size={"1"}
              loading={generatingReport}
              onClick={() => {
                const { dateFrom, dateTo } = buildRange[dateRange]();
                generateReport(dateFrom, dateTo);
              }}
            >
              Generate report
            </Button>
          )}

          <LLM
            data={
              view === "tickets"
                ? ticketExploreTableProps.rows
                : exploreTableProps.rows
            }
          />
        </div>
      </div>

      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          <SlicedBy
            isLoading={isLoading}
            ticketsCount={data?.metadata?.ticketCount}
            groupBy={data?.metadata?.groupBy}
            exploreValues={exploreValues}
            finiteState={finiteState}
          />
        </div>
      </div>

      <div className={styles.controlRow}>
        <div className={styles.buttonGroup}>
          <Search {...{ searchPhrase, setSearchPhrase }} />

          <Keyword />
        </div>

        <DateRangePicker />
      </div>

      <TableSum
        isLoading={isLoading}
        {...(view === "tickets"
          ? {
              label: "tickets",
              count: ticketExploreTableProps.rows?.length,
              totalCount: data?.metadata?.ticketCount,
            }
          : {
              label: groupBy ?? "topics",
              count: exploreTableProps.rows?.length,
            })}
      />

      {view === "tickets" ? (
        <ExploreTable<TicketExploreRow>
          {...ticketExploreTableProps}
          isLoading={isLoading}
          groupBy={groupBy}
          hasNextPage={ticketsQuery.hasNextPage}
          fetchNextPage={ticketsQuery.fetchNextPage}
          isFetchingNextPage={ticketsQuery.isFetchingNextPage}
          onRowClick={onTicketClick}
          cellGenerator={ticketExploreCellGenerator}
          cellValueGetter={ticketExploreCellValueGetter}
          defaultSortState={{ key: "overview", direction: "asc" }}
        />
      ) : (
        <ExploreTable<ExploreRow>
          {...exploreTableProps}
          isLoading={isLoading}
          groupBy={groupBy}
          hasNextPage={ticketsQuery.hasNextPage}
          fetchNextPage={ticketsQuery.fetchNextPage}
          isFetchingNextPage={ticketsQuery.isFetchingNextPage}
          onRowClick={handleExloreRowClick}
          cellGenerator={exploreCellGenerator}
        />
      )}
    </div>
  );
};

export const Explore = () => {
  return (
    <FilterProvider>
      <ExploreComponent />
    </FilterProvider>
  );
};
