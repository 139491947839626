import { useContext, useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import cx from "classnames";
import { useFeatureFlag } from "configcat-react";
import { Redirect } from "../redirect";
import logoImg from "../../assets/logo.png";
import {
  ESessionStorage,
  fetchFromSessionStorage,
} from "../../assets/storage/sessionStorage";
import { GeneralEvents, mixTrackEvent } from "../../assets/mixpanel";
import styles from "./styles.module.scss";
import { ENavigate, PagesIcons } from "./sidebar.types";
import { Button, DropdownMenu, Separator } from "@radix-ui/themes";
import * as Collapsible from "@radix-ui/react-collapsible";
import { ThemeContext } from "../Theme";
import {
  AlignRightIcon,
  CaretRightIcon,
  CheckIcon,
  ChevronDownIcon,
  ExitIcon,
  GearIcon,
  MoonIcon,
  PinRightIcon,
  SunIcon,
  TriangleRightIcon,
} from "@radix-ui/react-icons";
import { sharedReportRouteUri } from "../../routes/sharedReport";
import { FeatureAccessContext } from "../../context/featureAccess";
import { useGetAccess } from "../../api/useAccess";

const unprotected = [
  "/installation-successfull",
  "/welcome",
  "/chatbot-sandbox",
];

type Page = ENavigate | { root: ENavigate; pages: ENavigate[] };

function isMultiPage(
  page: Page
): page is { root: ENavigate; pages: ENavigate[] } {
  return (
    page != null && page.hasOwnProperty("root") && page.hasOwnProperty("pages")
  );
}

const reports: Page[] = [
  // ENavigate.ASSESSMENT,
  {
    root: ENavigate.SIMULATOR,
    pages: [ENavigate.AUTOPILOT, ENavigate.COPILOT],
  },
  ENavigate.KNOWLEDGE,
  {
    root: ENavigate.QUALITY,
    pages: [ENavigate.EVALUATIONS, ENavigate.VALIDATIONS],
  },
  ENavigate.SESSIONS_REPORTS,
];

const tooltipMapper: { [x: string]: string } = {
  [ENavigate.HOME]: "Dashboard",
  [ENavigate.ASSESSMENT]: "Topics",
  [ENavigate.ACTIVATION]: "Activation",
  [ENavigate.SIMULATOR]: "Automation",
  [ENavigate.KNOWLEDGE]: "Knowledge management",
  [ENavigate.PERFORMANCE]: "Teams",
  [ENavigate.VOC]: "Categories",
  [ENavigate.CUSTOMERS]: "Customers",
  [ENavigate.SESSIONS_REPORTS]: "Sessions",
  [ENavigate.QUALITY]: "Quality",
  [ENavigate.EVALUATIONS]: "Evaluations",
  [ENavigate.VALIDATIONS]: "Validations",
  [ENavigate.SAMPLING]: "Sampling",
  [ENavigate.COPILOT]: "Co-pilot",
  [ENavigate.AUTOPILOT]: "Auto-pilot",
};

export const Sidebar = () => {
  const { logout, isAuthenticated, isLoading, user } = useAuth0();
  const navigate = useNavigate();
  const { isDarkMode } = useContext(ThemeContext);
  const { toggleTheme } = useContext(ThemeContext);
  const location = useLocation();
  const [firstTopic, setFirstTopic] = useState<string>("");
  const [searchParams, setSearchParams] = useSearchParams();
  const { features, selectedWorkspace, setSelectedWorkspace } =
    useContext(FeatureAccessContext);
  const { data: getAccessData } = useGetAccess(!!user);

  const { value: sidebarValues } = useFeatureFlag("sidebar", "", {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });
  const { value: beta } = useFeatureFlag("betaFeatures", "", {
    identifier: user?.owner ?? "",
    email: user?.email ?? "",
    custom: user ?? {},
  });

  const explorer = [
    ENavigate.ASSESSMENT,
    ENavigate.VOC,
    ENavigate.PERFORMANCE,
    ENavigate.CUSTOMERS,
  ];

  useEffect(() => {
    if (getAccessData && getAccessData.tenants?.[0] && !selectedWorkspace) {
      setSelectedWorkspace(getAccessData.tenants[0]);
    }
  }, [JSON.stringify(getAccessData)]);

  const handleExploreClick = (page: ENavigate) => {
    // if its in explore now, just change the by
    // if its not explore that move it to explore
    if (location.pathname.startsWith("/explore")) {
      setSearchParams(
        (prevParams) =>
          new URLSearchParams([
            ["by", page],
            ["t", prevParams.get("t") ?? "14d"],
          ])
      );
    } else {
      navigate(`/explore?by=${page}&t=14d`);
    }
  };

  const handleClick = (page: ENavigate = ENavigate.HOME) => {
    mixTrackEvent({
      event: GeneralEvents.MOVE_PAGE_FROM_SIDEBAR,
      properties: {
        oldPage: location.pathname.split("/")[1],
        newPage: page,
      },
    });
    switch (page) {
      case ENavigate.ACTIVATION:
        navigate(`/${page}/${firstTopic}`);
        break;
      default:
        navigate(`/${page}`);
        break;
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const _firstTopic = fetchFromSessionStorage(ESessionStorage.FIRST_TOPIC);

      if (_firstTopic) {
        setFirstTopic(_firstTopic);
        clearInterval(interval);
      }
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  if (
    unprotected.includes(window.location.pathname) ||
    location.pathname.includes(sharedReportRouteUri)
  )
    return null;

  if (isLoading)
    return (
      <div className={cx(styles.dark_mode, styles.container)}>
        <div className={styles.loading} />
      </div>
    );

  return (
    <div className={cx(styles.container)}>
      {isAuthenticated ? (
        <>
          <div className={styles.topContainer}>
            <div className={styles.account}>
              <DropdownMenu.Root>
                <DropdownMenu.Trigger>
                  <div>
                    <Button color="gray" variant="outline" size="2">
                      {selectedWorkspace ? selectedWorkspace : user?.owner}
                      <DropdownMenu.TriggerIcon />
                    </Button>
                  </div>
                </DropdownMenu.Trigger>
                <DropdownMenu.Content variant="soft" color="gray">
                  <DropdownMenu.Group>
                    <DropdownMenu.Label>
                      Choose your workspace
                    </DropdownMenu.Label>
                    {getAccessData?.tenants?.map((t: string) => {
                      return (
                        <DropdownMenu.Item
                          shortcut=""
                          onClick={() => setSelectedWorkspace(t)}
                        >
                          {t}
                          {selectedWorkspace === t && <CheckIcon />}
                        </DropdownMenu.Item>
                      );
                    })}
                  </DropdownMenu.Group>
                  <DropdownMenu.Separator />
                  <DropdownMenu.Item
                    shortcut=""
                    onClick={() => navigate("/settings")}
                  >
                    <GearIcon />
                    Settings
                  </DropdownMenu.Item>
                  <DropdownMenu.Item shortcut="" onClick={() => toggleTheme()}>
                    {isDarkMode ? <SunIcon /> : <MoonIcon />}
                    Switch mode
                  </DropdownMenu.Item>
                  <DropdownMenu.Separator />

                  <DropdownMenu.Item
                    shortcut=""
                    onClick={() =>
                      logout({
                        logoutParams: { returnTo: window.location.origin },
                      })
                    }
                  >
                    <ExitIcon />
                    Logout
                  </DropdownMenu.Item>
                </DropdownMenu.Content>
              </DropdownMenu.Root>
            </div>

            <div className={styles.pagesContainer}>
              <label>Opportunities</label>
              {reports.map((page) => {
                if (isMultiPage(page)) {
                  return (
                    <>
                      <Collapsible.Root className="CollapsibleRoot">
                        <Collapsible.Trigger
                          asChild
                          className={styles.collapseTrigger}
                        >
                          <SidebarPageItem
                            key={page.root}
                            page={page.root}
                            onClick={() => {}}
                            active={false}
                            isParent
                          />
                        </Collapsible.Trigger>
                        <Collapsible.Content>
                          {page.pages.map((subPage) => (
                            <>
                              <div
                                style={{ height: "2px", width: "40px" }}
                              ></div>
                              <SidebarPageItem
                                key={subPage}
                                page={subPage}
                                onClick={() => handleClick(subPage)}
                                active={location.pathname.includes(subPage)}
                                indent={1}
                              />
                            </>
                          ))}
                        </Collapsible.Content>
                      </Collapsible.Root>
                    </>
                  );
                }

                return (
                  <SidebarPageItem
                    key={page}
                    page={page}
                    onClick={() => handleClick(page)}
                    active={location.pathname.includes(page)}
                  />
                );
              })}
              <hr />
              <label>Explore</label>
              {explorer.map((page: ENavigate) => {
                if (tooltipMapper[page]) {
                  return (
                    <div
                      key={page}
                      className={cx(styles.pageContainer, {
                        [styles.active]:
                          location.pathname.includes(`explore`) &&
                          location.search.includes(`by=${page}`),
                      })}
                      onClick={() => handleExploreClick(page)}
                    >
                      {PagesIcons[page]}
                      <span>{tooltipMapper[page]}</span>
                    </div>
                  );
                }
                // eslint-disable-next-line no-lone-blocks

                return <></>;
              })}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className={styles.topContainer}>
            <div className={styles.imageContainer}>
              <img
                src={logoImg}
                alt="Quack Logo"
                onClick={() => handleClick()}
              />
            </div>
          </div>
          <Redirect to="/login" />
        </>
      )}
    </div>
  );
};

interface SidebarPageItemProps {
  page: ENavigate;
  active: boolean;
  onClick: () => void;
  indent?: number;
  isParent?: boolean;
}

const SidebarPageItem = ({
  page,
  active,
  onClick,
  indent = 0,
  isParent = false,
}: SidebarPageItemProps) => {
  return (
    <div
      key={page}
      className={cx(styles.pageContainer, {
        [styles.active]: active,
      })}
      onClick={onClick}
      style={{
        marginInlineStart: indent * 15,
      }}
    >
      {PagesIcons[page]}
      <span>{tooltipMapper[page]}</span>
      {isParent && <CaretRightIcon className={styles.collapseBtn} />}
    </div>
  );
};
