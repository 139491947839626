import styles from "./styles.module.scss";
import { IoMdTrash } from "react-icons/io";
import cx from "classnames";
import { createToast } from "vercel-toast";
import { Badge, Tooltip } from "@radix-ui/themes";
import { getMemberRole, Member, PermissionDisplay, Role } from "../../../../api/members/members.model";
import { useDeleteMember } from "../../../../api/members";

export const Card = ({
  member,
  refetch,
  isAdmin,
}: {
  member: Member;
  refetch: () => void;
  isAdmin: boolean
}) => {
  const { mutate: deleteUserMutate, isLoading } = useDeleteMember(member.id);
  const permissionsDisplay = member.permissions.map(p => PermissionDisplay[p]).join(', ');
  return (
    <div className={cx(styles.container)}>
      <div>
        {member.email}
        <Tooltip content="To change the permission -> please reach out support">
          <Badge color={"gray"}>
            {getMemberRole(member) /*member.isAdmin ? "Admin" : permissionsDisplay*/}
          </Badge>
        </Tooltip>
        {getMemberRole(member) === Role.MEMBER ?
          <Badge color={"blue"}>
            {permissionsDisplay}
          </Badge> :
          <></>}
      </div>
      {isLoading ? (
        <div>...</div>
      ) : (
        isAdmin ? 
        <button
          onClick={() => {
            deleteUserMutate(
              {},
              {
                onSuccess: () => {
                  createToast(`Member ${member.email} deleted`, {
                    type: "success",
                    timeout: 3000,
                  });
                  refetch();
                },
              }
            );
          }}
        >
          <IoMdTrash />
        </button>
        : <></>
      )}
    </div>
  );
};
