import { useCallback, useState } from "react";
import { FilterProvider } from "../explore/context";
import { ExploreComponent } from "../explore";
import TicketQAScore from "./ticket-qa-score/TicketQAScore";
import { ColumnType } from "../explore/ExploreTable";
import {
  ExploreRow,
  TicketExploreRow,
} from "../../api/useExplore/Explore.model";
import { useExploreNavbar } from "../explore/ExploreNavbar";

const exploreLevel1Table: ColumnType<keyof ExploreRow>[] = [
  {
    label: "Topics",
    key: "name",
    width: "45%",
  },
  {
    label: "Volume",
    key: "avgVolume",
  },
  {
    label: "Trend Pattern",
    key: "trendVolume",
  },
  {
    label: "Agents Evaluated",
    key: "numberOfAgentsEvaluated",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
];

function EvaluationsComp() {
  const [ticket, setTicket] = useState<TicketExploreRow>();

  const exploreNavbarItems = useExploreNavbar({
    titleOverrides: { teams: "evaluations" },
    staticPrefix: ["quality"],
  });

  const toggleTicket = useCallback((ticket: TicketExploreRow) => {
    setTicket(ticket);
  }, []);

  const handleClose = useCallback(() => {
    setTicket(undefined);
  }, []);

  return (
    <>
      <ExploreComponent
        finiteState
        navbarOptions={{
          titleOverrides: { teams: "evaluations" },
          staticPrefix: ["quality"],
        }}
        exploreLevel1Table={exploreLevel1Table}
        onTicketClick={toggleTicket}
      />

      <TicketQAScore
        ticket={ticket}
        navbarItems={exploreNavbarItems}
        onClose={handleClose}
      />
    </>
  );
}

function Evaluations() {
  return (
    <FilterProvider defaultValue={{ dateRange: "14d", groupBy: "teams" }}>
      <EvaluationsComp />
    </FilterProvider>
  );
}

export default Evaluations;
