import dayjs from "dayjs";
import { TicketComments } from "./TicketQAScore.model";
import styles from "./TicketTranscript.module.scss";

function TicketTranscript({ comments }: { comments: TicketComments[] }) {
  return (
    <div className={styles.Transcript}>
      {comments.map((comment) => (
        <div className={styles.Comment}>
          <div className={styles.Title}>
            {dayjs(comment.updatedAt).format("HH:mm:ss - MM/DD/YYYY")}
          </div>

          <div className={styles.Content}>
            <div className={styles.Body}>
              <div>{comment.rawBody}</div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

export default TicketTranscript;
