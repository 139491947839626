import {
  createContext,
  useContext,
  PropsWithChildren,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useSearchParams } from "react-router-dom";
import {
  FilterValue,
  DateRangeValue,
  StringSlicerType,
  NumericSlicerType,
  StringSlicer,
  NumericSlicer,
  NumericSlicerPreset,
  isNumericSlicerPreset,
  GroupByType,
  componseSlicers,
  parseSlicers,
  groupByToSliceType,
  isGroupByType,
  isDateRangeValue,
  enhanceFilterValue,
} from "./FilterContext.model";

type FilterContextState = FilterValue;

interface FilterContextActions {
  resetFilter: (newFilterValue: FilterContextState) => void;
  setGroupBy: (newGroupBy: GroupByType) => void;
  setDateRange: (newDateRange: DateRangeValue) => void;
  setStringSlicer: (key: StringSlicerType, value: StringSlicer) => void;
  removeStringSlicer: (key: StringSlicerType) => void;
  setNumericSlicer: (
    key: NumericSlicerType,
    value: NumericSlicer | NumericSlicerPreset
  ) => void;
  removeNumericSlicer: (key: NumericSlicerType) => void;
  diveInto: (from: GroupByType, to: GroupByType, value: string) => void;
  diveIntoTickets: (value: string) => void;
}

type FilterContextValue = FilterContextState & FilterContextActions;

const FilterContext = createContext<FilterContextValue>(null!);

export const useFilter = () => {
  const context = useContext(FilterContext);
  if (!context) {
    throw new Error("useFilter must be used within a FilterProvider");
  }
  return context;
};

interface FilterProviderProps {
  defaultValue?: {
    groupBy?: GroupByType;
    dateRange?: DateRangeValue;
  };
}

export function buildFilterSearchParams(state: FilterValue) {
  const {
    groupBy,
    dateRange,
    stringSliceBy,
    numericSliceBy,
    view,
    mainSliceBy,
  } = state;

  const componsedSlicers = componseSlicers({
    stringSliceBy,
    numericSliceBy,
    mainSliceBy,
  });

  const newSearchParams = new URLSearchParams([
    ...(groupBy != null ? [["by", groupBy]] : []),
    ...(dateRange != null ? [["t", dateRange]] : []),
    ...(view != null ? [["view", view]] : []),
    ...componsedSlicers,
  ]);

  return newSearchParams;
}

function initializeState(
  searchParams: URLSearchParams,
  defaultValue?: FilterProviderProps["defaultValue"]
): FilterContextState {
  const groupBy = searchParams.get("by");
  const dateRange = searchParams.get("t");
  const { stringSliceBy, numericSliceBy } = parseSlicers(searchParams);
  const view = searchParams.get("view");

  const state: FilterContextState = {
    ...(groupBy != null && isGroupByType(groupBy)
      ? { groupBy }
      : defaultValue?.groupBy != null
      ? { groupBy: defaultValue.groupBy }
      : {}),
    ...(dateRange != null && isDateRangeValue(dateRange)
      ? { dateRange }
      : { dateRange: defaultValue?.dateRange ?? "14d" }),
    ...(stringSliceBy != null ? { stringSliceBy } : {}),
    ...(numericSliceBy != null ? { numericSliceBy } : {}),
    ...(view != null && view === "tickets" ? { view } : {}),
  };

  return enhanceFilterValue(state);
}

export function FilterProvider({
  children,
  defaultValue,
}: PropsWithChildren<FilterProviderProps>) {
  const [searchParams, setSearchParams] = useSearchParams();

  const [state, setState] = useState<FilterContextState>(() =>
    initializeState(searchParams, defaultValue)
  );

  const actions = useMemo<FilterContextActions>(() => {
    return {
      resetFilter: (newFilterValue: FilterContextState) => {
        setState(newFilterValue);
      },
      setGroupBy: (newGroupBy: GroupByType) => {
        setState((prevState) => ({ ...prevState, groupBy: newGroupBy }));
      },
      setDateRange: (newDateRange: DateRangeValue) => {
        setState((prevState) => ({ ...prevState, dateRange: newDateRange }));
      },
      setStringSlicer: (key: StringSlicerType, value: StringSlicer) => {
        setState((prevState) => {
          const newStringSlicers = (
            prevState.stringSliceBy?.and.filter(
              (slicer) => slicer.type !== key
            ) ?? []
          ).concat(value);

          return {
            ...prevState,
            stringSliceBy: { and: newStringSlicers },
          };
        });
      },
      removeStringSlicer: (key: StringSlicerType) => {
        setState(({ stringSliceBy, ...restPrevState }) => {
          const newStringSlicers =
            stringSliceBy?.and.filter((slicer) => slicer.type !== key) ?? [];

          return {
            ...restPrevState,
            ...(newStringSlicers.length > 0
              ? { stringSliceBy: { and: newStringSlicers } }
              : {}),
          };
        });
      },
      setNumericSlicer: (
        key: NumericSlicerType,
        value: NumericSlicer | NumericSlicerPreset
      ) => {
        setState((prevState) => {
          const newNumericSlicers = (
            prevState.numericSliceBy?.and.filter((slicer) => {
              if (isNumericSlicerPreset(slicer)) {
                return slicer.split("_")[0] !== key;
              }
              return slicer.type !== key;
            }) ?? []
          ).concat(value);

          return {
            ...prevState,
            numericSliceBy: { and: newNumericSlicers },
          };
        });
      },
      removeNumericSlicer: (key: NumericSlicerType) => {
        setState(({ numericSliceBy, ...restPrevState }) => {
          const newNumericSlicers =
            numericSliceBy?.and.filter((slicer) => {
              if (isNumericSlicerPreset(slicer)) {
                return slicer.split("_")[0] !== key;
              }
              return slicer.type !== key;
            }) ?? [];

          return {
            ...restPrevState,
            ...(newNumericSlicers.length > 0
              ? { numericSliceBy: { and: newNumericSlicers } }
              : {}),
          };
        });
      },
      diveInto: (from: GroupByType, to: GroupByType, value: string) => {
        setState((prevState) => {
          if (from == null || groupByToSliceType[from] == null) {
            return prevState;
          }

          return {
            ...prevState,
            groupBy: to,
            ...(prevState.mainSliceBy != null
              ? {
                  stringSliceBy: {
                    and: [
                      {
                        type: prevState.mainSliceBy.type,
                        operation: "and",
                        values: [prevState.mainSliceBy],
                      },
                      ...(prevState.stringSliceBy?.and ?? []),
                    ],
                  },
                }
              : {}),
            mainSliceBy: {
              type: groupByToSliceType[from],
              operation: "eq",
              value,
            },
          };
        });
      },
      diveIntoTickets: (value: string) => {
        setState((prevState) => {
          const { groupBy } = prevState;

          if (groupBy == null || groupByToSliceType[groupBy] == null) {
            return prevState;
          }

          const type = groupByToSliceType[groupBy];

          return {
            ...prevState,
            view: "tickets",
            ...(prevState.mainSliceBy != null
              ? {
                  stringSliceBy: {
                    and: [
                      {
                        type: prevState.mainSliceBy.type,
                        operation: "and",
                        values: [prevState.mainSliceBy],
                      },
                      ...(prevState.stringSliceBy?.and ?? []),
                    ],
                  },
                }
              : {}),
            mainSliceBy: {
              type,
              operation: "eq",
              value,
            },
          };
        });
      },
    };
  }, []);

  useEffect(() => {
    setSearchParams(buildFilterSearchParams(state));
  }, [JSON.stringify(state)]);

  useEffect(() => {
    setState(initializeState(searchParams, defaultValue));
  }, [searchParams]);

  return (
    <FilterContext.Provider value={{ ...state, ...actions }}>
      {children}
    </FilterContext.Provider>
  );
}
