export const EmptyState = () => (
  <svg
    width="170"
    height="101"
    viewBox="0 0 170 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M99.5412 34.9167C89.0649 34.9791 78.426 34.4915 68.4747 31.7287C58.5234 28.9658 49.7223 23.7526 41.7214 17.6644C36.4833 13.7015 31.7201 10.5511 24.9192 11.026C18.2695 11.3755 11.9091 13.8564 6.77932 18.1018C-1.87168 25.6778 -0.559162 39.6794 2.89129 49.5307C8.09184 64.3701 23.9189 74.6715 37.3582 81.3848C52.8977 89.1482 69.9623 93.6614 87.077 96.2491C102.079 98.5243 121.344 100.187 134.333 90.3858C146.272 81.3972 149.547 60.8446 146.622 46.9679C145.909 42.8693 143.728 39.1711 140.484 36.5665C132.095 30.4408 119.594 34.5289 110.168 34.7413C106.73 34.8167 103.116 34.9042 99.5412 34.9167Z"
      fill="#F2F2F2"
    />
    <path
      d="M42.8592 1H109.492C111.15 1 112.74 1.65857 113.913 2.83084C115.084 4.0031 115.743 5.59295 115.743 7.25086V86.5234C115.743 88.181 115.084 89.7714 113.913 90.9439C112.74 92.1152 111.15 92.774 109.492 92.774H34.3207C32.6628 92.774 31.0729 92.1152 29.9007 90.9439C28.7284 89.7711 28.0698 88.181 28.0698 86.5234V15.9394L42.8592 1Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M115.443 39.6299H46.7349V93.5367H115.443V39.6299Z"
      fill="#D2D2D2"
    />
    <path
      d="M28.0698 15.9394H40.184C40.8947 15.9362 41.575 15.6516 42.0765 15.1479C42.578 14.644 42.8595 13.9625 42.8595 13.2517V1.00024L28.0698 15.9394Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M115.106 22.415H47.1475C44.5032 22.415 42.3594 24.5588 42.3594 27.2031V41.7425C42.3594 44.3871 44.5032 46.5306 47.1475 46.5306H115.106C117.75 46.5306 119.894 44.3871 119.894 41.7425V27.2031C119.894 24.5588 117.75 22.415 115.106 22.415Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.5614 38.6796C57.8673 38.6796 59.737 36.8102 59.737 34.504C59.737 32.1977 57.8676 30.3284 55.5614 30.3284C53.2551 30.3284 51.3857 32.1977 51.3857 34.504C51.3857 36.8102 53.2551 38.6796 55.5614 38.6796Z"
      fill="#D2D2D2"
    />
    <path
      d="M68.6878 38.6796C70.9938 38.6796 72.8634 36.8102 72.8634 34.504C72.8634 32.1977 70.9941 30.3284 68.6878 30.3284C66.3816 30.3284 64.5122 32.1977 64.5122 34.504C64.5122 36.8102 66.3816 38.6796 68.6878 38.6796Z"
      fill="#D2D2D2"
    />
    <path
      d="M81.8016 38.6796C84.1076 38.6796 85.9772 36.8102 85.9772 34.504C85.9772 32.1977 84.1078 30.3284 81.8016 30.3284C79.4954 30.3284 77.626 32.1977 77.626 34.504C77.626 36.8102 79.4956 38.6796 81.8016 38.6796Z"
      fill="#D2D2D2"
    />
    <path
      d="M115.106 49.1436H47.1475C44.5032 49.1436 42.3594 51.2871 42.3594 53.9316V68.471C42.3594 71.1153 44.5032 73.2591 47.1475 73.2591H115.106C117.75 73.2591 119.894 71.1153 119.894 68.471V53.9316C119.894 51.2871 117.75 49.1436 115.106 49.1436Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.5614 65.4206C57.8673 65.4206 59.737 63.5512 59.737 61.245C59.737 58.939 57.8676 57.0693 55.5614 57.0693C53.2551 57.0693 51.3857 58.9387 51.3857 61.245C51.3857 63.5509 53.2551 65.4206 55.5614 65.4206Z"
      fill="#D2D2D2"
    />
    <path
      d="M68.6878 65.4206C70.9938 65.4206 72.8634 63.5512 72.8634 61.245C72.8634 58.939 70.9941 57.0693 68.6878 57.0693C66.3816 57.0693 64.5122 58.9387 64.5122 61.245C64.5122 63.5509 66.3816 65.4206 68.6878 65.4206Z"
      fill="#D2D2D2"
    />
    <path
      d="M81.8016 65.4206C84.1076 65.4206 85.9772 63.5512 85.9772 61.245C85.9772 58.939 84.1078 57.0693 81.8016 57.0693C79.4954 57.0693 77.626 58.9387 77.626 61.245C77.6262 63.5509 79.4956 65.4206 81.8016 65.4206Z"
      fill="#D2D2D2"
    />
    <path
      d="M115.106 75.8845H47.1475C44.5032 75.8845 42.3594 78.0281 42.3594 80.6726V95.212C42.3594 97.8561 44.5032 100 47.1475 100H115.106C117.75 100 119.894 97.8561 119.894 95.212V80.6726C119.894 78.0281 117.75 75.8845 115.106 75.8845Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M55.5614 92.1488C57.8673 92.1488 59.737 90.2799 59.737 87.9732C59.737 85.6672 57.8676 83.7976 55.5614 83.7976C53.2551 83.7976 51.3857 85.667 51.3857 87.9732C51.3857 90.2799 53.2551 92.1488 55.5614 92.1488Z"
      fill="#D2D2D2"
    />
    <path
      d="M68.6878 92.1488C70.9938 92.1488 72.8634 90.2799 72.8634 87.9732C72.8634 85.6672 70.9941 83.7976 68.6878 83.7976C66.3816 83.7976 64.5122 85.667 64.5122 87.9732C64.5122 90.2799 66.3816 92.1488 68.6878 92.1488Z"
      fill="#D2D2D2"
    />
    <path
      d="M81.8016 92.1488C84.1076 92.1488 85.9772 90.2799 85.9772 87.9732C85.9772 85.6672 84.1078 83.7976 81.8016 83.7976C79.4954 83.7976 77.626 85.667 77.626 87.9732C77.6262 90.2799 79.4956 92.1488 81.8016 92.1488Z"
      fill="#D2D2D2"
    />
    <path
      d="M121.256 52.869C135.57 52.869 147.172 41.2661 147.172 26.9533C147.172 12.6405 135.57 1.0376 121.256 1.0376C106.943 1.0376 95.3408 12.6405 95.3408 26.9533C95.3408 41.2661 106.943 52.869 121.256 52.869Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M139.659 45.7556L146.56 52.6565"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M145.788 49.3062L143.647 51.5473C142.683 52.5554 142.718 54.1541 143.727 55.1178L162.343 72.9135C163.35 73.8772 164.949 73.8411 165.912 72.833L168.055 70.5919C169.019 69.5837 168.982 67.9851 167.975 67.0214L149.359 49.2257C148.351 48.262 146.752 48.2981 145.788 49.3062Z"
      fill="white"
      stroke="#BABABA"
      stroke-width="1.25015"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
