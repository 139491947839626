import { useMemo, PropsWithChildren } from "react";
import cx from "classnames";
import { Button, DropdownMenu, Spinner, IconButton } from "@radix-ui/themes";
import {
  isNumericSlicerPreset,
  GroupByType,
  numericSlicerToLabel,
  NumericSlicerType,
  SlicerType,
} from "./context/FilterContext.model";
import { useFilter } from "./context";
import {
  Component2Icon,
  ChevronDownIcon,
  Cross1Icon,
  PlusIcon,
} from "@radix-ui/react-icons";
import { SliceStringDropDown } from "./slicers/stringSlice";
import styles from "./styles.module.scss";
import { SentimentDropDown } from "./slicers/sentiment";
import { QAScoreDropDown } from "./slicers/qaScore";
import { CategoriesDropDown } from "./slicers/categories";
import { TopicsDropDown } from "./slicers/topics";
import { AgentsDropDown } from "./slicers/agents";

type ExploreValuesType = Record<string, string[]>;

type ActiveSlicerType = {
  type: SlicerType;
  value: string | number;
  onClick: () => void;
};

interface SlicedByDropdownProps {
  groupBy: GroupByType;
  exploreValues?: ExploreValuesType;
}

function SlicedByDropdown({
  children,
  groupBy,
  exploreValues,
}: PropsWithChildren<SlicedByDropdownProps>) {
  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>{children}</DropdownMenu.Trigger>

      <DropdownMenu.Content variant="solid" color="gray" highContrast size="1">
        <SentimentDropDown />
        <QAScoreDropDown />
        {/* <OutliersDropDown /> */}
        <DropdownMenu.Separator />
        {groupBy !== "categories" && (
          <CategoriesDropDown options={exploreValues?.categories ?? []} />
        )}
        {groupBy !== "topics" && (
          <TopicsDropDown options={exploreValues?.topics ?? []} />
        )}

        <AgentsDropDown options={exploreValues?.agents ?? []} />
        {/* <CustomersDropDown /> */}
        <DropdownMenu.Separator />
        {/* <TicketVolumeDropDown /> */}
        {/* <TTRDropDown /> */}

        <SliceStringDropDown
          title={"Channels"}
          sliceKey="channel"
          k="channel"
          options={exploreValues?.channels ?? []}
        />
        {/* <ResponseTimeDropDown /> */}
        {/* <BackAndForthDropDown /> */}

        <SliceStringDropDown
          title={"Statuses"}
          sliceKey="status"
          k="status"
          options={exploreValues?.statuses ?? []}
        />
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

interface SliceByMenuButtonContentProps {
  mainSlicer?: Omit<ActiveSlicerType, "onClick">;
  activeSlicers: ActiveSlicerType[];
  isLoading: boolean;
  ticketsCount: number;
  finiteState: boolean;
}

function SliceByMenuButtonContent({
  mainSlicer,
  activeSlicers,
  isLoading,
  ticketsCount,
  finiteState,
}: SliceByMenuButtonContentProps) {
  const combinedSlicers = useMemo(() => {
    if (!mainSlicer) {
      return activeSlicers;
    }

    return [mainSlicer, ...activeSlicers];
  }, [mainSlicer, activeSlicers]);

  return (
    <>
      <Component2Icon />

      {combinedSlicers.length > 0 ? (
        <>
          <span>{combinedSlicers[0].value}</span>

          <span className={styles.moreSlicers}>
            {combinedSlicers.length > 1
              ? ` +${combinedSlicers.length - 1}`
              : ""}
          </span>
        </>
      ) : (
        <span>{finiteState ? "Evaluated Tickets" : "All Tickets"}</span>
      )}

      <span className={styles.ticketsCount}>
        <Spinner loading={isLoading}>{ticketsCount}</Spinner>

        {ticketsCount === 1 ? " Ticket" : " Tickets"}
      </span>

      <ChevronDownIcon />
    </>
  );
}

interface SlicedByProps {
  isLoading: boolean;
  ticketsCount?: number;
  groupBy?: GroupByType;
  exploreValues?: ExploreValuesType;
  finiteState: boolean;
}

function SlicedBy({
  isLoading,
  ticketsCount = 0,
  groupBy = "topics",
  exploreValues,
  finiteState,
}: SlicedByProps) {
  const {
    mainSliceBy,
    numericSliceBy,
    stringSliceBy,
    setNumericSlicer,
    setStringSlicer,
    removeNumericSlicer,
    removeStringSlicer,
  } = useFilter();

  const activeSlicers = useMemo<ActiveSlicerType[]>(() => {
    const numericSlicers = numericSliceBy?.and
      .map((slicer) => {
        if (isNumericSlicerPreset(slicer)) {
          const presetParts = slicer.split("_");
          const type = presetParts[0] as NumericSlicerType;
          const value = numericSlicerToLabel[type]?.[presetParts[1]];

          return [
            {
              type,
              value: value ?? presetParts[1],
              onClick: () => removeNumericSlicer(type),
            },
          ];
        }

        return slicer.values.map((value) => ({
          type: value.type,
          value: value.value,
          onClick: () => {
            const newValues = slicer.values.filter(
              (item) =>
                item.value !== value.value && item.operation === value.operation
            );

            if (newValues.length > 0) {
              setNumericSlicer(slicer.type, {
                ...slicer,
                values: newValues,
              });
            } else {
              removeNumericSlicer(slicer.type);
            }
          },
        }));
      })
      .flat();

    const stringSlicers = stringSliceBy?.and
      .map((slicer) => {
        return slicer.values.map((value) => ({
          type: value.type,
          value: value.value,
          onClick: () => {
            const newValues = slicer.values.filter(
              (item) =>
                item.value !== value.value && item.operation === value.operation
            );

            if (newValues.length > 0) {
              setStringSlicer(slicer.type, {
                ...slicer,
                values: newValues,
              });
            } else {
              removeStringSlicer(slicer.type);
            }
          },
        }));
      })
      .flat();

    return [...(numericSlicers ?? []), ...(stringSlicers ?? [])];
  }, [
    numericSliceBy,
    stringSliceBy,
    setStringSlicer,
    setNumericSlicer,
    removeNumericSlicer,
    removeStringSlicer,
  ]);

  const mainSlicer = useMemo<
    Omit<ActiveSlicerType, "onClick"> | undefined
  >(() => {
    if (mainSliceBy == null) {
      return;
    }

    return {
      type: mainSliceBy.type,
      value: mainSliceBy.value,
    };
  }, [mainSliceBy]);

  if (activeSlicers.length > 0) {
    return (
      <DropdownMenu.Root>
        <DropdownMenu.Trigger>
          <Button
            color="gray"
            variant="soft"
            highContrast
            size="1"
            className={cx(styles.btnWithBorder, styles.sliceBtn)}
          >
            <SliceByMenuButtonContent
              mainSlicer={mainSlicer}
              activeSlicers={activeSlicers}
              isLoading={isLoading}
              ticketsCount={ticketsCount}
              finiteState={finiteState}
            />
          </Button>
        </DropdownMenu.Trigger>

        <DropdownMenu.Content
          variant="solid"
          color="gray"
          highContrast
          size="1"
        >
          <div
            style={{
              display: "flex",
              gap: "8px",
              flexDirection: "column",
              padding: "4px",
            }}
          >
            {activeSlicers.map((slicer) => {
              return (
                <Button
                  variant="outline"
                  className={styles.buttonGroup}
                  onClick={(e) => e.preventDefault()}
                >
                  {`${slicer.type} = ${slicer.value}`}

                  <IconButton size="1" variant="ghost" onClick={slicer.onClick}>
                    <Cross1Icon />
                  </IconButton>
                </Button>
              );
            })}
            <SlicedByDropdown groupBy={groupBy} exploreValues={exploreValues}>
              <Button
                color="gray"
                variant="ghost"
                highContrast
                size="1"
                className={styles.smallBtn}
              >
                <PlusIcon />
                Add
              </Button>
            </SlicedByDropdown>
          </div>
        </DropdownMenu.Content>
      </DropdownMenu.Root>
    );
  }

  return (
    <>
      <SlicedByDropdown groupBy={groupBy} exploreValues={exploreValues}>
        <Button
          color="gray"
          variant="soft"
          highContrast
          size="1"
          className={cx(styles.btnWithBorder, styles.sliceBtn)}
        >
          <SliceByMenuButtonContent
            mainSlicer={mainSlicer}
            activeSlicers={activeSlicers}
            isLoading={isLoading}
            ticketsCount={ticketsCount}
            finiteState={finiteState}
          />
        </Button>
      </SlicedByDropdown>

      <div style={{ width: 5, height: 5 }} />
    </>
  );
}

export default SlicedBy;
