import React, { useEffect, useMemo, useState } from "react";
import { useFeatureFlag } from "configcat-react";
import { useReleases } from "../simulator/context/release";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import { Release, SimulatorChannel } from "../simulator/types";
import { Button, Card, Heading, IconButton, Tooltip } from "@radix-ui/themes";
import styles from "./styles.module.scss";
import { Dot, Input } from "@geist-ui/core";
import { Send, Slack } from "@geist-ui/icons";
import { BookIcon, SendIcon, SparkleIcon } from "lucide-react";
import {
  CrossCircledIcon,
  DotsVerticalIcon,
  LightningBoltIcon,
  Pencil1Icon,
  TrashIcon,
} from "@radix-ui/react-icons";
import { useAuth0 } from "@auth0/auth0-react";
import { DropdownMenu } from "../../components/shared/dropdown-menu";
import { useDeleteSimulator } from "../../api/useRelease";
import { Dialog } from "../../components/shared/dialog";

const iconsMapper = {
  helpcenter: <BookIcon />,
  autonomous_ticket_event: <SparkleIcon />,
  autonomous_quackchat: <LightningBoltIcon />,
  slackbot: (
    <span>
      <Slack />
    </span>
  ),
  copilot: <SendIcon />,
};
const channelMapper = {
  helpcenter: {
    label: "Help center chatbot",
    internal: false,
    description: "AI search experience on top of your Help center",
  },
  autonomous_ticket_event: {
    label: "",
    internal: false,
    description: "Answer your tickets in Email & Form channels",
  },
  autonomous_quackchat: {
    label: "",
    internal: false,
    description: "Answer your Chat conversations",
  },
  slackbot: {
    label: "",
    internal: true,
    description: "Provide Internal Support in your Slack Channels",
  },
  copilot: {
    label: "",
    internal: true,
    description:
      "Automate tasks and provide intelligent suggestions to enhance productivity.",
  },
};
export const Simulators = () => {
  const { simulators, handleCreateSimulator } = useReleases();

  const searchParams = new URLSearchParams(window.location.search);
  const fParam = searchParams.get("f");

  const isInternal = fParam === "copilot";

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Heading> {isInternal ? "Co-pilot " : "Auto-pilot "} Instances</Heading>
        <Button
          color="gray"
          variant="solid"
          onClick={handleCreateSimulator}
          highContrast
        >
          Create Agent
        </Button>
      </div>
      <Card
        variant="classic"
        style={{ gap: "16px", display: "flex", flexDirection: "column" }}
      >
        {simulators
          ?.filter((r) => {
            return channelMapper[r.channel].internal === isInternal;
          })
          .map((r) => (
            <SimulatorItem r={r} key={r.id} />
          ))}
      </Card>
    </div>
  );
};

const SimulatorItem = ({
  r,
}: {
  r: {
    id: number;
    name: string;
    releases: Release[];
    channel: SimulatorChannel;
    publishedReleaseId?: number;
  };
}) => {
  const [isEdit, setEdit] = useState(false);
  const [value, setValue] = useState(r.name);
  const [requestToDeleteId, setRequestToDeleteId] = useState<number>();

  const { handleUpdateSimulator, setChosenSimulatorId } = useReleases();

  const { user } = useAuth0();
  const { value: coPilotSimulatorValue } = useFeatureFlag(
    "coPilotSimulator",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  console.log(coPilotSimulatorValue);

  const navigate = useNavigate();
  const lastUpdateAt = r.releases?.reduce(
    (latest: Date, release: { updatedAt: Date }) => {
      const releaseDate = new Date(release.updatedAt);
      return releaseDate > latest ? releaseDate : latest;
    },
    new Date(0)
  );

  const draftRelease = r.releases.find(
    (release) => release.status === "draft" && !release.publishedAt
  );
  const publishedRelease = r.releases.find(
    (release) => release.id === r.publishedReleaseId
  );

  const calculateUpdatedInstructions = (
    draft?: Release,
    published?: Release
  ) => {
    // if (!draft || !published) return 0;
    if (!draft) return 0;

    console.log(draft);

    const draftInstructions =
      typeof draft.releasePolicy === "string"
        ? JSON.parse(draft.releasePolicy)
        : draft.releasePolicy;
    if (!published) return draftInstructions.length - 4;
    const publishedInstructions =
      typeof published.releasePolicy == "string"
        ? JSON.parse(published.releasePolicy)
        : published.releasePolicy;
    const updatedInstructions = draftInstructions.filter(
      (draftInst: any) =>
        !publishedInstructions.some(
          (pubInst: any) =>
            pubInst.content === draftInst.content &&
            pubInst.subTopic === draftInst.subTopic &&
            pubInst.topic === draftInst.topic &&
            pubInst.type === draftInst.type
        )
    );
    const deletedInstructions = publishedInstructions.filter(
      (draftInst: any) =>
        !draftInstructions.some(
          (pubInst: any) =>
            pubInst.content === draftInst.content &&
            pubInst.subTopic === draftInst.subTopic &&
            pubInst.topic === draftInst.topic &&
            pubInst.type === draftInst.type
        )
    );
    return updatedInstructions.length + deletedInstructions.length;
  };

  const updatedInstructionsCount = calculateUpdatedInstructions(
    draftRelease,
    publishedRelease
  );

  return (
    <>
      <div
        className={styles.simulator}
        onClick={() => {
          if (!isEdit) {
            const path =
              !coPilotSimulatorValue || r.channel !== "copilot"
                ? `/resolution/${r.id}`
                : `/resolution-copilot/${r.id}`;
            navigate(path);
          }
        }}
      >
        <div>
          <span className={styles.editableTitle}>
            <Tooltip content={channelMapper[r.channel].description}>
              {iconsMapper[r.channel]}
            </Tooltip>

            {isEdit ? (
              <Input
                value={value}
                onChange={(e) => setValue(e.target.value)}
                crossOrigin={undefined}
                autoFocus
                onBlur={(e) => {
                  handleUpdateSimulator(r.id, value);
                  e.stopPropagation();
                  setTimeout(() => {
                    setEdit(false);
                  }, 300);
                }}
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
                className={styles.input}
              />
            ) : (
              <Heading as="h6" size="3">
                {r.name}
              </Heading>
            )}
          </span>

          <Heading as="h6" size="1" weight="light">
            Last release {dayjs(lastUpdateAt).format("MMM DD,YYYY")}
          </Heading>
        </div>

        <label>
          <Send />
          {r.releases.length - 1} Releases
        </label>

        <label>
          <Dot type={updatedInstructionsCount === 0 ? "default" : "warning"} />
          {updatedInstructionsCount} Updates{" "}
        </label>

        <label
          style={{ flexGrow: 1 }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DropdownMenu
            items={[
              {
                label: "Rename",
                onClick: () => {
                  console.log("Rename");

                  setEdit(true);
                  setChosenSimulatorId(r.id);
                },
                icon: <Pencil1Icon />,
              },
              {
                label: "Delete",
                onClick: () => {
                  console.log("Delete");

                  setRequestToDeleteId(r.id);
                },
                icon: <TrashIcon />,
              },
            ]}
          >
            <DropdownMenu.Trigger>
              <IconButton
                color={"gray"}
                variant={"ghost"}
                highContrast
                size={"1"}
              >
                <DotsVerticalIcon />
              </IconButton>
            </DropdownMenu.Trigger>
          </DropdownMenu>
        </label>
      </div>

      {requestToDeleteId != null && (
        <DeleteSimulatorDialog
          simulatorId={requestToDeleteId}
          onClose={() => setRequestToDeleteId(undefined)}
        />
      )}
    </>
  );
};

function isError(data: any) {
  return data.error != null && data.statusCode != null && data.message != null;
}

interface DeleteSimulatorDialogProps {
  simulatorId: number;
  onClose: () => void;
}

function DeleteSimulatorDialog({
  simulatorId,
  onClose,
}: DeleteSimulatorDialogProps) {
  const { refetchSimulators } = useReleases();
  const { mutate, isLoading, data } = useDeleteSimulator({
    simulatorId,
  });

  const erroMessage = useMemo<string>(() => {
    if (data != null && isError(data)) {
      return data.message;
    }
  }, [data]);

  useEffect(() => {
    if (data != null && !isError(data)) {
      refetchSimulators().then(() => {
        onClose();
      });
    }
  }, [data]);

  return (
    <Dialog
      open
      onOpenChange={(open) => {
        if (!open) {
          onClose();
        }
      }}
    >
      <Dialog.Content size={"small"}>
        <Dialog.Title>
          Delete Simulator
          <Dialog.Close />
        </Dialog.Title>

        <div className={styles.DeleteSimulatorDialog}>
          <div className={styles.Content}>
            Are you sure you want to delete this simulator?
          </div>

          {erroMessage != null && (
            <div className={styles.ErroMessage}>
              <CrossCircledIcon />
              {erroMessage}
            </div>
          )}

          <div className={styles.Footer}>
            <Button
              color={"red"}
              variant={"solid"}
              size={"1"}
              loading={isLoading}
              disabled={erroMessage != null}
              onClick={() => {
                mutate({});
              }}
            >
              Delete
            </Button>

            <Button
              color={"gray"}
              variant={"ghost"}
              size={"1"}
              disabled={isLoading}
              onClick={onClose}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog.Content>
    </Dialog>
  );
}
