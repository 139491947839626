import {
  ExploreRow,
  TicketExploreRow,
} from "../../api/useExplore/Explore.model";
import { ColumnType } from "./ExploreTable";

export const exploreTicketsLevelTable: ColumnType<keyof TicketExploreRow>[] = [
  {
    label: "Tickets",
    key: "overview",
    width: "25%",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
  {
    label: "Status",
    key: "status",
  },
  {
    label: "Topic",
    key: "topic",
  },
  {
    label: "Agent",
    key: "agent",
  },
  {
    label: "Category",
    key: "category",
  },
  {
    label: "Team",
    key: "team",
  },
  {
    label: "TTR",
    key: "ttr",
  },
  // {
  //   label: "B&F",
  //   key: "avgBF",
  // },
  {
    label: "Channel",
    key: "channel",
  },
  // {
  //   label: "QA Score",
  //   key: "qaScore",
  // },
  // {
  //   label: "Negative Sentiment",
  //   key: "sentiment",
  // },
  {
    label: "KB Coverage",
    key: "kbCoverage",
  },
  {
    label: "CSAT",
    key: "ticketCsat",
  },
  // {
  //   label: "Created",
  //   key: "createdAt",
  // },
  // {
  //   label: "Last Update",
  //   key: "updatedAt",
  // },
];

export const exploreLevel1Table: ColumnType<keyof ExploreRow>[] = [
  {
    label: "Topics",
    key: "name",
    width: "45%",
  },
  {
    label: "Volume",
    key: "avgVolume",
  },
  {
    label: "Trend Pattern",
    key: "trendVolume",
  },
  {
    label: "Resolution",
    key: "resolutionRate",
  },
  {
    label: "Avg. TTR",
    key: "avgTimeToResolve",
  },
  {
    label: "Avg. B&F",
    key: "avgBackAndForth",
  },
  {
    label: "Avg. QA Score",
    key: "avgQaScore",
  },
];
