import { useAuthorizedMutation } from "..";
import { useAuthorizedQuery } from "..";
import { params } from "../../params";
import { Member } from "./members.model";

export const useGetMembers = (enabled: boolean = true) => {
  return useAuthorizedQuery<Member[]>({
    queryKey: "users",
    url: `${params.API_URL}/gateway/organization/tenant/users`,

    method: "GET",
    enabled,
  });
};

export const useCreateMembers = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/gateway/organization/users`,
    method: "POST",
  });
};

export const useUpdateMember = (memberId: number) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/gateway/organization/users/${memberId}`,
    method: "PUT",
  });
};

export const useDeleteMember = (memberId: number) => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/gateway/organization/users/${memberId}`,
    method: "DELETE",
  });
};
