import React, { useEffect, useMemo, useState } from "react";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";
import { Card, Select, Text } from "@geist-ui/core";

import { useSimilarTickets } from "../../../api/useSimilarTicket";
import { useGetTopics } from "../../../api/useGetTopics";
import styles from "./styles.module.scss";
import { ArrowLeft, ArrowRight, Loader } from "@geist-ui/icons";
import { cleanTickets } from "./lib";
import { useReleases } from "../context/release";
import { VerifiedIcon } from "lucide-react";
import SharedSelect, { SelectOption } from "../../../components/shared/select";

export const Suggestions = ({
  handleMessage,
  selectedValue,
  setSelectedValue,
}: {
  handleMessage: (value: string) => void;
  selectedValue: string;
  setSelectedValue: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const { user } = useAuth0();
  const [currentIndex, setCurrentIndex] = useState(0);

  const { releaseConversations } = useReleases();

  const { data: topicList, isLoading } = useGetTopics(!!user);

  const selectedTopicId = +selectedValue;
  const { data, isLoading: similarTicketsLoading } = useSimilarTickets(
    !!user && !Number.isNaN(selectedTopicId),
    selectedTopicId
  );

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? data.length - 3 : prevIndex - 3
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 3 >= data.length ? 0 : prevIndex + 3
    );
  };

  useEffect(() => {
    if (!selectedValue) {
      setSelectedValue((prev) => {
        return String(
          topicList?.filter(({ topic }: { topic: string }) => {
            return topic.toLowerCase() !== "other";
          })?.[0]?.id
        );
      });
    }
  }, [topicList]);

  const topicOptions = useMemo<SelectOption[] | undefined>(() => {
    return topicList
      ?.filter(({ topic }: { topic: string }) => {
        return topic.toLowerCase() !== "other";
      })
      .map((topic: { topic: string; id: number }) => ({
        label: topic.topic,
        value: String(topic.id),
      }));
  }, [topicList]);

  return (
    <div className={styles.suggestionsContainer}>
      {isLoading ? (
        <Loader />
      ) : (
        <SharedSelect
          options={topicList
            ?.filter(({ topic }: { topic: string }) => {
              return topic.toLowerCase() !== "other";
            })
            .map((topic: { topic: string; id: number }) => ({
              label: topic.topic,
              value: String(topic.id),
            }))}
          value={selectedValue}
          onChange={(newValue) => {
            setSelectedValue(newValue);
          }}
        />
      )}

      {data?.length !== 0 && !similarTicketsLoading ? (
        <>
          <div className={styles.title}>
            <Text small my={0}>
              Some good tickets to start with
            </Text>
          </div>
          <div className={cx(styles.cardContainer)}>
            {data
              ?.filter((t: any) => t?.id)
              .slice(currentIndex, currentIndex + 3)
              .map(
                (ticket: {
                  id: string;
                  description: string;
                  similarTicketsCount: number;
                }) => {
                  const cleanedDescription = cleanTickets(
                    ticket.description ?? ""
                  );

                  const isExist = releaseConversations.some(
                    (conversation) =>
                      conversation.releaseComments.sort(
                        (a, b) => a.id - b.id
                      )[0]?.comment === cleanedDescription
                  );
                  return (
                    <Card
                      key={ticket.id}
                      className={styles.card}
                      hoverable
                      onClick={() => handleMessage(cleanedDescription)}
                    >
                      <div>
                        <Text small>#{ticket.id}</Text>

                        {isExist ? (
                          <span className={styles.verified}>
                            <VerifiedIcon />
                            Already reviewed
                          </span>
                        ) : (
                          <></>
                        )}
                        <Text h6 my={0}>
                          {cleanedDescription}
                        </Text>
                      </div>
                      <div>
                        <Text small>
                          similar tickets - {ticket.similarTicketsCount}
                        </Text>
                      </div>
                    </Card>
                  );
                }
              )}
          </div>

          {data?.length > 3 && (
            <div className={styles.btnsContainer}>
              <button onClick={handlePrev} className={styles.btn}>
                <ArrowLeft />
              </button>
              <button onClick={handleNext} className={styles.btn}>
                <ArrowRight />
              </button>
            </div>
          )}
        </>
      ) : (
        <div className={styles.title}>
          <Text small my={0}>
            Oops, we didn't find any similar ticket, but feel free to ask
            anything 👇
          </Text>
        </div>
      )}
      {similarTicketsLoading && (
        <div className={cx(styles.cardContainer)}>
          <>
            <SuggestionsSkeleton />
          </>
        </div>
      )}
    </div>
  );
};

const SuggestionsSkeleton = () => {
  return (
    <>
      <div className={styles.skeletonCard}></div>
      <div className={styles.skeletonCard}></div>
      <div className={styles.skeletonCard}></div>
    </>
  );
};
