export enum Permission {
  KNOWLEDGE = "knowledge",
  SIMULATOR_INTERNAL = "simulator_internal",
  SIMULATOR_EXTERNAL = "simulator_external",
  EXPLORE = "explore",
  QUALITY = "quality",
  COPILOT = "copilot",
}

export const PermissionDisplay = {
  [Permission.EXPLORE]: "Explore",
  [Permission.SIMULATOR_EXTERNAL]: "Automation: Auto-pilot",
  [Permission.KNOWLEDGE]: "Knowledge Management",
  [Permission.SIMULATOR_INTERNAL]: "Automation: Co-pilot",
  [Permission.QUALITY]: "Quality Assurance",
  [Permission.COPILOT]: "Co-pilot",
};

export enum Role {
  ADMIN = "Admin",
  AGENT = "Agent",
  MEMBER = "Member",
}

export interface Member {
  id: number;
  email: string;
  name?: string;
  isAdmin: boolean;
  organizationId: string;
  permissions: Permission[];
  tenants: {
    id: string;
  }[];
}

export type CreateMembers = {
  emails: string[];
  permissions: Permission[];
  tenantsIds: string[];
  isAdmin?: boolean;
};

export type UpdateMember = {
  permissions: Permission[];
  tenantsIds: string[];
  isAdmin?: boolean;
};

export function getMemberRole(member?: Member): Role {
  if (member?.isAdmin) {
    return Role.ADMIN;
  } else if (
    member?.permissions?.length === 1 &&
    member?.permissions?.[0] === Permission.COPILOT
  ) {
    return Role.AGENT;
  }
  return Role.MEMBER;
}
