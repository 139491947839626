import { useAuthorizedQuery } from "..";
import { params } from "../../params";
import { Instruction } from "../../routes/simulator-copilot/types";

export const useInsights = (ticketId: string) => {
  return useAuthorizedQuery({
    url: `${params.API_URL}/platform/extension/tickets/${ticketId}/insights`,
    queryKey: `getTicketsInsightsAndSuggResposne-${ticketId}`,
    enabled: !!ticketId,
  });
};

export const createConversationInsight = async ({
  instructions,
  topic,
  client_id,
  description,
  metadata,
}: {
  instructions: Instruction[];
  topic: string;
  client_id: string;
  description: string;
  metadata: any;
}) => {
  const response = await fetch(
    `${params.AI_URL}/api/insights-enricher/simulator`,
    {
      method: "POST",
      body: JSON.stringify({
        topic,
        instructions,
        client_id,
        description,
        metadata,
      }),
      headers: {
        "x-quack-token": params.AI_TOKEN,
        "Content-Type": "application/json",
      },
    }
  );
  try {
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};
