import { useCallback, useContext, useMemo, useState } from "react";
import cx from "classnames";
import { Table, Tooltip, Skeleton, Button, Spinner } from "@radix-ui/themes";
import { ThemeContext } from "../../components/Theme";
import { ArrowDownIcon, ArrowUpIcon } from "@radix-ui/react-icons";
import { GroupByType } from "./context/FilterContext.model";
import { TooltipGenerator } from "./tooltips";
import styles from "./styles.module.scss";

export type ColumnType<KeyType = string> = {
  key: KeyType;
  label: string;
  width?: `${string}%`;
  hidden?: boolean;
};

export function TableSum({
  label,
  count,
  totalCount = count,
  isLoading = false,
}: {
  label: string;
  count?: number;
  totalCount?: number;
  isLoading?: boolean;
}) {
  return (
    <div className={styles.TableSum}>
      <span className={styles.Label}>{label}</span>

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {count != null && (
            <span className={styles.Count}>{`${count} of ${totalCount}`}</span>
          )}
        </>
      )}
    </div>
  );
}

function TableLoader<RowDataType = { [key: string]: unknown }>({
  columns,
}: {
  columns: ColumnType<keyof RowDataType>[];
}) {
  return (
    <>
      {[...Array(5)].map(() => (
        <Table.Row>
          {columns
            .filter(({ hidden }) => hidden !== true)
            .map(() => {
              const random = Math.round(Math.random() * 20) + 10;

              return (
                <Table.Cell>
                  <Skeleton
                    loading
                    width={`calc(100% - ${random}%)`}
                    maxWidth={`calc(240px - ${random}px)`}
                    height={"20px"}
                  />
                </Table.Cell>
              );
            })}
        </Table.Row>
      ))}
    </>
  );
}

interface SortState<RowDataType> {
  key: keyof RowDataType;
  direction: "asc" | "desc";
}

interface ExploreTableProps<RowDataType = { [key: string]: unknown }> {
  columns: ColumnType<keyof RowDataType>[];
  cellGenerator: (
    values: RowDataType,
    { key }: ColumnType<keyof RowDataType>,
    groupBy?: GroupByType
  ) => JSX.Element;
  cellValueGetter?: (
    values: RowDataType,
    key: keyof RowDataType
  ) => number | string | undefined;
  rows?: RowDataType[];
  isLoading?: boolean;
  defaultSortState?: SortState<RowDataType>;
  groupBy?: GroupByType;
  hasNextPage?: boolean;
  fetchNextPage?: () => void;
  isFetchingNextPage?: boolean;
  onRowClick?: (row: RowDataType) => void;
}

function ExploreTable<RowDataType>({
  columns,
  cellGenerator,
  cellValueGetter,
  rows = [],
  isLoading = false,
  defaultSortState,
  groupBy,
  hasNextPage,
  fetchNextPage,
  isFetchingNextPage,
  onRowClick,
}: ExploreTableProps<RowDataType>) {
  const { isDarkMode } = useContext(ThemeContext);

  const [sortState, setSortState] = useState<
    SortState<RowDataType> | undefined
  >(defaultSortState);

  const handleSortBy = useCallback((key: keyof RowDataType) => {
    setSortState((prevState) => {
      if (prevState?.key !== key) {
        return { key, direction: "asc" };
      }

      if (prevState.direction === "desc") {
        return undefined;
      }

      return { key, direction: "desc" };
    });
  }, []);

  const visibleColumns = useMemo(
    () => columns.filter((column) => column.hidden !== true),
    [columns]
  );

  const sortedRows = useMemo(() => {
    if (sortState == null) {
      return rows;
    }

    const { key, direction } = sortState;

    return [...rows].sort((a, b) => {
      const colValueA =
        cellValueGetter != null ? cellValueGetter(a, key) : a[key];
      const colValueB =
        cellValueGetter != null ? cellValueGetter(b, key) : b[key];

      if (colValueA == null) return 1;
      if (colValueB == null) return -1;
      if (colValueA == colValueB) return 0;
      if (direction === "desc") return colValueA > colValueB ? 1 : -1;
      return colValueA < colValueB ? 1 : -1;
    });
  }, [rows, sortState]);

  return (
    <Table.Root className={styles.table}>
      <Table.Header className={styles.tableHeader}>
        <Table.Row className={styles.theader}>
          {visibleColumns.map((value, i) => {
            const isSortedByThis = sortState?.key === value.key;

            if (i === 0) {
              return (
                <Table.ColumnHeaderCell
                  width={value.width}
                  className={cx(styles.thcell, {
                    [styles.sortedBy]: isSortedByThis,
                  })}
                  onClick={() => handleSortBy(value.key)}
                >
                  <div>
                    {value.label}
                    {isSortedByThis && sortState.direction === "desc" ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    )}
                  </div>
                </Table.ColumnHeaderCell>
              );
            }
            return (
              <Tooltip
                className={cx(styles.tooltip, {
                  [styles.tooltip_light]: !isDarkMode,
                  [styles.tooltip_dark]: isDarkMode,
                })}
                content={<TooltipGenerator<RowDataType> type={value.key} />}
              >
                <Table.ColumnHeaderCell
                  className={cx(styles.thcell, {
                    [styles.sortedBy]: isSortedByThis,
                  })}
                  onClick={() => handleSortBy(value.key)}
                >
                  <div>
                    {value.label}
                    {isSortedByThis && sortState?.direction === "desc" ? (
                      <ArrowUpIcon />
                    ) : (
                      <ArrowDownIcon />
                    )}
                  </div>
                </Table.ColumnHeaderCell>
              </Tooltip>
            );
          })}
        </Table.Row>
      </Table.Header>

      <Table.Body className={styles.tbody}>
        {isLoading && <TableLoader columns={columns} />}

        {sortedRows?.map((values) => {
          return (
            <Table.Row
              className={styles.hoverable}
              onClick={() => onRowClick?.(values)}
            >
              {visibleColumns.map((column) => {
                return (
                  <Table.Cell>
                    <Skeleton loading={isLoading}>
                      {isLoading ? (
                        "Lorem"
                      ) : (
                        <>{cellGenerator(values, column, groupBy)}</>
                      )}
                    </Skeleton>
                  </Table.Cell>
                );
              })}
            </Table.Row>
          );
        })}

        {hasNextPage && (
          <Table.Row align={"center"}>
            <Table.Cell colSpan={9} align="center">
              <Button
                loading={isFetchingNextPage}
                disabled={isFetchingNextPage}
                color="gray"
                variant="outline"
                onClick={fetchNextPage}
              >
                Load more
              </Button>
            </Table.Cell>
          </Table.Row>
        )}
      </Table.Body>
    </Table.Root>
  );
}

export default ExploreTable;
