import dayjs from "dayjs";
import { Button, DropdownMenu, Separator } from "@radix-ui/themes";
import { CalendarIcon, ChevronDownIcon } from "@radix-ui/react-icons";
import { useFilter } from "./context";
import { buildRange, DateRangeValue, dateRanges } from "./context/FilterContext.model";
import styles from "./styles.module.scss";

const dateRangeToLabel: Record<DateRangeValue, string> = {
  "7d": "Last 7 days",
  "14d": "Last 14 days",
  "1m": "Last 1 month",
  "2m": "Last 2 months",
};

function DateRangePicker() {
  const { dateRange, setDateRange } = useFilter();
  const { dateFrom, dateTo } = buildRange[dateRange]();

  return (
    <DropdownMenu.Root>
      <DropdownMenu.Trigger>
        <Button
          color="gray"
          variant="soft"
          size="1"
          className={styles.btnWithBorder}
          highContrast
        >
          {dateRangeToLabel[dateRange]}

          <ChevronDownIcon />

          <Separator orientation="vertical" className={styles.seperator} />

          <CalendarIcon />

          {`${dayjs(dateFrom).format("MMM DD")}-${dayjs(dateTo).format("MMM DD")}`}
        </Button>
      </DropdownMenu.Trigger>

      <DropdownMenu.Content variant="soft" color="gray">
        {dateRanges.map((item) => (
          <DropdownMenu.Item shortcut="" onClick={() => setDateRange(item)}>
            {dateRangeToLabel[item]}  
          </DropdownMenu.Item>  
        ))}
      </DropdownMenu.Content>
    </DropdownMenu.Root>
  );
}

export default DateRangePicker;