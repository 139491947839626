import { useCallback, useMemo, useState } from "react";
import dayjs from "dayjs";
import ExploreTable, { ColumnType } from "../../explore/ExploreTable";
import { SelectOption } from "../../../components/shared/select";
import { Navbar, NavbarItem } from "../../explore/ExploreNavbar";
import exploreStyles from "../../explore/styles.module.scss";
import { Sample, useValidationSets } from "../../../api/useValidations";
import ValidationSet from "./ValidationSet";
import AddNewValidationSet, {
  ADD_NEW_VALIDATION_SET,
} from "./AddNewValidationSet";

function buildSampleOptions(samples: Sample[]): SelectOption[] {
  return samples
    .sort((a, b) => {
      if (a.dateFrom !== b.dateFrom) {
        return dayjs(b.dateFrom).diff(dayjs(a.dateFrom));
      }
      return dayjs(b.dateTo).diff(dayjs(a.dateTo));
    })
    .map(({ id, dateFrom, dateTo }) => {
      const label = `${dayjs(dateFrom).format("MMM DD")}-${dayjs(dateTo).format(
        "MMM DD"
      )}`;
      return { label, value: String(id) };
    });
}

interface ValidationSetRow {
  name: string;
  sampled: number;
  samples: SelectOption[];
}

const validationSetColumns: ColumnType<keyof ValidationSetRow>[] = [
  {
    key: "name",
    label: "Validation sets",
  },
  {
    key: "sampled",
    label: "Sampled",
  },
  {
    key: "samples",
    label: "",
    hidden: true,
  },
];

function cellGenerator(
  values: ValidationSetRow,
  { key }: ColumnType<keyof ValidationSetRow>
) {
  if (values["name"] === ADD_NEW_VALIDATION_SET) {
    return key === "name" ? <AddNewValidationSet /> : <></>;
  }

  const cellValue = values?.[key];
  return <>{cellValue != null ? cellValue : "-"}</>;
}

function Validations() {
  const [selectedValidationSet, setSelectedValidationSet] = useState<
    ValidationSetRow | undefined
  >();

  const { data, isLoading } = useValidationSets();

  const navbarItems = useMemo<NavbarItem[]>(
    () => [{ title: "quality" }, { title: "validations" }],
    []
  );

  const rows = useMemo<ValidationSetRow[] | undefined>(() => {
    if (isLoading || data == null) {
      return;
    }
    const samples = buildSampleOptions(data);

    return [
      {
        name: "Random",
        sampled: samples.length,
        samples,
      },
      {
        name: ADD_NEW_VALIDATION_SET,
        sampled: 0,
        samples: [],
      },
    ];
  }, [isLoading, data]);

  const resetSelectedValidationSet = useCallback(() => {
    setSelectedValidationSet(undefined);
  }, []);

  const handleRowClick = useCallback((row: ValidationSetRow) => {
    if (row.name === ADD_NEW_VALIDATION_SET) {
      return;
    }

    setSelectedValidationSet(row);
  }, []);

  if (selectedValidationSet != null) {
    return (
      <ValidationSet
        name={selectedValidationSet.name}
        samplesOptions={selectedValidationSet.samples}
        backToValidationSets={resetSelectedValidationSet}
      />
    );
  }

  return (
    <div className={exploreStyles.exploreContainer}>
      <div className={exploreStyles.controlRow}>
        <Navbar items={navbarItems} />
      </div>

      <div className={exploreStyles.controlRow} />

      <div className={exploreStyles.TableSum}>
        <span className={exploreStyles.Label}>Validation sets</span>
      </div>

      <ExploreTable<ValidationSetRow>
        columns={validationSetColumns}
        rows={rows}
        isLoading={isLoading}
        cellGenerator={cellGenerator}
        onRowClick={handleRowClick}
      />
    </div>
  );
}

export default Validations;
