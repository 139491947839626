import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
//@ts-ignore
import { Message, useChat } from "ai/react";
import { Text } from "@geist-ui/core";
import { Tabs, Badge, Kbd, Button, HoverCard } from "@radix-ui/themes";
import { ArrowUp, CornerDownRight, Plus, X } from "@geist-ui/icons";
import { IoSparkles } from "react-icons/io5";
import { useAuth0 } from "@auth0/auth0-react";
import dayjs from "dayjs";

import styles from "./styles.module.scss";
import { useGetTopics } from "../../../api/useGetTopics";
import { params } from "../../../params";
import { ReleaseConversationFeedback } from "../types";
import { useReleases } from "../../simulator/context/release";
import { InputType, SimulatorInput } from "../../simulator/input";
import { useInstructions } from "../../simulator/context/instructions";
import { CoPilotSimulatorSuggestions } from "../suggestions";
import { CoPilotMessages } from "../messages";
import {
  createConversationInsight,
  useInsights,
} from "../../../api/coPilotSimulator";
import { useGetTicket } from "../../../api/useGetTicket";

const useDelayedEffect = (callback: () => void, delay: number, deps: any[]) => {
  useEffect(() => {
    const handler = setTimeout(() => callback(), delay);

    return () => clearTimeout(handler);
  }, [...deps, delay]);
};

const handleBadFeedback = async ({
  badFeedback,
  tenant,
  instructions,
  topic,
  subTopic,
  messages,
  isTraining,
  setLoading,
}: {
  messages: Message[];
} & any): Promise<string> => {
  setLoading(true);
  const response = await fetch(
    `${params.AI_URL}/api/reply?stream=false&playground=${isTraining}&simulator=true`,
    {
      method: "POST",
      body: JSON.stringify({
        tenant,
        instructions: [
          ...instructions,
          {
            topic,
            subTopic,
            type: "manual",
            content: `
          The last response was:
          ${messages[messages.length - 1].content}

          this response isn't good enough
          fix by following feedback:
          ${badFeedback}

        `,
          },
        ],
        topic,
        subTopic,
        messages: messages.slice(0, -1),
      }),
      headers: {
        "x-quack-token": params.AI_TOKEN,
      },
    }
  );
  const res = await response.json();

  setLoading(false);

  return res.text;
};

export const CopilotSimulatorChat = ({
  topic,
  setTopic,
  isTraining,
  setTraining,
  ticketId,
  setTicketId,
  setComments,
}: {
  topic: string | null;
  setTopic: Dispatch<SetStateAction<string | null>>;
  isTraining: boolean;
  setTraining: Dispatch<SetStateAction<boolean>>;
  ticketId: string | null;
  setTicketId: Dispatch<SetStateAction<string | null>>;
  setComments: Dispatch<
    React.SetStateAction<
      | {
          rawBody: string;
          agent: boolean;
          createdAt: string;
        }[]
      | null
    >
  >;
}) => {
  const [id, setId] = useState(0);
  const [isOptionsOpen, setOptionsOpen] = useState(false);
  const isInstructionChanged = useRef<boolean>(false);
  const [isRedrafting, setRedrafting] = useState(false);
  const [selectedValue, setSelectedValue] = useState("");

  const { user } = useAuth0();

  const conversationIdRef = useRef<number>(null);
  const topicIdRef = useRef<number>(null);

  const { handleUpdateConversation, conversationId, releaseId } = useReleases();

  useEffect(() => {
    (conversationIdRef as React.MutableRefObject<number | null>).current =
      conversationId;
  }, [conversationId]);

  // should create conversation
  // should create new release

  const [subTopic] = useState(null);
  const [options, setOptions] = useState<string[]>([]);

  const [textareaValue, setTextareaValue] = useState("");
  const { data: topics } = useGetTopics(!!user);
  const [isLoading, setLoading] = useState(false);
  const [type, setType] = useState<InputType>("none");

  const { data: insights, isLoading: insightsLoading } = useInsights(
    ticketId ?? ""
  );

  const { data: ticketData } = useGetTicket(ticketId ?? "", !!ticketId);

  const {
    instructions,
    handleInstruction,
    handleInstructionsOptions,
    simulatorInstructions,
  } = useInstructions();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  // use tickets filter by status slice by topic

  useDelayedEffect(
    () => {
      if (
        textareaValue &&
        isInstructionChanged.current &&
        type !== "feedback"
      ) {
        handleInstructionsOptions(textareaValue).then((res) => {
          setOptions(res);
          setOptionsOpen(true);
          isInstructionChanged.current = false;
        });
      }
      // You can add any additional logic here that should run after the delay
    },
    900,
    [textareaValue]
  );

  const handleTopicAssignment = async (question: string) => {
    const response = await fetch(`${params.AI_URL}/api/topics`, {
      method: "POST",
      body: JSON.stringify({
        question: question,
        topics: topics
          ?.map((topic: { topic: string }) => topic.topic)
          .filter((topic: string) => topic.toLowerCase() !== "other"),
      }),
      headers: {
        "x-quack-token": params.AI_TOKEN,
      },
    });
    const data = await response.json();

    const topicId = topics?.filter(
      (topic: { topic: string; id: number }) =>
        topic.topic.replace(/['"`*]/g, "").trim() ===
        data.text.replace(/['"`*]/g, "").trim()
    )?.[0]?.id;

    setTopic(data.text.replace(/['"`*]/g, "").trim());

    (topicIdRef as React.MutableRefObject<number | null>).current = topicId;

    handleUpdateConversation({
      message: { role: "user", content: question },
      feedback: ReleaseConversationFeedback.NONE,
      topicId: topicId ?? 0,
      localConversationId: conversationIdRef.current ?? 0,
    });

    return data.text.replace(/['"`*]/g, "").trim();
  };

  const handleFormSubmission = async (e: React.FormEvent<HTMLFormElement>) => {
    switch (type) {
      case "none":
        e.preventDefault();
        if (messages.length === 0) {
          await handleTopicAssignment(textareaRef?.current?.value ?? "");
        } else {
          // should update
          handleUpdateConversation({
            message: {
              role: "user",
              content: textareaRef?.current?.value ?? "",
            },
            feedback: ReleaseConversationFeedback.NONE,
            topicId: topicIdRef.current ?? 0,
            localConversationId: conversationId ?? 0,
          });
        }

        setLoading(true);
        handleSubmit(e);
        break;
      case "instruction":
        const inputValue = textareaRef?.current?.value ?? "";
        setLoading(true);
        handleInstruction({
          topic,
          subTopic,
          content: inputValue,
          type: "manual",
        });
        setMessages([]);
        e.preventDefault();
        const response = await createConversationInsight({
          instructions: [
            ...instructions,
            {
              topic,
              subTopic,
              content: inputValue,
              type: "manual",
            },
          ],
          topic: topic ?? "",
          client_id: user?.owner ?? "",
          description: ticketData?.ticket?.description ?? "",
          metadata: ticketData?.ticket?.metadata ?? {},
        });

        setMessages([
          {
            content: response.conversationalInsight,
            role: "assistant",
            id: "new-conv-insight",
          },
        ]);

        setTimeout(() => {
          setTextareaValue("");
          setType("none");
          if (textareaRef?.current) {
            textareaRef.current.style.height = "24px";
          }
        }, 300);

        break;
      case "feedback":
        e.preventDefault();

        handleResetConversation(
          ReleaseConversationFeedback.NEGATIVE,
          textareaRef?.current?.value ?? ""
        );

        setTimeout(() => {
          setTextareaValue("");
          setType("none");
          if (textareaRef?.current) {
            textareaRef.current.style.height = "24px";
          }
        }, 300);

        break;
    }
  };

  const handleResponse = async (response: Message) => {
    setLoading(false);
    const firstTool = response.toolInvocations?.[0];

    handleUpdateConversation({
      localConversationId: conversationIdRef.current ?? 0,
      message: {
        role: "assistant",
        content:
          response.content ||
          (firstTool?.state === "result" ? firstTool.result : "__"),
      },
      feedback: ReleaseConversationFeedback.NONE,
      topicId: topicIdRef.current ?? 0,
    });
  };

  const {
    messages,
    input,
    handleInputChange,
    handleSubmit,
    append,
    reload,
    setMessages,
  } = useChat({
    id: String(id),
    api: `${params.AI_URL}/api/copilot-reply?stream=true&playground=${isTraining}&simulator=true`,
    headers: {
      "x-quack-token": params.AI_TOKEN,
    },
    body: {
      tenant: user?.owner ?? "",
      instructions: isTraining ? instructions : simulatorInstructions,
      topic,
      subTopic,
    },
    onFinish: handleResponse,
  });

  const handleResetConversation = (
    feedback?:
      | ReleaseConversationFeedback.NEGATIVE
      | ReleaseConversationFeedback.POSITIVE,
    feedbackComment?: string
  ) => {
    // here should fire a create conversation
    if (feedback === ReleaseConversationFeedback.NEGATIVE && feedbackComment) {
      // we block the reset conversation
      handleBadFeedback({
        tenant: user?.owner ?? "",
        instructions: isTraining ? instructions : simulatorInstructions,
        topic,
        subTopic,
        messages,
        isTraining,
        badFeedback: feedbackComment ?? "",
        setLoading: setRedrafting,
      }).then((res) => {
        handleUpdateConversation({
          localConversationId: conversationIdRef.current ?? 0,
          message: {
            role: "assistant",
            content: res,
          },
          topicId: topicIdRef.current ?? 0,
          feedback: ReleaseConversationFeedback.NONE,
          feedbackComment: "",
        });

        setMessages([
          ...messages.slice(0, -1),
          { id: "1241241", content: res, role: "assistant" },
        ]);
      });
    } else {
      if (feedback === ReleaseConversationFeedback.POSITIVE) {
        fetch(`${params.AI_URL}/api/rag`, {
          method: "PUT",
          body: JSON.stringify({
            messages,
            id: conversationId,
            tenant: user?.owner ?? "",
          }),
          headers: {
            "x-quack-token": params.AI_TOKEN,
          },
        }).then((res) => {
          handleUpdateConversation({
            localConversationId: conversationIdRef.current ?? 0,
            message: {
              role: "user",
              content: "--reset converesation--",
            },
            topicId: topicIdRef.current ?? 0,
            feedback: feedback ?? ReleaseConversationFeedback.NONE,
            feedbackComment: feedbackComment ?? "",
          });
          // should update the conversation
          (topicIdRef as { current: number | null }).current = null;
          (conversationIdRef as { current: number | null }).current = null;
          setTopic(null);
          setId(dayjs().valueOf());
        });
      } else {
        handleUpdateConversation({
          localConversationId: conversationIdRef.current ?? 0,
          message: {
            role: "user",
            content: "--reset converesation--",
          },
          topicId: topicIdRef.current ?? 0,
          feedback: feedback ?? ReleaseConversationFeedback.NONE,
          feedbackComment: feedbackComment ?? "",
        });
        // should update the conversation
        (topicIdRef as { current: number | null }).current = null;
        (conversationIdRef as { current: number | null }).current = null;
        setTopic(null);
        setId(dayjs().valueOf());
      }
    }
  };

  const [inputValue, handleInputValueChange] =
    type === "none"
      ? [input, handleInputChange]
      : [
          textareaValue,
          (
            e:
              | React.ChangeEvent<HTMLInputElement>
              | React.ChangeEvent<HTMLTextAreaElement>
          ) => {
            isInstructionChanged.current = true;
            setTextareaValue(e.target.value);
          },
        ];

  const handleSelectTicketId = async ({
    ticketId,
    topic,
    comments,
  }: {
    ticketId: string;
    topic: string;
    comments: any;
  }) => {
    setLoading(true);
    setTopic(topic);
    setTicketId(ticketId);
    setComments(comments);
  };

  useEffect(() => {
    if (!!insights && !insightsLoading) {
      setLoading(false);
      const ticketInsights = insights?.data?.insights || [];
      const sortedInsightsItems = ticketInsights
        ? [...ticketInsights].sort((a, b) => a.id - b.id)
        : [];

      const lastInsight = sortedInsightsItems[sortedInsightsItems.length - 1];
      setMessages(
        lastInsight
          ? [
              {
                content: lastInsight.conversationalInsight,
                role: "assistant",
                id: lastInsight.id,
              },
            ]
          : []
      );
    }
  }, [insights, insightsLoading, setMessages]);

  useEffect(() => {
    handleResetConversation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTraining]);

  const stringify = JSON.stringify(instructions);

  useEffect(() => {
    reload();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stringify]);

  return (
    <div className={styles.container}>
      <div className={styles.topContainer}>
        {messages.length > 0 && (
          <div className={styles.resetButton}>
            <Button
              onClick={() => handleResetConversation()}
              size={"2"}
              color="gray"
              highContrast
              variant="outline"
            >
              <Plus />
              New Session
            </Button>
          </div>
        )}
        <Tabs.Root
          value={isTraining ? "training" : "simulate"}
          onValueChange={(val) => {
            setMessages([]);
            setTicketId(null);
            setTraining(val === "training");
          }}
        >
          <Tabs.List>
            <Tabs.Trigger value="training">Training</Tabs.Trigger>
            <Tabs.Trigger value="simulate">Simulate</Tabs.Trigger>
          </Tabs.List>
        </Tabs.Root>
        {messages.length > 0 && (
          <div
            className={styles.resetButton}
            style={{ right: "24px", left: "unset" }}
          >
            <Badge size={"2"} color="gray" highContrast variant="outline">
              Ticket #{ticketId?.split("::")[1]}
            </Badge>
          </div>
        )}
      </div>
      <div className={styles.messagesContainer}>
        {topic && (
          <div className={styles.badgesContainer}>
            <Badge className={styles.primary}>{topic}</Badge>
            {subTopic && <Badge variant="outline">{subTopic}</Badge>}
          </div>
        )}
        {messages.length === 0 && !isLoading ? (
          <CoPilotSimulatorSuggestions
            handleSelectTicketId={handleSelectTicketId}
            {...{ selectedValue, setSelectedValue }}
          />
        ) : (
          <CoPilotMessages messages={messages} isLoading={isLoading} />
        )}
      </div>

      <form onSubmit={handleFormSubmission}>
        <div className={styles.inputContainer}>
          <div className={styles.inputFieldContainer}>
            {isOptionsOpen && options.length > 0 && (
              <div className={styles.optionsContainer}>
                <div className={styles.optionsHeader}>
                  <div className={styles.iconContainer}>
                    <IoSparkles />
                  </div>
                  <label>
                    Suggested optimized instructions that might perform better
                  </label>
                  <div
                    className={styles.close}
                    onClick={() => setOptionsOpen(false)}
                  >
                    <X />
                  </div>
                </div>

                <div className={styles.optionsBody}>
                  {options.map((o, index) => {
                    return (
                      <div
                        className={styles.option}
                        key={o}
                        onClick={() => {
                          setOptionsOpen(false);
                          setTextareaValue(o);
                        }}
                      >
                        {o}

                        <CornerDownRight />
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            <div className={styles.input}>
              <div className={styles.iconContainer}>
                <IoSparkles />
              </div>

              <SimulatorInput
                ref={textareaRef}
                type={type}
                setType={setType}
                value={inputValue}
                handleInputChange={handleInputValueChange}
                messagesLength={messages.length}
                handleSubmit={() => buttonRef.current?.click()}
                handleResetConversation={handleResetConversation}
              />
            </div>
            <div className={styles.submitContainer}>
              <button type="submit" ref={buttonRef}>
                <ArrowUp />
              </button>
            </div>
          </div>
          <div className={styles.bottomBar}>
            <Button
              type={"button"} // important - otherwise form will submit on click
              color={"gray"}
              variant={"ghost"}
              highContrast
              size={"1"}
              className={styles.shortcut}
              onClick={() => setType("instruction")}
            >
              <Kbd size={"3"}>/</Kbd>
              <Text small>to add instructions</Text>
            </Button>

            <Button
              type={"button"}
              color={"gray"}
              variant={"ghost"}
              highContrast
              size={"1"}
              className={styles.shortcut}
              onClick={() => handleResetConversation()}
            >
              <Kbd size={"3"}>I</Kbd>
              <Text small>new session</Text>
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
