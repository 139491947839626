import React from "react";
import styles from "./styles.module.scss";
import { Button } from "@radix-ui/themes";
import { EmptyState } from "../../routes/reports/emptyState";
export const EmptyStateComponent = ({
  title,
  description,
  cta = "Talk to our CEO",
}: {
  title: string;
  description: string;
  cta?: string;
}) => {
  return (
    <div className={styles.emtpyStateContainer}>
      <EmptyState />
      <h3>{title}</h3>
      <p className="text-gray-600 text-lg">{description}</p>
      <div>
        <Button
          color="gray"
          variant="solid"
          highContrast
          size="1"
          onClick={() =>
            window.open("https://calendly.com/nadav-a6u/quick-chat", "_blank")
          }
        >
          {cta}
        </Button>
        {/* <Button color="gray" variant="outline" highContrast size="1">
            Learn more
          </Button> */}
      </div>
    </div>
  );
};
