import React, {
  useEffect,
  useMemo,
  useState,
  useCallback,
  useContext,
} from "react";
import { Route, Routes, useLocation, Navigate } from "react-router-dom";
import cx from "classnames";
import { useAuth0 } from "@auth0/auth0-react";

import Lottie from "react-lottie";

import animationDataDark from "./lottie/dark_mode_loader.json";
import animationDataLight from "./lottie/light_mode_loader.json";

import "./App.css";
import Login from "./routes/login";
import AccountSetup from "./routes/accountSetup";
import { Sidebar } from "./components/sidebar";
import { InstallSuccess } from "./routes/successfullInstall";
import { Supervision } from "./routes/supervision";
import { Report } from "./routes/report";
import { Assessment } from "./routes/assessment";
import { AssessmentByTopicId } from "./routes/assessmentByTopicId";
import { AssessmentByTicketId } from "./routes/assessmentByTicketId";
import { Topbar, TopbarContext } from "./components/topbar";
import styles from "./styles.module.scss";
import { Activation } from "./routes/activation";
import { ActivationTopic } from "./routes/activationTopic";
import { KnowledgeManagement } from "./routes/knowledgeManagement";
import { KnowledgeByTopic } from "./routes/knowledgeByTopic";
import { ArticleDraft } from "./routes/articleDraft";
import { Analysis } from "./routes/analysis";
import { Performance } from "./routes/performance";
import { mixRegisterSuperProps, mixSetUserProps } from "./assets/mixpanel";
import { createToast } from "vercel-toast";
import { VoiceOfCustomer } from "./routes/vocOfCustomer";
import { IntegrationsInvite } from "./routes/integrations-invite";
import { Simulator } from "./routes/simulator";
import { SimulatorCoPilot } from "./routes/simulator-copilot";
import { Reports } from "./routes/reports";
import { ArticleWizard } from "./routes/articleWizard";
import { Customers } from "./routes/customers";
import { Customer } from "./routes/customer";
import { Simulators } from "./routes/simulators";
import { SimulatorWrapper } from "./routes/simulators/wrapper";
import ArticlePage from "./routes/articlePage";
import { ThemeContext } from "./components/Theme";
import { Explore } from "./routes/explore";
import { SharedReport, sharedReportRouteUri } from "./routes/sharedReport";
import { Button } from "@radix-ui/themes";
import { Evaluations, Validations } from "./routes/quality";
import { FeatureAccessContext } from "./context/featureAccess";
import { useGetMembers } from "./api/members";
import { getMemberRole, Role } from "./api/members/members.model";
import { ChatbotSandbox } from "./routes/chatbot-sandbox";
import { useGetAccess } from "./api/useAccess";

const onlyAdminsRoutes = new Map([
  ["/supervision", <Supervision />],
  ["/settings", <AccountSetup />],
  ["/home", <Assessment />],
  ["/assessment/:topicId", <AssessmentByTopicId />],
  ["/assessment/:topicId/:ticketId", <AssessmentByTicketId />],
  ["/activation", <Activation />],
  ["/activation/:topicId", <ActivationTopic />],
  ["/knowledge-management", <KnowledgeManagement />],
  ["/knowledge-management/:topicId", <KnowledgeByTopic />],
  ["/knowledge-management/:topicId/wizard/:articleId", <ArticleWizard />],
  ["/knowledge-management/:topicId/article/:articleId", <ArticlePage />],
  ["/knowledge-management/:topicId/:articleId", <ArticleDraft />],
  ["/assessment", <Analysis />],
  ["/performance", <Performance />],
  ["/voice-of-customer", <VoiceOfCustomer />],
  [
    "/resolution/:simulatorId",
    <SimulatorWrapper>
      <Simulator />
    </SimulatorWrapper>,
  ],
  [
    "/resolution-copilot/:simulatorId",
    <SimulatorWrapper>
      <SimulatorCoPilot />
    </SimulatorWrapper>,
  ],
  [
    "/resolution",
    <SimulatorWrapper>
      <Simulators />
    </SimulatorWrapper>,
  ],
  ["/sessions-reports", <Reports />],
  ["/customers", <Customers />],
  ["/customers/:organizationId", <Customer />],
  ["/explore", <Explore />],
  ["/quality", <Navigate to="/quality/evaluations" />],
  ["/quality/evaluations", <Evaluations />],
  ["/quality/validations", <Validations />],
]);

const onlyUsersRoutes = new Map([["/report/:id", <Report />]]);

// for it to work, make sure to update 'unprocted' in the sidebar
const openRoutes = new Map([
  ["/installation-successfull", <InstallSuccess />],
  ["/login", <Login />],
  ["/welcome", <IntegrationsInvite />],
  ["/chatbot-sandbox", <ChatbotSandbox />],
  [`${sharedReportRouteUri}/:reportId`, <SharedReport />],
]);

const renderUnauthorized = (logout: () => void) => (
  <div id="main" className={styles.container}>
    <div className={styles.secondContainer}>
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          paddingTop: "300px",
          gap: "16px",
        }}
      >
        Sorry, you can try a different account{" "}
        <Button
          variant={"solid"}
          color={"gray"}
          highContrast
          onClick={() => logout()}
        >
          logout
        </Button>
      </div>
    </div>
  </div>
);

function App() {
  const [path, setPath] = useState("");
  const { user, logout, isLoading } = useAuth0();
  const location = useLocation();
  const [isAnimationLoading, setAnimationLoading] = useState(true);
  const [isWorkspaceChange, setWorkspaceChange] = useState(false);

  const { selectedWorkspace } = useContext(FeatureAccessContext);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    setTimeout(() => {
      setAnimationLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    if (user) {
      mixSetUserProps(true, {
        name: user?.name,
        email: user?.email,
        id: user?.sub,
        tenant: user?.owner,
        webapp_user: true,
      });
      mixRegisterSuperProps({
        webapp_event: true,
      });
    }
  }, [user]);

  const { data: membersData, isLoading: membersLoading } = useGetAccess(!!user);

  const isAdmin = membersData && getMemberRole(membersData) !== Role.AGENT;

  useEffect(() => {
    const currentPath = location.pathname;
    const isAdminRoute = Array.from(onlyAdminsRoutes.keys()).some(
      (path) =>
        path === currentPath ||
        (path.includes(":") && currentPath.startsWith(path.split(":")[0]))
    );

    if (
      !membersLoading &&
      !isLoading &&
      membersData !== undefined &&
      isAdminRoute &&
      !isAdmin
    ) {
      createToast(`Not Authorized user, Contact your manager`, {
        type: "dark",
        timeout: 3000,
      });
    }
  }, [isAdmin, membersData, location, membersLoading, isLoading]);

  const renderRoute = useCallback(
    (path: string, element: React.ReactNode) => {
      if (openRoutes.has(path)) {
        return <Route key={path} path={path} element={element} />;
      }
      if (onlyAdminsRoutes.has(path)) {
        return (
          <Route
            key={path}
            path={path}
            element={isAdmin ? element : renderUnauthorized(logout)}
          />
        );
      }
      if (onlyUsersRoutes.has(path)) {
        return <Route key={path} path={path} element={element} />;
      }
      return null;
    },
    [isAdmin, logout]
  );

  useEffect(() => {
    setWorkspaceChange(true);
    setTimeout(() => {
      setWorkspaceChange(false);
    }, 3);
  }, [selectedWorkspace]);

  return (
    <div
      id="main"
      className={cx(
        {
          [styles.dark_mode]: isDarkMode,
          [styles.light_mode]: !isDarkMode,
        },
        styles.container
      )}
    >
      {!isLoading && !membersLoading && !isAnimationLoading ? (
        <>
          <Sidebar />
          {isWorkspaceChange ? (
            <></>
          ) : (
            <div className={styles.secondContainer}>
              <Topbar />
              <TopbarContext.Provider value={{ path: path, setPath: setPath }}>
                <Routes>
                  {[
                    ...Array.from(onlyAdminsRoutes),
                    ...Array.from(onlyUsersRoutes),
                    ...Array.from(openRoutes),
                  ].map(([path, element]) => renderRoute(path, element))}
                  <Route
                    path="*"
                    element={<Navigate to="/explore?by=topics&t=14d" />}
                  />
                </Routes>
              </TopbarContext.Provider>
            </div>
          )}
        </>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: isDarkMode
                ? animationDataDark
                : animationDataLight,
              rendererSettings: {
                preserveAspectRatio: "xMidYMid slice",
              },
            }}
            height={400}
            width={400}
          />
        </div>
      )}
    </div>
  );
}

export default App;
