import React, { useContext, useEffect, useState } from "react";

import { GDashboardTable } from "../../components/geistTableDashboard";
import {
  EColumnTypes,
  IColumn,
} from "../../components/geistTableDashboard/index.types";
import { useReports } from "../../api/useReport";
import { EBadgeMode } from "../../components/atoms/geistBadge";

import styles from "./styles.module.scss";
import { badgeOptions, TReportDisplay } from "./index.types";
import { useAuth0 } from "@auth0/auth0-react";
import { EmptyState } from "./emptyState";
import { Badge, Button } from "@radix-ui/themes";
import { FeatureAccessContext } from "../../context/featureAccess";
import { EmptyStateComponent } from "../../components/emptyState";

const columns: IColumn[] = [
  {
    label: "Ticket",
    value: "ticketId",
    type: EColumnTypes.DEFAULT,
  },
  {
    label: "Report Creation Date",
    value: "createdAt",
    type: EColumnTypes.DATE_TIME,
  },
  {
    label: "Recording Starting Time",
    value: "startTime",
    type: EColumnTypes.DATE_TIME,
  },
  {
    label: "Agent Assigned",
    value: "agentAssigned",
    type: EColumnTypes.DEFAULT,
  },
  {
    label: "Status",
    value: "status",
    type: EColumnTypes.BADGE,
  },
  {
    label: "Errors",
    value: "errors",
    type: EColumnTypes.BADGE,
    badgeOptions: badgeOptions,
  },
  {
    label: "Browser",
    value: "browserName",
    type: EColumnTypes.BADGE,
  },
  {
    label: "Operating System",
    value: "os",
    type: EColumnTypes.BADGE,
    badgeOptions: {
      MacOS: EBadgeMode.PURPLE,
      Windows: EBadgeMode.BLUE,
    },
  },
];

export const Reports = () => {
  const [page, setPage] = useState(0);
  const [allData, setAllData] = useState<any[]>([]);
  const { user } = useAuth0();
  const { data, isLoading } = useReports(page);
  const { features } = useContext(FeatureAccessContext);
  const reports = data as {
    reports: TReportDisplay[];
    nextPage: number | null;
  };

  useEffect(() => {
    if (reports && reports?.reports && reports.reports.length > 0) {
      const _reports = reports.reports.map((item: TReportDisplay) => ({
        ...item,
        id: item.ticketId,
        ticketId: "#" + item.ticketId.split("::")[1],
        errors: item.networkEvents
          ? item.networkEvents.filter(
              (event) => event.value.response.status >= 300
            ).length
          : "-",
        browserName: item?.session?.browserInfo?.browserName,
        os: item?.session?.browserInfo?.os,
        agentAssigned: item?.agentAssigned?.email,
        status: item?.status,
      }));
      setAllData((prevData) => [...prevData, ..._reports]);
    }
  }, [reports]);

  if (!features.sessions) {
    return (
      <EmptyStateComponent
        title={"Session Reports"}
        description="Stop asking for screenshots and gain full visibility!
        Bring your customers session to the forefront of solving tickets."
      />
    );
  }

  return (
    <div className={styles.reportsTableContainer}>
      <GDashboardTable
        dataArray={allData}
        selectedTopic={0}
        handleRowClick={(rowId) => (window.location.href = `/report/${rowId}`)}
        columns={columns}
        onNextPage={() => {
          setPage(page + 1);
        }}
        nextPage={reports && reports.nextPage !== null}
        loadMore={isLoading}
        disableSelected
        loading={reports === undefined && allData.length === 0 && isLoading}
        firstColumnWidth="15%"
      />
    </div>
  );
};
