import { useMemo, useCallback, useState } from "react";
import { useFilter } from "../context";
import {
  Cross1Icon,
  MagnifyingGlassIcon,
  PaperPlaneIcon,
} from "@radix-ui/react-icons";
import { Button, IconButton, TextField } from "@radix-ui/themes";

export const Keyword = () => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();

  const value = useMemo(() => {
    const slicer = stringSliceBy?.and.find(({ type }) => type === "keyword");

    return slicer?.values?.[0]?.value;
  }, [stringSliceBy]);

  const [active, setActive] = useState(false);
  const [searchPhrase, setSearchPhrase] = useState<string>(value ?? "");

  const handleClear = useCallback(() => {
    if (value != null) {
      removeStringSlicer("keyword");
      setSearchPhrase("");
      setActive(false);
    }
  }, [removeStringSlicer, value]);

  const handleSlice = useCallback(() => {
    if (searchPhrase.length > 0) {
      setStringSlicer("keyword", {
        type: "keyword",
        operation: "or",
        values: [
          {
            type: "keyword",
            operation: "eq",
            value: searchPhrase,
          },
        ],
      });
    } else {
      handleClear();
    }
  }, [searchPhrase, handleClear, setStringSlicer]);

  return (
    <>
      {active ? (
        <TextField.Root
          placeholder={`Slice by keyword`}
          size="1"
          autoFocus
          variant="soft"
          color="gray"
          value={searchPhrase}
          onChange={(e) => setSearchPhrase(e.target.value)}
        >
          <TextField.Slot>
            <MagnifyingGlassIcon height="16" width="16" />
          </TextField.Slot>
          <TextField.Slot>
            <IconButton
              size="1"
              variant="ghost"
              disabled={!searchPhrase}
              onClick={() => handleSlice()}
            >
              <PaperPlaneIcon />
            </IconButton>
          </TextField.Slot>
          <TextField.Slot>
            <IconButton size="1" variant="ghost" onClick={() => handleClear()}>
              <Cross1Icon />
            </IconButton>
          </TextField.Slot>
        </TextField.Root>
      ) : (
        <Button
          color="gray"
          variant="ghost"
          highContrast
          size="1"
          onClick={() => setActive(true)}
        >
          <MagnifyingGlassIcon /> Slice by keyword
        </Button>
      )}
    </>
  );
};
