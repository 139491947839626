export const reasonsForNegativeScore = [
  "poor_active_listening",
  "repetitive_questions",
  "inconsistent_information",
  "language_barriers",
  "missed_to_ask_clarifying_question",
  "rushed_responses",
  "lack_of_empathy",
  "failure_to_acknowledge_mistake",
  "long_wait_times",
  "lack_of_confidence",
  "inability_to_address_urgency",
  "impersonal_interactions",
  "unresponsive",
  "not_getting_to_the_point",
  "lack_of_customer_education",
  "inadequate_follow_up",
  "inaccurate_promises",
  "missed_to_set_proper_expectations",
  "invalid_escalations",
  "no_thorough_investigation",
  "lack_of_product_knowledge_expertise",
  "faild_to_confirm_resolution",
  "unresolved_issues",
] as const;

export type ReasonForNegativeScore = (typeof reasonsForNegativeScore)[number];

export const questionToReasons: Record<string, ReasonForNegativeScore[]> = {
  "UNDERSTAND THE INQUIRY": [
    "poor_active_listening",
    "repetitive_questions",
    "inconsistent_information",
    "language_barriers",
    "missed_to_ask_clarifying_question",
    "rushed_responses",
  ],
  "SHOW EMPATHY": [
    "lack_of_empathy",
    "failure_to_acknowledge_mistake",
    "long_wait_times",
    "lack_of_confidence",
    "inability_to_address_urgency",
    "impersonal_interactions",
    "unresponsive",
  ],
  "EXPLAIN PLAN OF ACTION": [
    "not_getting_to_the_point",
    "lack_of_customer_education",
    "inadequate_follow_up",
    "inaccurate_promises",
    "missed_to_set_proper_expectations",
    "invalid_escalations",
  ],
  "RESOLUTION CONFIRMATION": [
    "no_thorough_investigation",
    "lack_of_product_knowledge_expertise",
    "unresolved_issues",
    "faild_to_confirm_resolution",
  ],
};

export const questionsOrder: Record<keyof typeof questionToReasons, number> = {
  "UNDERSTAND THE INQUIRY": 4,
  "SHOW EMPATHY": 3,
  "EXPLAIN PLAN OF ACTION": 2,
  "RESOLUTION CONFIRMATION": 1,
};

export const reasonsForNegativeScoreFormatted: Record<
  ReasonForNegativeScore,
  string
> = {
  poor_active_listening: "Poor active listening",
  repetitive_questions: "Repetitive questions",
  inconsistent_information: "Inconsistent information",
  language_barriers: "Language barriers",
  missed_to_ask_clarifying_question: "Missed to ask clarifying question",
  rushed_responses: "Rushed responses",
  lack_of_empathy: "Lack of empathy",
  failure_to_acknowledge_mistake: "Failure to acknowledge mistake",
  long_wait_times: "Long wait times",
  lack_of_confidence: "Lack of confidence",
  inability_to_address_urgency: "Inability to address urgency",
  impersonal_interactions: "Impersonal interactions",
  unresponsive: "Unresponsive",
  not_getting_to_the_point: "Not getting to the point",
  lack_of_customer_education: "Lack of customer education",
  inadequate_follow_up: "Inadequate follow-up",
  inaccurate_promises: "Inaccurate promises",
  missed_to_set_proper_expectations: "Missed to set proper expectations",
  invalid_escalations: "Invalid escalations",
  no_thorough_investigation: "No thorough investigation",
  lack_of_product_knowledge_expertise: "Lack of product knowledge expertise",
  faild_to_confirm_resolution: "Failed to confirm resolution",
  unresolved_issues: "Unresolved issues",
};

export type TicketQuestionScore = {
  id: number;
  title: string;
  content: string;
  autoScore: number;
  adjustedScore?: number;
  reasonsForNegativeScore?: ReasonForNegativeScore[];
  comment?: string;
};

export interface TicketComments {
  id: string;
  externalId: string;
  body: string;
  rawBody: string;
  source: string;
  authorId: string;
  email: string;
  agent: boolean;
  isInternal: boolean;
  createdAt: string;
  updatedAt: string;
  ticketId: string;
  externalIdIndex: number;
}

export interface TicketQAScoreResponse {
  ticketScore: TicketQuestionScore[];
  comments: TicketComments[];
}
