import { useEffect, useMemo } from "react";
import { params } from "../../params";
import { useAuthorizedMutation } from "..";
import { getSharedReportUrl } from "../../routes/sharedReport";

export function useGenerateReport(agentId?: number) {
  const { mutate, data, isLoading } = useAuthorizedMutation({
    url: `${params.API_URL}/platform/sampling/sample-report/${agentId}`,
    method: "POST",
  });

  useEffect(() => {
    if (data) {
      const reportId = data.s3Url.split("/")[3].replace(".json", "");
      const url = getSharedReportUrl(reportId);
      window.open(url, "_blank");
    }
  }, [data]);

  return useMemo(
    () => ({
      generateReport: (dateFrom: string, dateTo: string) => {
        if (agentId == null) {
          return;
        }

        mutate({ dateFrom, dateTo });
      },
      isLoading,
    }),
    [agentId, mutate, isLoading]
  );
}
