import React, { useEffect, useState, forwardRef } from "react";
import cx from "classnames";
import { Text, useKeyboard } from "@geist-ui/core";

import styles from "./styles.module.scss";

export type InputType = "none" | "instruction" | "feedback";

export const SimulatorInput = forwardRef<
  HTMLTextAreaElement,
  {
    value: string;
    type: InputType;
    setType: (v: InputType) => void;
    handleInputChange: (e: any) => void;
    messagesLength: number;
    handleSubmit: () => void;
    handleResetConversation: () => void;
  }
>(
  (
    {
      value,
      type,
      setType,
      handleInputChange,
      messagesLength,
      handleSubmit,
      handleResetConversation,
    },
    textareaRef
  ) => {
    const [, setValue] = useState("");

    useEffect(() => {
      (textareaRef as React.RefObject<HTMLTextAreaElement>).current?.focus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [type]);

    useKeyboard(() => {
      setType("none");
      setValue("");
      if ((textareaRef as React.RefObject<HTMLTextAreaElement>).current) {
        (textareaRef as any).current.style.height = "24px";
      }
    }, [27]);

    useKeyboard(
      (e) => {
        if (!e.shiftKey) {
          setTimeout(() => {
            setType("instruction");
            (
              textareaRef as React.RefObject<HTMLTextAreaElement>
            ).current?.focus();
          }, 300);
        }
      },
      [191],
      {
        stopPropagation: false,
        preventDefault: false,
        disableGlobalEvent: false,
      }
    );

    useKeyboard(
      (e) => {
        if (e.metaKey || e.ctrlKey) {
          handleResetConversation();
        }
      },
      [73],
      {
        event: "keydown",
        stopPropagation: false,
        preventDefault: false,
        disableGlobalEvent: false,
      }
    );

    return (
      <div
        className={cx(styles.simulatorInputWrapper, {
          [styles.instructions]: type === "instruction",
          [styles.feedback]: type === "feedback",
        })}
      >
        {type === "instruction" && <Text small>Instruction</Text>}
        {type === "feedback" && <Text small>Bad Response</Text>}

        <textarea
          ref={textareaRef}
          value={value}
          onChange={(e) => {
            handleInputChange(e);
            setValue(e.target.value);
          }}
          rows={1}
          placeholder={
            type === "none"
              ? messagesLength === 0
                ? "Ask anything else or add general instruction "
                : "Ask a follow up or add instructions"
              : type === "instruction"
              ? "Add an instruction"
              : "Why its not a good response ?"
          }
          onKeyDown={(e) => {
            if (e.key === "Enter" && !e.shiftKey) {
              e.preventDefault();
              handleSubmit();
              setTimeout(() => {
                (e.target as any).style.height = "auto";
              }, 30);
            }
            if (e.keyCode === 191 && !e.shiftKey) {
              e.preventDefault();
            }
          }}
          onInput={(e) => {
            (e.target as any).style.height = "auto";
            (e.target as any).style.height = `${
              (e.target as any).scrollHeight
            }px`;
          }}
        />
      </div>
    );
  }
);
