import { Loading } from "@geist-ui/core";
import { Message } from "ai/react";
import React, { useMemo } from "react";
import cx from "classnames";

import styles from "./styles.module.scss";

export const TicketChat = ({ messages }: { messages: Message[] }) => {
  return (
    <div className={styles.messages}>
      {messages.map((message, index) => (
        <MessageComponent key={message.id} message={message} />
      ))}
    </div>
  );
};

export const MessageComponent = ({
  message,
  isLoading = false,
  icon = <></>,
}: {
  message: Message;
  isLoading?: boolean;
  icon?: React.ReactNode;
}) => {
  const messageContainers = useMemo(() => {
    const formatContent = (content: string) => {
      if (!content.trim()) {
        return [];
      }
      const splitMessages = content.split(/\n\s*\n/); // double line breaks - new message

      return splitMessages.map((msg) => {
        const parts = msg.split(/(\*\*.*?\*\*|\[.*?\]\(.*?\))/g);
        return parts.map((part, index) => {
          if (part.startsWith("**") && part.endsWith("**")) {
            return <strong key={index}>{part.slice(2, -2)}</strong>;
          } else if (part.match(/\[.*?\]\(.*?\)/)) {
            const [text, url] = part.slice(1, -1).split("](");
            return (
              <a
                key={index}
                href={url}
                target="_blank"
                rel="noopener noreferrer"
                className={styles.styledLink}
              >
                {text}
              </a>
            );
          } else {
            return part
              .split("\n")
              .filter((line) => line.trim() !== "" && line.trim() !== ".")
              .map((line, lineIndex) => (
                <React.Fragment key={`${index}-${lineIndex}`}>
                  {line}
                  {lineIndex < part.split("\n").length - 1 && <br />}
                </React.Fragment>
              ));
          }
        });
      });
    };

    return formatContent(message.content);
  }, [message.content]);

  return (
    <div className={styles.messageWrapper}>
      {messageContainers.map((formattedMessage, index) => (
        <div
          className={cx(styles.messageContainer, {
            [styles.agent]: message.role === "assistant",
            [styles.loading]: isLoading,
          })}
          key={message.id + index}
        >
          {!isLoading ? (
            <div className={styles.messageContent}>
              {icon}
              {formattedMessage}
            </div>
          ) : (
            <Loading width={2.5} />
          )}
        </div>
      ))}
    </div>
  );
};
