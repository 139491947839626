import { useMemo } from "react";
import { TicketExploreRow } from "../../../api/useExplore/Explore.model";
import { DataList } from "../../../components/shared/data-list";
import styles from "./TicketQAScore.module.scss";
import { DataListValue } from "../../../components/shared/data-list/DataList.model";

type TicketOverviewProps = TicketExploreRow;

function TicketOverview({
  overview,
  agent,
  avgQaScore,
  topic,
  status,
  kbCoverage,
  channel,
  ttr,
  category,
  ticketCsat,
}: TicketOverviewProps) {
  const ticketDataList = useMemo<DataListValue[]>(() => {
    return [
      { title: "Topic", type: "string", value: topic },
      { title: "Ticket Status", type: "status", value: status },
      { title: "KB Coverage", type: "kbCoverage", value: kbCoverage },
      { title: "Channel", type: "channel", value: channel },
      { title: "TTR", type: "ttr", value: ttr },
      { title: "Category", type: "category", value: category },
      { title: "CSAT", type: "qaScore", value: ticketCsat },
    ];
  }, [topic, status, kbCoverage, channel, ttr, category]);

  return (
    <div className={styles.TicketOverview}>
      <div className={styles.Header}>
        <div className={styles.Subject}>{overview.subject}</div>
        <div className={styles.TicketId}>{`#${overview.id}`}</div>
      </div>

      <div>
        <DataList
          className={styles.DataList}
          values={[
            { title: "Agent", type: "string", value: agent },
            { title: "QA Score", type: "qaScore", value: avgQaScore },
          ]}
        />

        <DataList
          className={styles.DataList}
          values={ticketDataList.filter((item) => item.value != null)}
        />
      </div>
    </div>
  );
}

export default TicketOverview;
