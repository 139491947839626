import React, { useEffect, useMemo, useRef, useState } from "react";
import cx from "classnames";
import { useLocation, useSearchParams } from "react-router-dom";
import { IoInformationCircleOutline } from "react-icons/io5";
import { ArrowRight, ArrowLeft, Loader } from "@geist-ui/icons";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";
import { useAuth0 } from "@auth0/auth0-react";
import { useFeatureFlag } from "configcat-react";
import { Tooltip } from "@geist-ui/core";
import { PiThumbsDown, PiThumbsUp } from "react-icons/pi";
import dayjs from "dayjs";

import {
  useAskFollowUp,
  useAskTryAgain,
  useCommonQuestions,
  useCommonQuestionToggleEnable,
  useGetAutoConfig,
  useUndoAnswer,
  useUndoAutoConfig,
} from "../../api/useCommonQuestions";
import { Dot } from "../../components/atoms/dot";
import { useFetchingToasts } from "../../hooks/useFetchingToasts";
import {
  ActivationEvents,
  mixRegisterSuperProps,
  mixTrackEvent,
  mixUnregisterSuperProps,
} from "../../assets/mixpanel";

import { CommonQuestion } from "./components/commonQuestion";
import { Tag } from "./components/enabledTag";
import { EMessageType, ERole, Messages } from "./components/messages";
import { ActionPanel } from "./components/actionPanel";
import { InfoDrawer } from "./components/infoDrawer";
import styles from "./styles.module.scss";
import {
  defaultConfig,
  TAnswer,
  TAutoResponseData,
  TCommonQuestion,
  TDisplayedMessage,
} from "./index.types";
import { TAccuracyConfig } from "./components/actionPanel/configurationTools.types";
import { createToast } from "vercel-toast";

export const ActivationTopic = () => {
  const { user } = useAuth0();

  const [isExpanded, setExpanded] = useState(true);
  const [isExpandedBounced, setExpandedBounce] = useState(false);
  const [askedQ, setAskedQ] = useState<string>();
  const [isDraft, setDraft] = useState(true);
  const [cqArray, setCqArray] = useState<TCommonQuestion[]>([]);

  const { value: activationSortingValue } = useFeatureFlag(
    "activationSorting",
    false,
    {
      identifier: user?.owner ?? "",
      email: user?.email ?? "",
      custom: user ?? {},
    }
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const location = useLocation();

  const topicId = useMemo(
    () => location.pathname.split("/")[2],
    [location.pathname]
  );
  const selectedIndex = Number(searchParams.get("i") ?? "0");

  const {
    data: cqData,
    isLoading,
    isError,
    refetch,
  } = useCommonQuestions(topicId, true);
  const commonQuestionsData = cqData as TAutoResponseData;

  useEffect(() => {
    if (cqData && Array.isArray(cqData.commonQuestions)) {
      const ready = cqData.commonQuestions.filter(
        (cq: TCommonQuestion) => cq.readyAutoResponse && cq.name !== "Other"
      );
      setCqArray(ready);
    }
  }, [cqData]);

  const selectedQuestion: TCommonQuestion | null = useMemo(() => {
    if (!cqArray?.[selectedIndex]) return null;

    const question = { ...cqArray[selectedIndex] };
    if (question.answers && Array.isArray(question.answers)) {
      const latestVersion = Math.max(...question.answers.map((a) => a.version));
      question.answers = question.answers.filter(
        (a) => a.version === latestVersion
      );
    }

    return question;
  }, [selectedIndex, cqArray]);

  const { data: autoConfigData, refetch: refetchConfig } = useGetAutoConfig(
    selectedQuestion?.id ?? 0,
    !!selectedQuestion?.id
  );
  const configsArray = autoConfigData as TAccuracyConfig[];

  const cqId = Number(searchParams.get("cqId"));
  useEffect(() => {
    if (cqId && cqArray.length) {
      const index = cqArray.findIndex(
        (q) => q.id.toString() === cqId.toString()
      );
      if (index !== undefined && index !== -1) {
        setSearchParams({ i: index.toString() }, { replace: true });
        return;
      } else if (index === -1) {
        const newCq = commonQuestionsData?.commonQuestions.find(
          (cq: TCommonQuestion) => cq.id === cqId
        );
        if (newCq) {
          setSearchParams({ i: "0" });
          setCqArray((prev) => [newCq, ...prev]);
          return;
        }
      }
      createToast(
        "Common question associated with the ticket not found, or unavailable for activation",
        {
          type: "error",
          timeout: 3000,
        }
      );
      setSearchParams({ i: "0" }, { replace: true });
    }
  }, [cqId, cqArray, commonQuestionsData?.commonQuestions, setSearchParams]);

  useEffect(() => {
    if (configsArray && Array.isArray(configsArray)) {
      const published = configsArray.find((c) => !c.isDraft);
      const draft = configsArray.find((c) => c.isDraft);
      if (published && !draft) {
        setDraft(false);
      } else {
        setDraft(true);
      }
    }
  }, [configsArray]);

  const config: TAccuracyConfig = useMemo(() => {
    if (!configsArray || !Array.isArray(configsArray)) return defaultConfig;
    if (isDraft) {
      return configsArray.find((c) => c.isDraft) || defaultConfig;
    }
    return configsArray.find((c) => !c.isDraft) || defaultConfig;
  }, [configsArray, isDraft]);

  const {
    data: followupAnswer,
    isLoading: loadFollowup,
    mutateAsync: mutateAskFollowup,
  } = useAskFollowUp();

  const {
    data: tryAgainAnswer,
    isLoading: loadTryAgain,
    mutateAsync: mutateAskTryAgain,
  } = useAskTryAgain();

  const {
    isLoading: loadToggleEnabledAutoResponse,
    mutateAsync: mutateToggleEnabledAutoResponse,
  } = useCommonQuestionToggleEnable();

  const {
    isLoading: loadToggleEnabledPublish,
    mutateAsync: mutateToggleEnabledPublish,
  } = useCommonQuestionToggleEnable();

  const { isLoading: loadUndoAutoConfig, mutateAsync: mutateUndoAutoConfig } =
    useUndoAutoConfig(selectedQuestion?.id ?? 0);

  const { isLoading: loadUndoAnswer, mutateAsync: mutateUndoAnswer } =
    useUndoAnswer(selectedQuestion?.id ?? 0);

  const loadMutate = useMemo(() => {
    return (
      loadFollowup ||
      loadTryAgain ||
      loadToggleEnabledAutoResponse ||
      loadToggleEnabledPublish
    );
  }, [
    loadFollowup,
    loadTryAgain,
    loadToggleEnabledAutoResponse,
    loadToggleEnabledPublish,
  ]);

  useEffect(() => {
    if (followupAnswer) {
      refetch();
      refetchConfig();
    }
  }, [followupAnswer, refetch, refetchConfig]);

  useEffect(() => {
    if (tryAgainAnswer) {
      refetch();
      refetchConfig();
    }
  }, [tryAgainAnswer, refetch, refetchConfig]);

  const processMessages = (answers: TAnswer[]) => {
    const _answers: TDisplayedMessage[] = [];
    answers.forEach(({ question, answer }, index) => {
      _answers.push({
        content: question,
        role: ERole.USER,
        type: index === 0 ? EMessageType.EXAMPLE : EMessageType.FOLLOWUP,
      });
      _answers.push({
        content: answer,
        role: ERole.AGENT,
        type: EMessageType.NONE,
      });
    });

    return _answers;
  };

  const displayMessages = useMemo(() => {
    let _answers: TAnswer[] = [];
    if (selectedQuestion && selectedQuestion.answers) {
      const lastPlace = Math.max(selectedQuestion?.answers?.length - 1, 0);
      const sortedQuestionByVersion = selectedQuestion.answers?.sort(
        (a, b) => a.version - b.version
      );
      const lastVersion = sortedQuestionByVersion[lastPlace]?.version;
      _answers = [...selectedQuestion.answers];
      _answers = _answers.filter((_answer) => _answer.version === lastVersion);
    }

    if (askedQ && loadFollowup)
      _answers.push({
        question: askedQ,
        answer: "",
        id: 0,
        commonQuestionId: selectedQuestion?.id ?? 0,
        createdAt: "",
        updatedAt: "",
        version: 0,
        score: 0,
        isFollowUpQuestion: true,
      });
    if (followupAnswer && !loadFollowup) {
      setAskedQ(undefined);
    }
    return processMessages(_answers);
  }, [selectedQuestion, askedQ, followupAnswer, loadFollowup]);

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  useEffect(() => {
    if (selectedQuestion && topicId) {
      mixRegisterSuperProps({
        activationTopic: topicId,
        commonQuestionId: selectedQuestion.id,
        commonQuestion: selectedQuestion.name,
      });

      return () => {
        mixUnregisterSuperProps([
          "activationTopic",
          "commonQuestionId",
          "commonQuestion",
        ]);
      };
    }
  }, [topicId, selectedQuestion]);

  const handleNext = () => {
    if (cqArray?.length - 1 !== +selectedIndex) {
      mixTrackEvent({
        event: ActivationEvents.CHANGE_COMMON_QUESTION,
        properties: {
          method: "next arrow",
          new_index: +selectedIndex + 1,
          current_index: selectedIndex,
          new_common_question: cqArray[+selectedIndex + 1].name,
        },
      });
      setSearchParams({ i: String(+selectedIndex + 1) });
    }
  };
  const handlePrevious = () => {
    if (+selectedIndex !== 0) {
      mixTrackEvent({
        event: ActivationEvents.CHANGE_COMMON_QUESTION,
        properties: {
          method: "previous arrow",
          new_index: +selectedIndex - 1,
          current_index: selectedIndex,
          new_common_question: cqArray[+selectedIndex - 1].name,
        },
      });
      setSearchParams({ i: String(+selectedIndex - 1) });
    }
  };

  useEffect(() => {
    if (isExpanded) {
      setTimeout(() => {
        setExpandedBounce(true);
      }, 400);
    } else {
      setExpandedBounce(false);
    }
  }, [isExpanded]);

  useFetchingToasts({
    error: cqData?.error || isError,
    emptyState:
      !isLoading &&
      commonQuestionsData &&
      !commonQuestionsData?.commonQuestions?.length,
    emptyStateMessage: `Topic ${commonQuestionsData?.topicName} has no common questions`,
  });

  const lastPlace = selectedQuestion?.answers
    ? selectedQuestion.answers?.length - 1
    : 0;

  const handleAskFollowup = async (question: string) => {
    setAskedQ(question);
    await mutateAskFollowup({
      commonQuestionId: selectedQuestion?.id ?? 0,
      answerId: selectedQuestion?.answers[lastPlace].id,
      question,
      isFollowUp: true,
    });
  };
  const handleAskTryAgain = async (instructions: string) => {
    await mutateAskTryAgain({
      commonQuestionId: selectedQuestion?.id ?? 0,
      answerId: selectedQuestion?.answers[lastPlace].id,
      question: selectedQuestion?.answers[lastPlace].question,
      isFollowUp: false,
      customInstructions: instructions,
    });
    refetch();
    refetchConfig();
  };

  const [loadMessage, setLoadMessage] = useState(true);

  useEffect(() => {
    if (loadFollowup || isLoading || loadTryAgain) {
      setLoadMessage(true);
    } else {
      const timer = setTimeout(() => {
        setLoadMessage(false);
      }, 300);

      return () => clearTimeout(timer);
    }
  }, [loadFollowup, isLoading, loadTryAgain]);

  const handleEnabledTogglePublish = async () => {
    mixTrackEvent({
      event: ActivationEvents.PUBLISH_COMMON_QUESTION,
      properties: {
        enable: !selectedQuestion?.publishEnabled,
      },
    });
    await mutateToggleEnabledPublish({
      id: selectedQuestion?.id ?? 0,
      publishEnabled: !selectedQuestion?.publishEnabled,
    });
    refetch();
    refetchConfig();
  };

  const handleEnabledToggleAutoResponse = async () => {
    mixTrackEvent({
      event: ActivationEvents.PUBLISH_AUTO_RESPONSE,
      properties: {
        enable: !selectedQuestion?.autoResponseEnabled,
      },
    });
    await mutateToggleEnabledAutoResponse({
      id: selectedQuestion?.id ?? 0,
      autoResponseEnabled: !selectedQuestion?.autoResponseEnabled,
    });
    refetch();
    refetchConfig();
  };

  const chatBodyRef = useRef<HTMLDivElement>(null);

  const handleResize = () => {
    const chatBody = chatBodyRef.current;
    if (!chatBody) return;
    chatBody.style.scrollBehavior = "smooth";
    chatBody.scrollTop = chatBody.scrollHeight;
  };

  useEffect(() => {
    handleResize();
  }, [loadFollowup, loadTryAgain]);

  const lastUpdatedAt = useMemo(() => {
    const publishLastUpdated = dayjs(selectedQuestion?.publishLastUpdated);
    const activeLastUpdated = dayjs(selectedQuestion?.activeLastUpdated);
    const configUpdatedAt = dayjs(config?.updatedAt);

    const mostRecentDate = [
      publishLastUpdated,
      activeLastUpdated,
      configUpdatedAt,
    ].reduce((latest, current) => {
      return current.isAfter(latest) ? current : latest;
    });
    return mostRecentDate;
  }, [selectedQuestion, config]);

  const savingStatusMessage = useMemo(() => {
    const now = dayjs();
    const diffMinutes = now.diff(lastUpdatedAt, "minute");
    const diffHours = now.diff(lastUpdatedAt, "hour");

    if (diffMinutes <= 5) {
      return "All changes saved now";
    } else if (diffMinutes <= 60) {
      return `All changes saved ${diffMinutes}m ago`;
    } else if (diffHours <= 24) {
      const minutes = diffMinutes % 60;
      return `All changes saved ${diffHours}h and ${minutes}m ago`;
    } else {
      return `All changes saved ${lastUpdatedAt.format("MMM D, HH:mm")}`;
    }
  }, [lastUpdatedAt]);

  const { thumbsUpTotal, thumbsDownTotal, thumbsUpClass, thumbsDownClass } =
    useMemo(() => {
      if (commonQuestionsData?.thumbsUpThumbsDown && selectedQuestion) {
        const selectedCqIdRatings = Object.values(
          commonQuestionsData.thumbsUpThumbsDown
        ).filter(
          (feedback) => feedback.commonQuestionId === selectedQuestion.id
        );

        const thumbsUpTotal = selectedCqIdRatings.reduce(
          (acc, feedback) => acc + feedback.thumbsUp,
          0
        );

        const thumbsDownTotal = selectedCqIdRatings.reduce(
          (acc, feedback) => acc + feedback.thumbsDown,
          0
        );

        const thumbsUpClass = thumbsUpTotal > thumbsDownTotal ? styles.up : "";
        const thumbsDownClass =
          thumbsUpTotal < thumbsDownTotal ? styles.down : "";

        return {
          thumbsUpTotal,
          thumbsDownTotal,
          thumbsUpClass,
          thumbsDownClass,
        };
      }
      return {
        thumbsUpTotal: 0,
        thumbsDownTotal: 0,
        thumbsUpClass: "",
        thumbsDownClass: "",
      };
    }, [commonQuestionsData, selectedQuestion]);

  return (
    <>
      <div className={styles.mainContainer}>
        {activationSortingValue && (
          <div className={styles.sorting}>sort by potential</div>
        )}
        <div className={styles.panelContainer}>
          {!isLoading &&
          !cqData?.error &&
          !isError &&
          cqArray?.length &&
          selectedQuestion ? (
            <>
              <div
                className={cx(styles.leftContainer)}
                style={{
                  width: isExpanded ? "360px" : "93px",
                }}
              >
                <div className={styles.header}>
                  <div
                    className={styles.text}
                    style={{
                      opacity: isExpandedBounced ? "1" : "0",
                    }}
                  >
                    <div className={styles.title}>
                      {commonQuestionsData?.topicName}
                      <span>{cqArray?.length}</span>
                    </div>
                    <div className={styles.stats}>
                      <span>Enabled</span>
                      <label>
                        {Math.floor(commonQuestionsData?.enabledPercentage)}%
                      </label>
                      <Dot />
                      <span>Full potential</span>
                      <label>
                        {Math.floor(commonQuestionsData?.readyPercentage)}%
                      </label>
                    </div>
                  </div>
                  <button
                    className={styles.expandBtn}
                    onClick={() => {
                      mixTrackEvent({
                        event: ActivationEvents.RESIZE_SIDEBAR,
                      });
                      setExpanded((prev) => !prev);
                    }}
                  >
                    {isExpanded ? (
                      <MdKeyboardDoubleArrowLeft />
                    ) : (
                      <MdKeyboardDoubleArrowRight />
                    )}
                  </button>
                </div>
                <div className={styles.commonQuestionsContainer}>
                  {cqArray?.map((question: TCommonQuestion, index: number) => {
                    return (
                      <CommonQuestion
                        onClick={(clickedIndex) => {
                          setSearchParams({ i: String(clickedIndex - 1) });
                          mixTrackEvent({
                            event: ActivationEvents.CHANGE_COMMON_QUESTION,
                            properties: {
                              method: "side panel",
                              new_index: clickedIndex - 1,
                              current_index: selectedIndex,
                              new_common_question: cqArray[index].name,
                            },
                          });
                        }}
                        isExpanded={isExpandedBounced}
                        index={index + 1}
                        key={question.name}
                        state={{
                          ...question,
                          potential:
                            (question?._count.tickets * 100) /
                            commonQuestionsData?.totalTickets,
                        }}
                        active={index === Number(selectedIndex)}
                      />
                    );
                  })}
                </div>
              </div>
              <div className={styles.rightContainer}>
                <div className={styles.chatHeader}>
                  {+selectedIndex !== 0 ? (
                    <button
                      className={styles.navigationBtn}
                      onClick={handlePrevious}
                    >
                      <ArrowLeft />
                    </button>
                  ) : (
                    <div style={{ width: "40px" }} />
                  )}
                  <div className={styles.chatHeaderCenter}>
                    <div className={styles.top}>
                      expressions {+selectedIndex + 1} out of {cqArray?.length}{" "}
                      <Dot /> potential is{" "}
                      <label>
                        {Math.floor(
                          (selectedQuestion?._count.tickets * 100) /
                            commonQuestionsData?.totalTickets
                        )}
                        %
                      </label>{" "}
                      <Dot />{" "}
                      <Tag
                        isEnabled={
                          selectedQuestion?.autoResponseEnabled ?? false
                        }
                        isPublish={selectedQuestion?.publishEnabled ?? false}
                        isExpanded={true}
                      />
                      {commonQuestionsData.thumbsUpThumbsDown && (
                        <>
                          <Dot />
                          <Tooltip text="Agents feedback from copilot">
                            <div className={styles.feedbackRating}>
                              <div
                                className={cx(
                                  styles.feedbackRatingItem,
                                  thumbsUpClass
                                )}
                              >
                                <PiThumbsUp />
                                <span>{thumbsUpTotal}</span>
                              </div>
                              <div
                                className={cx(
                                  styles.feedbackRatingItem,
                                  thumbsDownClass
                                )}
                              >
                                <PiThumbsDown />
                                <span>{thumbsDownTotal}</span>
                              </div>
                            </div>
                          </Tooltip>
                        </>
                      )}
                    </div>
                    <div className={styles.headerQuestion}>
                      <span>{selectedQuestion?.name}</span>
                      <span
                        className={styles.icon}
                        onClick={() => {
                          mixTrackEvent({
                            event: ActivationEvents.OPEN_INFO_DRAWER,
                          });
                          setDrawerOpen(true);
                        }}
                      >
                        <IoInformationCircleOutline />
                      </span>
                    </div>
                  </div>
                  {cqArray?.length - 1 !== +selectedIndex ? (
                    <button
                      className={styles.navigationBtn}
                      onClick={handleNext}
                    >
                      <ArrowRight />
                    </button>
                  ) : (
                    <div style={{ width: "40px" }} />
                  )}
                </div>
                <div
                  className={cx(styles.chatBody, {
                    [styles.expanded]: isExpandedBounced,
                  })}
                  ref={chatBodyRef}
                >
                  <Messages
                    messages={displayMessages}
                    isAnswerLoading={loadMessage}
                    isDraft={isDraft}
                  />
                  <ActionPanel
                    config={config}
                    messages={displayMessages}
                    askFollowup={handleAskFollowup}
                    askTryAgain={handleAskTryAgain}
                    isAnswerLoading={loadMessage}
                    questionId={selectedQuestion?.id}
                    handleResize={handleResize}
                    question={selectedQuestion}
                  />
                </div>
                <div className={styles.chatFooter}>
                  <div className={styles.right}>
                    {selectedQuestion?.answers?.length > 1 && (
                      <Tooltip text="Remove all followup questions and answers, return to the original question">
                        <button
                          onClick={async () => {
                            await mutateUndoAnswer({});
                            refetch();
                          }}
                          className={cx(styles.button, styles.secondaryBtn, {
                            [styles.loading]: loadToggleEnabledAutoResponse,
                          })}
                          disabled={loadUndoAnswer}
                        >
                          Remove Followups
                        </button>
                      </Tooltip>
                    )}
                  </div>
                  <div className={styles.right}>
                    <div className={styles.savingStatus}>
                      {!loadMutate ? (
                        <>
                          <IoIosCheckmarkCircleOutline />
                          {savingStatusMessage}
                        </>
                      ) : (
                        <>
                          <Loader />
                          Saving...
                        </>
                      )}
                    </div>
                    {!isDraft ||
                    (isDraft &&
                      configsArray?.findIndex((c) => !c.isDraft) === -1) ? (
                      <>
                        <Tooltip
                          text={`last updated ${dayjs(
                            selectedQuestion?.activeLastUpdated
                          ).format("MMM D, HH:mm")}`}
                        >
                          {selectedQuestion?.autoResponseEnabled ? (
                            <>
                              <button
                                onClick={() =>
                                  handleEnabledToggleAutoResponse()
                                }
                                className={cx(
                                  styles.button,
                                  styles.secondaryBtn,
                                  {
                                    [styles.loading]:
                                      loadToggleEnabledAutoResponse,
                                  }
                                )}
                                disabled={
                                  loadToggleEnabledPublish ||
                                  loadToggleEnabledPublish ||
                                  loadFollowup ||
                                  loadTryAgain ||
                                  loadMessage ||
                                  !selectedQuestion.readyAutoResponse ||
                                  selectedQuestion.name === "Other"
                                }
                              >
                                Deactivate
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() =>
                                  handleEnabledToggleAutoResponse()
                                }
                                className={cx(
                                  styles.button,
                                  styles.primaryBtn,
                                  {
                                    [styles.loading]:
                                      loadToggleEnabledAutoResponse,
                                  }
                                )}
                                disabled={
                                  loadToggleEnabledPublish ||
                                  loadToggleEnabledPublish ||
                                  loadFollowup ||
                                  loadTryAgain ||
                                  loadMessage ||
                                  !selectedQuestion.readyAutoResponse ||
                                  selectedQuestion.name === "Other"
                                }
                              >
                                Activate
                              </button>
                            </>
                          )}
                        </Tooltip>
                        <Tooltip
                          text={`last updated ${dayjs(
                            selectedQuestion?.publishLastUpdated
                          ).format("MMM D, HH:mm")}`}
                        >
                          {selectedQuestion?.publishEnabled ? (
                            <>
                              <button
                                onClick={() => handleEnabledTogglePublish()}
                                className={cx(
                                  styles.button,
                                  styles.secondaryBtn,
                                  {
                                    [styles.loading]: loadToggleEnabledPublish,
                                  }
                                )}
                                disabled={
                                  loadToggleEnabledPublish ||
                                  loadToggleEnabledPublish ||
                                  loadFollowup ||
                                  loadTryAgain ||
                                  loadMessage ||
                                  !selectedQuestion.readyAutoResponse ||
                                  selectedQuestion.name === "Other"
                                }
                              >
                                UnPublish from Copilot
                              </button>
                            </>
                          ) : (
                            <>
                              <button
                                onClick={() => handleEnabledTogglePublish()}
                                className={cx(
                                  styles.button,
                                  styles.primaryBtn,
                                  {
                                    [styles.loading]: loadToggleEnabledPublish,
                                  }
                                )}
                                disabled={
                                  loadToggleEnabledPublish ||
                                  loadToggleEnabledPublish ||
                                  loadFollowup ||
                                  loadTryAgain ||
                                  loadMessage ||
                                  !selectedQuestion.readyAutoResponse ||
                                  selectedQuestion.name === "Other"
                                }
                              >
                                Publish to Copilot
                              </button>
                            </>
                          )}
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={async () => {
                            mixTrackEvent({
                              event:
                                ActivationEvents.UNDO_DRAFT_COMMON_QUESTION_AUTO_CONFIG,
                            });
                            await mutateUndoAutoConfig({});
                            refetch();
                            refetchConfig();
                          }}
                          className={cx(styles.button, styles.secondaryBtn, {
                            [styles.loading]: loadUndoAutoConfig,
                          })}
                          disabled={
                            !selectedQuestion.readyAutoResponse ||
                            selectedQuestion.name === "Other"
                          }
                        >
                          Undo
                        </button>
                        <button
                          onClick={async () => {
                            mixTrackEvent({
                              event:
                                ActivationEvents.UPDATE_DRAFT_COMMON_QUESTION_AUTO_CONFIG,
                            });
                            await await mutateToggleEnabledAutoResponse({
                              id: selectedQuestion.id,
                            });
                            refetch();
                            refetchConfig();
                          }}
                          className={cx(styles.button, styles.primaryBtn, {
                            [styles.loading]: loadToggleEnabledPublish,
                          })}
                          disabled={
                            !selectedQuestion.readyAutoResponse ||
                            selectedQuestion.name === "Other"
                          }
                        >
                          Activate Version
                        </button>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className={styles.skeleton} />
          )}
        </div>
        {selectedQuestion && isDrawerOpen && (
          <InfoDrawer
            openDrawer={isDrawerOpen}
            onDrawerClosed={() => setDrawerOpen(false)}
            questionId={selectedQuestion?.id}
            question={selectedQuestion}
          />
        )}
      </div>
    </>
  );
};
