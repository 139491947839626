import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import dayjs from "dayjs";
import rrwebPlayer from "rrweb-player";
import "rrweb-player/dist/style.css";
import ReactJson from "react-json-view";

import { Typography } from "../typography";
import { DisplayWrapper } from "../displayWrapper";
import { Table } from "../table";
import { ETabs, Tabs } from "../tabs";
import { mixTrackEvent, ReportEvents } from "../../assets/mixpanel";

import styles from "./styles.module.scss";

const skeletonArray = [1, 2, 3, 4, 5, 6, 7];

export const ReviewPage = ({
  data = {},
  loading,
  handleLoadChunk,
}: {
  handleCreateTask?: () => void;
  data: any;
  loading: boolean;
  handleLoadChunk: (v: any) => void;
}) => {
  const [activeTab, setActiveTab] = useState<ETabs>(ETabs.CONSOLE);
  const [isPlayMode, setPlayMode] = useState(false);
  const [videoMeta, setVideoMeta] = useState({
    startTime: 0,
    endTime: 0,
    totalTime: 0,
  });
  const [currentSeek, setCurrentSeek] = useState<number>(0);
  const replayRef = useRef<HTMLDivElement>(null);
  const replayerClient = useRef<rrwebPlayer>();

  const handleChooseTab = (value: ETabs) => {
    mixTrackEvent({
      event: ReportEvents.MOVE_TAB,
      properties: {
        tab: value,
      },
    });
    setActiveTab(value);
  };

  useEffect(() => {
    if (replayRef.current && data?.recordings && data?.recordings.length > 0) {
      const initialWidth = 1024;
      const initialHeight = 576;
      const { width } = replayRef.current.getBoundingClientRect();

      const precentage = (width * 100) / initialWidth;

      const height = (precentage * initialHeight) / 100;
      if (replayRef.current.children.length !== 0) {
        replayRef.current.removeChild(replayRef.current.children[0]);
      }
      if (replayRef.current.children.length === 0) {
        const client = new rrwebPlayer({
          target: replayRef.current,
          props: {
            events: data?.recordings,
            height: height,
            width: width,
            autoPlay: false,
            speed: 1,
            speedOption: [1, 1.5, 2],
          },
        });

        // get current timing
        client.addEventListener("ui-update-current-time", (event) => {
          setCurrentSeek(event.payload);
        });

        const a = client.getMetaData();
        setVideoMeta(a);
        replayerClient.current = client;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.recordings?.length, replayRef, loading]);

  useEffect(() => {
    if (!!data?.form) {
      setActiveTab(ETabs.FORM);
    } else {
      if (data?.log_data?.length === 0) {
        if (data?.network_logs === 0) {
          setActiveTab(ETabs.SYSTEM_INFO);
        } else {
          setActiveTab(ETabs.NETWORK);
        }
      }
    }
  }, [data]);

  return (
    <>
      {loading ? (
        <div className=" flex p-2 pl-4 gap-2 h-14">
          <Typography theme="primary" type="table_body">
            Loading...
          </Typography>
        </div>
      ) : (
        <>
          {data?.spansCount > 0 && data.correlationId && (
            <div className=" flex p-2 pl-4 gap-2 h-14">
              <Typography theme="primary" type="table_body">
                Showing {dayjs(videoMeta.startTime).format("HH:mm:ss")} -{" "}
                {dayjs(videoMeta.endTime).format("HH:mm:ss")}
              </Typography>
            </div>
          )}
        </>
      )}

      <div className={styles.container}>
        <div>
          {loading ? (
            <div
              className="bg-slate-700 animate-pulse w-full h-96 rounded-3xl"
              style={{ marginTop: "30px" }}
            ></div>
          ) : (
            <div className={styles.smallContainer}>
              <div ref={replayRef} style={{ paddingTop: "30px" }}></div>
            </div>
          )}
        </div>
        <div className={styles.rightContainer}>
          <Tabs
            isForm={!!data?.form}
            activeTab={activeTab}
            handleChooseTab={handleChooseTab}
            {...{ isPlayMode, setPlayMode }}
          />
          {loading ? (
            <div className="flex flex-col gap-4 p-4">
              {skeletonArray.map((k) => (
                <div key={k} className="flex gap-4">
                  <div className=" bg-slate-700 animate-pulse w-10 h-5 rounded-md" />
                  <div className=" bg-slate-700 animate-pulse w-96 h-5 rounded-md" />
                  <div className=" bg-slate-700 animate-pulse w-16 h-5 rounded-md" />
                  <div className=" bg-slate-700 animate-pulse w-16 h-5 rounded-md" />
                  <div className=" bg-slate-700 animate-pulse w-6 h-5 rounded-md" />
                </div>
              ))}
            </div>
          ) : (
            <>
              {activeTab === ETabs.FORM && (
                <div className={styles.scrollContainer}>
                  <DisplayWrapper state={data.form} />
                </div>
              )}
              {activeTab === ETabs.CONSOLE && (
                <Fragment>
                  <div className={styles.scrollContainer}>
                    <Table
                      data={data?.console}
                      type="console"
                      isPlayMode={isPlayMode}
                      time={currentSeek}
                      newVersion
                      startTime={videoMeta.startTime}
                    />
                  </div>
                </Fragment>
              )}
              {activeTab === ETabs.NETWORK && (
                <Fragment>
                  <div className={styles.scrollContainer}>
                    <Table
                      data={data?.network}
                      type="network"
                      isPlayMode={isPlayMode}
                      time={currentSeek}
                      newVersion
                      startTime={videoMeta.startTime}
                    />
                  </div>
                </Fragment>
              )}
              {activeTab === ETabs.SYSTEM_INFO && <SystemInfo data={data} />}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export const SystemInfo = ({ data }: { data: any }) => {
  const parseStringifiedJSON = (obj: any) => {
    const entries = Array.isArray(obj)
      ? Array.from(obj.entries())
      : Object.entries(obj);
    for (const [key, value] of entries) {
      if (typeof value === "string") {
        try {
          const parsedValue = JSON.parse(value);
          if (typeof parsedValue === "object") {
            obj[key] = parseStringifiedJSON(parsedValue);
          }
        } catch (e) {}
      } else if (typeof value === "object" && value !== null) {
        obj[key] = parseStringifiedJSON(value);
      }
    }
    return obj;
  };

  const parsedLocalStorage = useMemo(
    () => parseStringifiedJSON(data?.localStorage[0] || {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [data?.localStorage]
  );

  return (
    <div className={styles.infoContainer}>
      <h2>Info</h2>
      <div>
        <ReactJson
          src={
            {
              ...data?.browserInfo,
            } as any
          }
          theme="google"
          indentWidth={4}
          name={null}
          style={{ backgroundColor: "unset" }}
          displayDataTypes={false}
          collapsed={1}
        />
      </div>
      <div className="divider" />
      <h2>Local storage</h2>
      {Object.entries(parsedLocalStorage).map(([key, value]) =>
        value && typeof value === "object" ? (
          <div key={key}>
            <h3>{key}</h3>
            <ReactJson
              src={value}
              theme="google"
              indentWidth={4}
              name={null}
              style={{ backgroundColor: "unset" }}
              displayDataTypes={false}
              collapsed={0}
            />
          </div>
        ) : (
          <></>
        )
      )}
    </div>
  );
};
