import {useMemo, useCallback} from "react";
import { DropdownMenu } from "@radix-ui/themes";
import { useFilter } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";

export const TopicsDropDown = ({ options }: { options: string[] }) => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();

  const fields = useMemo(() => {
    const slicer = stringSliceBy?.and.find(({type}) => type === "topic");

    return slicer?.values.map(({value}) => value);
  }, [stringSliceBy]);

  const handleSlice = useCallback((option: string) => {
    let newFields = [];

    if (fields?.includes(option)) {
      newFields = fields.filter((o) => o !== option);
    } else {
      newFields = [...(fields ?? []), option];
    }

    if (newFields.length > 0) {
      setStringSlicer("topic", {
        type: "topic",
        operation: "or",
        values: newFields.map((field) => ({
          type: "topic",
          operation: "eq",
          value: field,
        })),
      });  
    } else {
      removeStringSlicer("topic");
    }
  }, [fields, removeStringSlicer, setStringSlicer]);

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        Topics
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options
          .filter((o) => o !== "Other")
          .map((option) => {
            return (
              <DropdownMenu.Item
                className="DropdownMenuItem"
                onClick={() => handleSlice(option)}
              >
                {fields?.includes(option) && <CheckIcon />}
                {option}
              </DropdownMenu.Item>
            );
          })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
