import { CheckIcon, FaceIcon } from "@radix-ui/react-icons";
import { DropdownMenu } from "@radix-ui/themes";
import { useCallback, useMemo } from "react";
import { useFilter } from "../context";
import { isNumericSlicerPreset, NumericSlicerPreset, sentimentSlicerMutator, SentimentSlicerPresetType, sentimentToLabel } from "../context/FilterContext.model";

export const SentimentDropDown = () => {
  const { numericSliceBy, setNumericSlicer, removeNumericSlicer } = useFilter();

  const value = useMemo<string | undefined>(() => {
    const slicer = numericSliceBy?.and.find((slicer) => isNumericSlicerPreset(slicer) && slicer.split("_")[0] === "sentiment");

    if (slicer == null || typeof slicer !== "string") {
      return;
    }

    const preset = (slicer as NumericSlicerPreset).split("_")[1];
    return preset;
  }, [numericSliceBy]);

  const handleSlice = useCallback((newValue: SentimentSlicerPresetType) => {
    if (value !== newValue) {
      setNumericSlicer("sentiment", `sentiment_${newValue}`)
    } else {
      removeNumericSlicer("sentiment");
    }
  }, [value, setNumericSlicer, removeNumericSlicer]);

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        <FaceIcon />
        Sentiment
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {Object.entries(sentimentSlicerMutator).map(([key]) => (
          <DropdownMenu.Item
            className="DropdownMenuItem"
            onClick={() => handleSlice(key as SentimentSlicerPresetType)}
          >
            {value === key && <CheckIcon />}
            {sentimentToLabel[key as SentimentSlicerPresetType]}
          </DropdownMenu.Item>  
        ))}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
