import { Home, Layers, Settings, Users } from "@geist-ui/icons";
import { BsLightningCharge } from "react-icons/bs";
import { ReactNode } from "react";
import {
  BackpackIcon,
  EyeOpenIcon,
  FileTextIcon,
  RocketIcon,
  StackIcon,
  TokensIcon,
  VideoIcon,
} from "@radix-ui/react-icons";

export enum ENavigate {
  HOME = "home",
  ASSESSMENT = "topics",
  TICKETS = "tickets",
  ACTIVATION = "activation",
  SETTINGS = "settings",
  KNOWLEDGE = "knowledge-management",
  SAMPLING = "sampling",
  PERFORMANCE = "teams",
  VOC = "categories",
  SIMULATOR = "resolution",
  SESSIONS_REPORTS = "sessions-reports",
  CUSTOMERS = "customers",
  QUALITY = "quality",
  EVALUATIONS = "quality/evaluations",
  VALIDATIONS = "quality/validations",
  COPILOT = "resolution?f=copilot",
  AUTOPILOT = "resolution?f=autopilot",
}

export const PagesIcons: Record<ENavigate, ReactNode> = {
  [ENavigate.HOME]: <Home />,
  [ENavigate.TICKETS]: <Layers />,
  [ENavigate.ACTIVATION]: <BsLightningCharge />,
  [ENavigate.SETTINGS]: <Settings />,
  [ENavigate.SESSIONS_REPORTS]: <VideoIcon />,
  //
  [ENavigate.CUSTOMERS]: <BackpackIcon />,
  [ENavigate.PERFORMANCE]: <Users />,
  [ENavigate.VOC]: <TokensIcon />,
  [ENavigate.ASSESSMENT]: <StackIcon />,
  [ENavigate.SAMPLING]: undefined,
  [ENavigate.SIMULATOR]: <RocketIcon />,
  [ENavigate.KNOWLEDGE]: <FileTextIcon />,
  [ENavigate.QUALITY]: <EyeOpenIcon />,
  [ENavigate.EVALUATIONS]: undefined,
  [ENavigate.VALIDATIONS]: undefined,
  [ENavigate.AUTOPILOT]: undefined,
  [ENavigate.COPILOT]: undefined,
};
