import { CheckIcon, RulerHorizontalIcon } from "@radix-ui/react-icons";
import { DropdownMenu } from "@radix-ui/themes";
import { useCallback, useMemo } from "react";
import { useFilter } from "../context";
import { isNumericSlicerPreset, NumericSlicerPreset, qualityAssuranceSlicerMutator, QualityAssuranceSlicerPresetType, qualityAssuranceToLabel } from "../context/FilterContext.model";

export const QAScoreDropDown = () => {
  const { numericSliceBy, setNumericSlicer, removeNumericSlicer } = useFilter();

  const value = useMemo<string | undefined>(() => {
    const slicer = numericSliceBy?.and.find((slicer) => isNumericSlicerPreset(slicer) && slicer.split("_")[0] === "qualityAssurance");
    
    if (slicer == null || typeof slicer !== "string") {
      return;
    }

    const preset = (slicer as NumericSlicerPreset).split("_")[1];
    return preset;
  }, [numericSliceBy]);

  const handleSlice = useCallback((newValue: QualityAssuranceSlicerPresetType) => {
    if (value !== newValue) {
      setNumericSlicer("qualityAssurance", `qualityAssurance_${newValue}`)
    } else {
      removeNumericSlicer("qualityAssurance");
    }
  }, [value, setNumericSlicer, removeNumericSlicer]);

  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        <RulerHorizontalIcon />
        QA Score
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {Object.entries(qualityAssuranceSlicerMutator).map(([key]) => (
          <DropdownMenu.Item
            className="DropdownMenuItem"
            onClick={() => handleSlice(key as QualityAssuranceSlicerPresetType)}
          >
            {value === key && <CheckIcon />}
            {qualityAssuranceToLabel[key as QualityAssuranceSlicerPresetType]}
          </DropdownMenu.Item>
        ))}
        {/* <DropdownMenu.Item
          className="DropdownMenuItem"
          onClick={() => handleSlice("high")}
        >
          {selected.includes("high") && <CheckIcon />}
          {"High Score ( Score > 4.5 )"}
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className="DropdownMenuItem"
          onClick={() => handleSlice("medium")}
        >
          {selected.includes("medium") && <CheckIcon />}
          {"Medium Score ( Score 3-4.5 )"}
        </DropdownMenu.Item>
        <DropdownMenu.Item
          className="DropdownMenuItem"
          onClick={() => handleSlice("low")}
        >
          {selected.includes("low") && <CheckIcon />}
          {"Low Score ( Score < 3 )"}
        </DropdownMenu.Item> */}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
