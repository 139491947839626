import { useCallback, useState } from "react";
import { Button } from "@radix-ui/themes";
import { Dialog } from "../../../../components/shared/dialog";
import styles from "./Members.module.scss";
import Select from "../../../../components/shared/select";
import { useCreateMembers } from "../../../../api/members";
import {
  Permission,
  PermissionDisplay,
  Role,
} from "../../../../api/members/members.model";
import { createToast } from "vercel-toast";
import { useGetTenants } from "../../../../api/tenants";
import { Checkbox, Input } from "@geist-ui/core";
import MultiSelect from "../../../../components/shared/multi-select/Select";

function AddMembersDialog({ refetch }: { refetch: () => Promise<unknown> }) {
  const { data: tenants } = useGetTenants();

  const { mutateAsync: createMembers, isLoading } = useCreateMembers();

  const [open, setOpen] = useState<boolean>(false);
  const [roleValue, setRoleValue] = useState<Role>(Role.AGENT);
  const [allowedPermissions, setAllowedPermissions] = useState<Permission[]>(
    []
  );
  const [allowedTenants, setAllowedTenants] = useState<string[]>([]);
  const [emails, setEmails] = useState<string[]>([]);

  const rolesOptions = [
    { label: "Agent - Co-pilot AI assistant access", value: Role.AGENT },
    { label: "Member - Customized platform access", value: Role.MEMBER },
    { label: "Admin - Full administrative access", value: Role.ADMIN },
  ];

  const permissionsOptions = Object.entries(PermissionDisplay)
    .map((p) => {
      return {
        value: p[0] as Permission,
        label: p[1],
      };
    })
    .filter((p) => p.value !== Permission.COPILOT);

  const validateEmail = (): boolean => {
    const regexPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return (
      emails.length > 0 &&
      emails.every((part) => regexPattern.test(part.trim()))
    );
  };

  const closeDialog = () => {
    setOpen(false);
    setRoleValue(Role.AGENT);
    setAllowedPermissions([]);
    setAllowedTenants([]);
    setEmails([]);
  };

  const handleAddNew = useCallback(async () => {
    const isAdmin = roleValue === Role.ADMIN;
    const isAgent = roleValue === Role.AGENT;

    await createMembers(
      {
        emails,
        permissions: isAgent
          ? [Permission.COPILOT]
          : [...allowedPermissions, Permission.COPILOT],
        tenantsIds: isAgent ? tenants?.map((t) => t.id) : allowedTenants,
        isAdmin,
      },
      {
        onSettled: async () => {
          await refetch();
          console.log(`finished adding members`);
          closeDialog();
          createToast(`Member added`, {
            type: "success",
            timeout: 3000, // in 3 seconds
          });
        },
      }
    );
  }, [closeDialog, createToast, refetch]);

  return (
    <>
      <Button
        color="gray"
        variant="solid"
        highContrast
        style={{
          width: "123px",
          height: "35px",
          borderRadius: "2px",
        }}
        onClick={() => setOpen(true)}
      >
        Add members
      </Button>

      <Dialog open={open} onOpenChange={setOpen}>
        <Dialog.Content size="small">
          <div className={styles.AddMember}>
            <Dialog.Close className={styles.Close} />

            <div className={styles.Header}>
              <div className={styles.Title}>Invite member</div>
            </div>
            <div className={styles.Content}>
              <div className={styles.AccessParameter}>
                <div className={styles.Option}>
                  <div className={styles.Title}>Email</div>
                </div>

                <Input
                  className={styles.Input}
                  placeholder={"Add member by email"}
                  autoFocus
                  color="gray"
                  value={emails.join(",")}
                  onPointerEnterCapture={undefined}
                  onPointerLeaveCapture={undefined}
                  crossOrigin={undefined}
                  onChange={(e) =>
                    setEmails(e.target.value.split(",").map((v) => v.trim()))
                  }
                />
              </div>

              <div className={styles.AccessParameter}>
                <div className={styles.Option}>
                  <div className={styles.Title}>Role</div>
                </div>

                <Select
                  options={rolesOptions}
                  value={roleValue}
                  onChange={(newValue) => {
                    setRoleValue(newValue as Role);
                  }}
                  classes={{ trigger: styles.Select }}
                />
              </div>

              {roleValue === Role.MEMBER ? (
                <div className={styles.AccessParameter}>
                  <div className={styles.Option}>
                    <div className={styles.Title}>Platform access</div>
                  </div>

                  <div className={styles.ChoiceList}>
                    {permissionsOptions.map((permission) => (
                      <Checkbox
                        className={styles.ChoiceItem}
                        key={permission.value}
                        scale={2}
                        checked={allowedPermissions.includes(permission.value)}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setAllowedPermissions((prev) => [
                              ...prev,
                              permission.value,
                            ]);
                          } else {
                            setAllowedPermissions((prev) =>
                              prev.filter((p) => p !== permission.value)
                            );
                          }
                        }}
                      >
                        <span>{permission.label}</span>
                      </Checkbox>
                    ))}
                  </div>
                </div>
              ) : (
                <></>
              )}

              {roleValue === Role.MEMBER ? (
                <div className={styles.AccessParameter}>
                  <div className={styles.Option}>
                    <div className={styles.Title}>Spaces access</div>
                  </div>

                  <MultiSelect
                    classes={{ trigger: styles.MultiSelect }}
                    options={tenants?.map((option) => ({
                      label: option.id,
                      value: option.id,
                    }))}
                    value={allowedTenants}
                    onChange={(selectedValue) => {
                      if (allowedTenants.includes(selectedValue)) {
                        setAllowedTenants((prev) =>
                          prev.filter((tenant) => tenant !== selectedValue)
                        );
                      } else {
                        setAllowedTenants((prev) => [...prev, selectedValue]);
                      }
                    }}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>

            <div className={styles.Footer}>
              <Button
                color="gray"
                variant="solid"
                highContrast
                onClick={handleAddNew}
                loading={false}
                disabled={!validateEmail() || isLoading}
                className={styles.AddButton}
              >
                {isLoading ? "..." : "Send Invites"}
              </Button>
              <Button
                color="gray"
                variant="ghost"
                highContrast
                onClick={() => {
                  closeDialog();
                }}
                className={styles.CancelButton}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Dialog.Content>
      </Dialog>
    </>
  );
}

export default AddMembersDialog;
