import {
  useAuthorizedInfiniteQuery,
  useAuthorizedMutation,
  useAuthorizedQuery,
} from "..";
import { params } from "../../params";
import { buildFilterSearchParams } from "../../routes/explore/context";
import {
  FilterValue,
  pasrseFilterValue,
} from "../../routes/explore/context/FilterContext.model";
import {
  ExploreRequest,
  ExploreResponse,
  TicketExploreResponse,
} from "./Explore.model";

export const useExplore = () => {
  return useAuthorizedMutation<ExploreResponse, ExploreRequest>({
    url: `${params.API_URL}/platform/explorer/explore`,
    method: "POST",
  });
};
export const useExploreTickets = () => {
  return useAuthorizedMutation({
    url: `${params.API_URL}/platform/explorer/tickets`,
    method: "POST",
  });
};
export const useExploreValues = (enabled: boolean) => {
  return useAuthorizedQuery({
    queryKey: `useExploreValues`,
    url: `${params.API_URL}/platform/explorer/values`,
    method: "GET",
    enabled,
  });
};
export const useExploreInfiniteTickets = (
  {
    includeComments,
    ...filterValue
  }: FilterValue & { includeComments: boolean },
  enabled: boolean
) => {
  const searchParams = buildFilterSearchParams(filterValue);

  return useAuthorizedInfiniteQuery<TicketExploreResponse>({
    url: `${params.API_URL}/platform/explorer/tickets/`,
    method: "POST",
    queryKey: `useAuthorizedInfiniteQuery_${searchParams.toString()}`,
    enabled,
    body: { ...pasrseFilterValue(filterValue), includeComments },
    pageKey: "page",
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.metadata?.isThereNextPage && allPages.length + 1;
    },
  });
};
