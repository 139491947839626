import React, { useState, useEffect, ReactNode } from "react";
import cx from "classnames";
import { createToast } from "vercel-toast";
import { Copy } from "@geist-ui/icons";

import { Typography } from "../typography";
import { EBadgeMode, GeistBadge } from "../atoms/geistBadge";
import { mixTrackEvent, ReportEvents } from "../../assets/mixpanel";

import styles from "./styles.module.scss";

export const useToggle = () => {
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return [isOpen, toggle] as const;
};

export const Header = ({
  name,
  id,
  description,
  isloading,
}: {
  name?: string;
  description?: string;
  id?: string;
  isloading?: boolean;
}) => {
  const [meta, setMeta] = useState({ name, description });
  const [isCopied, setCopied] = useState(false);

  const handleCopyLink = () => {
    navigator?.clipboard?.writeText(`${window.location.origin}/report/${id}`);
    mixTrackEvent({
      event: ReportEvents.PRESS_COPY_LINK_REPORT,
    });
    isCopied &&
      createToast("Copied to clipboard", {
        type: "success",
        timeout: 3000,
      });
    setCopied(true);
  };

  useEffect(() => {
    setMeta({ name, description });
  }, [name, description]);

  return (
    <>
      <div className={styles.container}>
        <span className={styles["g-16"]}>
          <div className="flex flex-col gap-2">
            <>
              {name && (
                <Typography type="page_headline" theme="secondary">
                  {meta.name}
                </Typography>
              )}
              {description && (
                <Typography type="tab" theme="secondary">
                  {meta.description}
                </Typography>
              )}
            </>
          </div>
        </span>
        <span>
          <GeistBadge
            mode={
              isloading
                ? EBadgeMode.LOAD
                : !isCopied
                ? EBadgeMode.PRETTY
                : EBadgeMode.GREEN
            }
            onClick={handleCopyLink}
          >
            <Copy />
            Copy Report Link{" "}
          </GeistBadge>
        </span>
      </div>
    </>
  );
};

export const SimpleButton = ({
  children,
  ...props
}: { children: ReactNode } & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>) => {
  return (
    <button {...props} className={cx(styles.button, props.className)}>
      {children}
    </button>
  );
};
