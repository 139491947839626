import React, { useState } from "react";

import styles from "./styles.module.scss";
import { CopilotSimulatorChat } from "./chat";
import { ReleaseProvider } from "../simulator/context/release";
import { InstructionsProvider } from "../simulator/context/instructions";
import { CopilotRightPanel } from "./rightPanel";

export const SimulatorCoPilot = () => {
  const [topic, setTopic] = useState<string | null>(null);
  const [isTraining, setTraining] = useState(true);
  const [ticketId, setTicketId] = useState<string | null>(null);
  const [comments, setComments] = useState<
    | {
        rawBody: string;
        agent: boolean;
        createdAt: string;
      }[]
    | null
  >(null);

  return (
    <ReleaseProvider>
      <InstructionsProvider>
        <div className={styles.container}>
          <div className={styles.chatContainer}>
            <CopilotSimulatorChat
              {...{
                topic,
                setTopic,
                isTraining,
                setTraining,
                ticketId,
                setTicketId,
                setComments,
              }}
            />
          </div>
          <div className={styles.configurationPanelContainer}>
            <CopilotRightPanel {...{ topic, isTraining, ticketId, comments }} />
          </div>
        </div>
      </InstructionsProvider>
    </ReleaseProvider>
  );
};
