import { DropdownMenu } from "@radix-ui/themes";
import { useCallback, useMemo } from "react";
import { useFilter } from "../context";
import { CheckIcon } from "@radix-ui/react-icons";

export const AgentsDropDown = ({ options }: { options: string[] }) => {
  const { stringSliceBy, setStringSlicer, removeStringSlicer } = useFilter();

  const fields = useMemo(() => {
    const slicer = stringSliceBy?.and.find(({type}) => type === "agent");

    return slicer?.values.map(({value}) => value);
  }, [stringSliceBy]);

  const handleSlice = useCallback((option: string) => {
    let newFields = [];

    if (fields?.includes(option)) {
      newFields = fields.filter((o) => o !== option);
    } else {
      newFields = [...(fields ?? []), option];
    }

    if (newFields.length > 0) {
      setStringSlicer("agent", {
        type: "agent",
        operation: "or",
        values: newFields.map((field) => ({
          type: "agent",
          operation: "eq",
          value: field,
        })),
      });  
    } else {
      removeStringSlicer("agent");
    }
  }, [fields, removeStringSlicer, setStringSlicer]);
  
  return (
    <DropdownMenu.Sub>
      <DropdownMenu.SubTrigger className="DropdownMenuSubTrigger">
        Agents
      </DropdownMenu.SubTrigger>
      <DropdownMenu.SubContent
        className="DropdownMenuSubContent"
        sideOffset={8}
        alignOffset={-5}
      >
        {options.map((option) => {
          return (
            <DropdownMenu.Item
              className="DropdownMenuItem"
              onClick={() => handleSlice(option)}
            >
              {fields?.includes(option) && <CheckIcon />}
              {option}
            </DropdownMenu.Item>
          );
        })}
      </DropdownMenu.SubContent>
    </DropdownMenu.Sub>
  );
};
