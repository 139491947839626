export enum ActivationEvents {
  PUBLISH_COMMON_QUESTION = "activation_publish_common_question",
  PUBLISH_AUTO_RESPONSE = "activation_publish_auto_response",
  RESIZE_SIDEBAR = "activation_resize_sidebar",
  OPEN_INFO_DRAWER = "activation_open_info_drawer",
  OPEN_TEXT_EDITOR = "activation_open_text_editor",
  ASK_FOLLOW_UP_QUESTION = "activation_ask_follow_up_question",
  SEND_TRY_AGAIN = "activation_send_try_again",
  OPEN_CONFIGURATION_TOOLS = "activation_open_configuration_tools",
  SET_CONFIGURATION_TOOLS = "activation_set_configuration_tools",
  OPEN_SOURCE_DETAILS_DRAWER = "activation_open_source_details_drawer",
  CHANGE_COMMON_QUESTION = "activation_change_common_question",
  UNDO_DRAFT_COMMON_QUESTION_AUTO_CONFIG = "activation_undo_draft_common_question_auto_config",
  UPDATE_DRAFT_COMMON_QUESTION_AUTO_CONFIG = "activation_update_draft_common_question_auto_config",
}
